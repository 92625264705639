import React from 'react';

const ExpandedContent = ({ materials }) => {
  return (
    <div>
      <p>Materials:</p>
      <ul>
        {materials.map((material) => (
          <li key={material._id}>{material.name}: {material.code}</li>
        ))}
      </ul>
    </div>
  );
};

export default ExpandedContent;
