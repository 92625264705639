import { useLocation } from "react-router-dom"
import MultibleChoicesAnswer from "./multibleChoicesAnswer";
import TrueFalseAnswer from "./trueFalseAnswer";
import TextAnswer from "./textAnswer";
import { useState } from "react";
import { useSelector } from "react-redux";
import SetStudentDegreeComponent from "./SetStudentDegreeComponent";
import { setResult } from "../../../utils/results";
import { Container } from "react-bootstrap";

export default function TeacherCorrectionComponent() {
  const location = useLocation();
  const { teacher_test, user } = useSelector(state => state.loggedUser);
  
  const { toast } = useSelector(state => state.notify);
  const{lang,dir}=useSelector(state=>state.language);

  const [editedQuestionsArr, setEditedQuestionsArr] = useState(teacher_test?.result?.questions);

  console.log('teacher_test', teacher_test);

  const onSubmit = async () => {
    try {
      let resultData = {
        name: teacher_test?.result?.testID?.name,
        userID: teacher_test?.result?.userID,
        testID: teacher_test?.result?.testID?._id,
        total: teacher_test?.result?.testID?.tot_degree,
        questions: editedQuestionsArr,
        numberOfQuestions: editedQuestionsArr.length,
        teacher: true
      };

      let correctedQuestions = teacher_test?.result?.correctedQuestions;
      let result = Number(teacher_test?.result?.result);
      editedQuestionsArr.map((el, i) => {
        if (el.answerDegree) {
          if (teacher_test?.result?.questions[i]?.answerDegree) {
            //edit( remove old results)
            result -= parseInt(teacher_test?.result?.questions[i]?.answerDegree);
          }

          result += parseInt(el.answerDegree);
          //edit case dont increase
          if (teacher_test?.result?.correctedQuestions != teacher_test?.result?.numberOfQuestions)
            parseInt(correctedQuestions++)

        }
        //el.answerDegree ? teacher_test?.result?.correctedQuestions==teacher_test?.result?.numberOfQuestions ? '' : parseInt(correctedQuestions++) : ''
      });
      //editedQuestionsArr.map(el => el.answerDegree ? result += parseInt(el.answerDegree) : '');
      //console.log('correctedQuestions',correctedQuestions);
      resultData.correctedQuestions = correctedQuestions;
      resultData.result = result;

      console.log('resultData', resultData);

      if (parseInt(resultData.correctedQuestions) !== parseInt(resultData.numberOfQuestions)) {
        console.log('xxxxxxxx');
        toast().error('برجاء تصحيح جميع الاسئلة قبل التأكيد');
        return;
      }

       const res = await setResult(resultData);

       if (res.data.case) toast().success('تم التصحيح بنجاح');
       else toast().error('برجاء المحاولة مرة اخري');
    } catch (error) {
      toast().error('برجاء المحاولة مرة اخري');
    }

  }



  //console.log('editedQuestionsArr', editedQuestionsArr);

  return (
    <Container dir={dir}>
      {/* if(answer is choices ) */}
      {teacher_test?.result?.questions?.map((q, i) =>
        <div key={i}>
          {
            q.choices &&
            <>
              <div className="d-flex gap-5 py-3 mx-5" style={{ borderTop: '1px solid black' }}>
                <div> {`Question ${i + 1}: ${q.question}`}</div>
                <div> الدرجة : {q.degree} </div>
              </div>
              <MultibleChoicesAnswer index={i} question={q} handleSelectAnswer={() => { }} />
              {
                user.role == "student" && <div className="d-flex flex-column gap-2 py-3">
                  <div> :التعليل</div>
                  <div>  {q?.reason} </div>
                </div>
              }

            </>

          }
          {
            !q.choices && q.TrueFalseAnswer !== null && (
              <>
                <div className="d-flex gap-5 py-3 mx-5" style={{ borderTop: '1px solid black' }}>
                  <div> {`Question ${i + 1}: ${q.question}`}</div>
                  <div> الدرجة : {q.degree} </div>
                </div>

                <TrueFalseAnswer question={q} disabled={true} />

                {
                  user.role == "student" && <div className="d-flex flex-column gap-2 py-3">
                    <div> :التعليل</div>
                    <div>  {q?.reason} </div>
                  </div>
                }
              </>
            )
          }

          {!q.choices && q.TrueFalseAnswer === null && (
            <>
              <div className="d-flex gap-5 py-3 mx-5" style={{ borderTop: '1px solid black' }}>
                <div> {`Question ${i + 1}: ${q.question}`}</div>
                <div> الدرجة : {q.degree} </div>
              </div>

              <TextAnswer
                index={i}
                handleAnswer={() => { }}
                question={q}
              />

              {
                user.role == "student" && <div className="d-flex flex-column gap-2 py-3">
                  <div> :التعليل</div>
                  <div>  {q?.reason} </div>
                </div>
              }
              {
                user.role == 'teacher' && <SetStudentDegreeComponent
                  question={q}
                  setEditedQuestionsArr={setEditedQuestionsArr}
                  index={i}
                />
              }


            </>
          )
          }

        </div>


      )}

      {
        user.role === "teacher" &&
        <div
          onClick={() => onSubmit()}
          className="numOfQuestionBox mx-auto my-3"
        >
          تصحيح
        </div>
      }

    </Container>
  )
}
