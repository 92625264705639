import React from 'react'
import PostCard from './postCard'
import { Container } from 'react-bootstrap'

function Posts({posts}) {

  //  console.log('posts',posts)
    return (
    <Container>
        {posts&& posts.length? posts.map((p,i)=><PostCard key={i} post={p}/>)
        :
        <h2 className='text-center font'>لا توجد منشورات حاليا لهذا القسم</h2>
        }
    </Container>
  )
}

export default Posts