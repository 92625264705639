import { useContext, useEffect, useState } from 'react';
import LogoRhaya from '../../assets/logo.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import { ThemeContext } from '../../ThemeProvider';
import { CiLight } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Log_Out } from '../../actions/login&register';
import { useNavigate } from "react-router-dom"
// import { setLang } from "../../../../actions/language";
import { setLang } from "../../actions/language";
import { HomePage } from '../../utils/Translation/HomePage';
function HeaderDefault() {
    const [scrolled, setScrolled] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 0.2) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const loggedUser = useSelector((state) => state.loggedUser);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });




    const logout = () => {
        try {
            dispatch(Log_Out());
            navigate(`/login`)
            window.location.reload();
        } catch (e) {
            console.log(e);
        }
    }

    const { theme, toggleTheme } = useContext(ThemeContext);
    return (
        <Navbar expand="lg" className={`font ${scrolled ? `scrolled` : ''} ${theme}`} style={{ background: "white" }} dir={`${dir}`} fixed="top">
            <Container fluid>
                <Link to="/">
                    <img src={LogoRhaya} alt="OLP LOGO" style={{ width: "100%", height: "90px" }} />
                </Link>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="m-auto my-2 my-lg-0"
                    >
                        <NavLink to="/" style={{ textDecoration: "none", marginRight: "30px", marginTop: "10px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>{HomePage.Home[lang]}</NavLink>
                        <NavLink to="/subjects" style={{ textDecoration: "none", marginRight: "30px", marginTop: "10px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>{HomePage.Subjects[lang]}</NavLink>
                        <NavLink to="/teachers" style={{ textDecoration: "none", marginRight: "30px", marginTop: "10px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>{HomePage.Teachers[lang]}</NavLink>
                        <NavLink to="/contact-us" style={{ textDecoration: "none", marginRight: "30px", marginTop: "10px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>
                            {HomePage.Contact_us[lang]}
                        </NavLink>
                        <NavLink to="/bardiat-community" style={{ textDecoration: "none", marginRight: "30px", marginTop: "10px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>
                            {HomePage.society[lang]}
                        </NavLink>
                    </Nav>
                    <div className='mx-2 mt-3'>
                        <ul className="navbar-nav">
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    dispatch(setLang(e.target.value));
                                }}
                            >
                                <option selected value="AR">
                                    العربية
                                </option>
                                <option value="EN">English</option>
                            </select>
                        </ul>
                    </div>
                    {
                        loggedUser && loggedUser.auth ? (
                            <div>
                                {
                                    loggedUser?.user?.role === "Admin" ? (
                                        <Link to={`/user/${loggedUser?.user?._id}`} className='btn special color'>{HomePage.dashboard[lang]}</Link>
                                    ) : (
                                        <Link to={`/dashboard/${loggedUser?.user?.username}/${loggedUser?.user?._id}/profile`} className='btn special color'>{HomePage.dashboard[lang]}</Link>
                                    )
                                }

                                <button className='btn buttonsGroub' onClick={logout}>{HomePage.log_out[lang]}</button>
                            </div>
                        ) : (
                            <div>
                                <Link to="/login" className='btn special color'>{HomePage.login[lang]} </Link>
                                <Link to="/register" className='btn buttonsGroub'>{HomePage.register[lang]}</Link></div>
                        )
                    }
                    <button onClick={toggleTheme} className='fs-3' style={{ background: "transparent", outline: "none", border: "none" }}>
                        {
                            theme === 'light' ? (<CiLight />) : (<MdDarkMode />)
                        }
                    </button>
                </Navbar.Collapse>
            </Container >
        </Navbar >
    );
}

export default HeaderDefault;