import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Table, Modal, Button } from 'react-bootstrap';
import './pdf.css'
import { GetMaterialsByIds } from '../../../utils/materials';
import { increaseTries } from '../../../utils/codes'
const PdfGenerator = ({ sheet }) => {
  // console.log('sheet',sheet)
  const [show, setShow] = useState(false);
  const [material, setMaterial] = useState(null)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const generatePDF = () => {
    const input = document.getElementById('pdf-content');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageHeight = pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;
    const canvasWidth = pageWidth - margin * 2;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgHeight = (canvas.height * canvasWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      while (heightLeft > 0) {
        pdf.addImage(imgData, 'PNG', margin, position, canvasWidth, imgHeight);
        heightLeft -= pageHeight;
        position -= pageHeight;

        if (heightLeft > 0) {
          pdf.addPage();
          position = 0;
        }
      }

      pdf.save('download.pdf');
    }).catch((err) => console.error('Error generating PDF', err));
  };

  useEffect(() => {
    if (sheet && show) {
      const getMaterial = async () => {
        try {
          const res = await GetMaterialsByIds({ codes: [sheet?.materialCode] });
          if (res.data.case) {
            setMaterial(res.data.materials[0])
          }

        }
        catch (err) {
          console.log(err);
        }
      }
      getMaterial()
    }
  }, [show, sheet])
  const testFunction = async () => {
    try {
      const res = await increaseTries({
        sheetId: '669ba2586feaa40c541c0886',
        lesson: { _id: "669ba2586feaa40c541c0887" },
        code: "100_783459"
      })
      console.log('test', res.data)
    }
    catch (err) {
      console.warn(err)
    }
  }

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        عرض الاكواد
      </Button>
      {/* <button onClick={()=>testFunction()}>Test</button> */}
      <Modal show={show} onHide={handleClose} size="lg" className="font" centered >
        <Modal.Header>
          <Modal.Title>PDF Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div id="pdf-content">
            {sheet.lessons?.map((lesson, index) => (
              <React.Fragment key={index}>
                <h1 id='pdf-title'>{`${lesson?.lessonId?.name}`}</h1>
                <Table bordered>
                  <thead>
                    <tr >
                      <th>الكارت</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: '16px'  , marginBottom:"10px"}}>
                    {lesson?.codesObjects?.map((code, codeIndex) => (
                      <tr id='pdf-row' key={codeIndex}>
                        <th >
                          <p>المادة: {material?.name} | {sheet?.materialCode}</p>
                          <p>اسم الدرس: {lesson?.lessonId?.name}</p>
                          <p>الكود: {code?.code}</p>
                          <p>عدد الاستخدامات: {code?.usedNumber}</p>
                          <p>تاريخ الانتهاء: {sheet?.expir?.split('T')[0]}</p>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </React.Fragment>
            ))}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className='btn buttonsGroub' onClick={handleClose}>
            اغلاق
          </button>
          <button className='btn special color' onClick={generatePDF}>
            تحميل الملف
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PdfGenerator;
