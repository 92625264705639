import React from 'react';
import { urlBase } from '../../utils/API';
import { NavLink } from 'react-router-dom';

function PostCard({ post }) {
  // Function to format creation date to Cairo local time
  const formatToCairoTime = (dateString) => {
    const date = new Date(dateString); // Create Date object from creationDate string
    const options = {
      timeZone: 'Africa/Cairo', // Set time zone to Cairo
      weekday: 'long', // Display full weekday name (e.g., "Monday")
      year: 'numeric', // Display full year (e.g., "2024")
      month: 'long', // Display full month name (e.g., "January")
      day: 'numeric', // Display day of the month (e.g., "1")
      hour: 'numeric', // Display hour (e.g., "1" or "01" for 1 AM/PM)
      minute: 'numeric' // Display minute (e.g., "30")
    };
    return date.toLocaleString('en-US', options); // Format to Cairo local time
  };

  return (
    <div className='post-card shadow font'>
      <div className='image-cont-post-card'>
        <div className='avatar-post'>
          <img src={`${urlBase}/${post?.author?.personalImage}`} alt='Author Avatar' />
        </div>
        <div className='post-author'>
          <h4>
          <span>{formatToCairoTime(post?.creationDate)}</span><br/>
            {post?.author?.name}
            <br/>
            <span>{post?.author?.role}</span>
          </h4>
        </div>
      </div>
      <p className='post-content'>
        {post?.content}
      </p>
      <NavLink className='post-view-btn ' to={`/post/${post._id}`}>
        مشاهدة المزيد
      </NavLink>
    </div>
  );
}

export default PostCard;

// /bardiat-community/${post?.classID?.branchId}_${post?.classID?.levelId}_${post?.classID?._id}/${post._id}