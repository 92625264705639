import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getUserByID } from '../../utils/API';
import { setSubscribe } from '../../utils/subscribe';
import { success } from '../shared/modules/Notifications';
import GetLevelName from '../shared/GetLevelName';
import ModalGetPackage from './ModalGetPackage';

const MyStudents = () => {
    const loggedUser = useSelector((state) => state.loggedUser);
    const [subscribersData, setSubscribersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchSubscribersData = async () => {
        setLoading(true);
        try {
            const subscribers = loggedUser?.user?.subscribers || [];
            const subscribersDetails = await Promise.all(
                subscribers.map(async (sub) => {
                    const res = await getUserByID({ userID: sub.user });
                    return res.data; // assuming the API returns the user data in res.data
                })
            );
            setSubscribersData(subscribersDetails);
        } catch (e) {
            console.error(e);
            setError('Failed to fetch subscriber data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (loggedUser?.user?.subscribers) {
            fetchSubscribersData();
        }
    }, [loggedUser]);




    const cancelSubscribe = async (idSub) => {
        try {
            const res = await setSubscribe({
                subscriber: idSub,
                target: loggedUser?.user?._id,
            });

            console.log(res)

            if (res?.data?.case) {
                success(res?.data?.message)
            }

            setSubscribersData((prevData) => prevData.filter(sub => sub?.user?._id !== idSub));
        } catch (e) {
            console.error(e);
            setError('Failed to cancel subscription.');
        }
    };


    console.log(loggedUser)



    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <Table responsive className="container mt-5 font fs-6">
                    <thead>
                        <tr>
                            <th></th>
                            <th>الطالب</th>
                            <th>الايميل</th>
                            <th>المستوي</th>
                            <th>النوع</th>
                            <th>رقم الهاتف</th>
                            <th>الغاء الاشتراك</th>
                            <th>البرامج المشتركه مع الطالب</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subscribersData.map((sub, index) => (
                            <tr key={sub?._id}>
                                <td>{index + 1}</td>
                                <td>{sub?.user?.name}</td>
                                <td>{sub?.user?.email}</td>
                                <td>
                                    <GetLevelName levelId={sub?.user?.levelId} />
                                </td>
                                <td>{sub?.user?.gender}</td>
                                <td>{sub?.user?.phone}</td>
                                <td>
                                    <button
                                        className='btn buttonsGroub'
                                        onClick={() => { cancelSubscribe(sub?.user?._id) }}
                                    >
                                        الغاء الاشتراك
                                    </button>
                                </td>
                                <td>
                                    <ModalGetPackage studentId={sub?.user?._id} teacher={loggedUser?.user?._id} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default MyStudents;
