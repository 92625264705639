import React, { useState } from 'react'
import { Dropdown, DropdownButton, Form, FormCheck, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import { uploadingFileLocally } from '../../../utils/upload' 
import { useSelector } from 'react-redux'

function Question({question,
    questionIndex,
    handleQuestionChange,
    handleDegreeChange,
    handleRadioChange,
    handleChoiceChange,
    handelAddFile,
    updateReason,
    handleTrue_FalseAnswer
}) {
    
const {user} = useSelector(state=>state.loggedUser)
    const uploadFiles = async(e)=>{
      try{
        const formData = new FormData();
        formData.append("file",e.target.files[0]);
        formData.append("user",user?._id);
        const res = await uploadingFileLocally(formData);
        console.log(res)
        handelAddFile(questionIndex,{...res?.data?.data})
      }
      catch(err){
        console.warn(err)
      }
    }
  return (
    <div>
        <Form key={questionIndex} style={{ marginTop: '20px' }}>
          <FormGroup>
            <FormLabel style={{ backgroundColor: 'red', color: '#fff', padding: '5px', borderRadius: '5px' }}>
              السؤال:  {questionIndex + 1}:
            </FormLabel>
            <FormControl type="text" value={question.question} onChange={(e) => handleQuestionChange(e, questionIndex)} />
          </FormGroup>
          {question.questionType.q==='Text_Image'?
          
          <Form.Group controlId="QuestionFile">
                <Form.Label>رفع صورة للسؤال <span style={{fontSize:'12px',color:'gray'}}>اختيارى</span></Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => uploadFiles(event)}
                />
              </Form.Group>

            :<></>
        }
           {question.questionType.q==='Text_Video'?
          <Form.Group controlId="QuestionFile">
                <Form.Label>رفع فيديو للسؤال <span style={{fontSize:'12px',color:'gray'}}>اختيارى</span></Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => uploadFiles(event)}
                />
              </Form.Group>

            :<></>
        }
        {question.questionType.q==='Text_Audio'?
          <Form.Group controlId="QuestionFile">
                <Form.Label>رفع ملف صوتى للسؤال <span style={{fontSize:'12px',color:'gray'}}>اختيارى</span></Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => uploadFiles(event)}
                />
              </Form.Group>

            :<></>
        }
        
          <FormGroup>
            <FormLabel>درجة السؤال:</FormLabel>
            <FormControl type="number" value={question.degree} min={0} onChange={(e) => handleDegreeChange(e, questionIndex)} />
          </FormGroup>
           {question.questionType.answer==='CHOICE'?
            <>
                  {question.choices.map((choice, choiceIndex) => (
              <div key={choiceIndex}>
                <FormControl
                  type="text"
                  value={choice.value}
                  placeholder={`الاختيار:  ${choiceIndex + 1}`}
                  onChange={(e) => handleChoiceChange(e, questionIndex, choiceIndex)}
                />
                {/* Modify the label below or remove it */}
                <FormCheck
                  type      = "radio"
                  label     = {``} //{/* Add your label text here */}
                  className ='test-radio'
                  checked   ={choice.correct}
                  onChange  ={() => handleRadioChange(questionIndex, choiceIndex)}
                />
              </div>
            ))}
            </>   
            :<></>
        }
        {question.questionType.answer==='TRUE&FALSE'?
           <DropdownButton title="الاجابة الصحيحة" variant="primary" onSelect={(e)=>handleTrue_FalseAnswer(questionIndex,e)}>
                  <Dropdown.Item eventKey={true}>
               صحيح
             </Dropdown.Item>
             <Dropdown.Item eventKey={false}>
               خاطئ
             </Dropdown.Item>
         </DropdownButton>
         :<></>
    }
            <Form.Group controlId="answerReason">
                <Form.Label>  تعليل الاجابة   <span style={{fontSize:'12px',color:'gray'}}> اختيارى </span></Form.Label>
                <Form.Control
                  type="textArea"
                  onChange={(event) => updateReason(questionIndex,event.target.value)}
                />
              </Form.Group>
          </Form>
    </div>
  )
}

export default Question