import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Inetialize_Orders,Remove_product } from '../../../actions/store';
import DeleteProductModal from './deleteProductModal';

function ProductsList({ products, selectedCategory,categories }) {
    const [productsObject, setProductsObject] = useState({});
    const [currentProducts,setCurrentProducts] = useState(null);

    const {toast} = useSelector(state=>state.notify)
    const dispatch = useDispatch();

  // filter products accordinf to category
  const filterProducts = (catId)=>{
    if(catId){

        const newProducts = products.filter(p => p.category ===catId)
        setCurrentProducts(newProducts)
    }
    else{
        setCurrentProducts(products)
    }
  }
//   const getProducts = async () => {
//       try {
//           const res = await dispatch(Inetialize_Orders());
//           console.log('Fired request');
//           setProductsObject(
//               res.data.orders.reduce((obj, product) => {
//                   obj[product._id] = product;
//                   return obj;
//               }, {})
//           );
//       } catch (err) {
//           console.log(err);
//       }
//   };
    useEffect(() => {


        filterProducts(selectedCategory)
    }, [selectedCategory]);

const getProductCategory =(catId)=>{
    if(categories){

        return categories.find(c=>c?._id ===catId)
    }
    else return {name:'لا تتوفر فئة لهذا المنتج '}
}

// Remove Product

const removeProduct = async(id)=>{
    try{
     const res = await dispatch(Remove_product({_id:id}));
     if(res.data.case){
         toast().success(res?.data?.message)
     }
     else{
        toast().error(res?.data?.message)
     }
    }
    catch(err){
        console.warn(err);
        toast().error(err.message)
    }
}
    return (
        <div style={{padding:'50px 0 150px 0'}}>

            <Table responsive striped bordered hover>
                <thead>
                    <tr>
                        <th>الاسم</th>
                        <th>الفئة</th>
                        <th>السعر - EGP</th>
                        <th>فى المخزن</th>
                        <th>الوصف</th>
                        <th>خيارات</th>
                    </tr>
                </thead>
                {currentProducts?
                <tbody>
                    {currentProducts.map((product) => (
                        <tr
                            key={product._id}
                            className={product._id === selectedCategory ? 'table-active' : ''}
                        >
                            <td>{product.name}</td>
                            <td>{getProductCategory(product.category)?.name}</td>
                            <td>EGP{product.price}</td>
                            <td>{product.stock}</td>
                            <td>{product.description?product.description:'لايوجد'}</td>
                            <td><DropdownButton 
                                  title={'خيارات'}
                            >
                                  <Dropdown.Item>
                                    <DeleteProductModal removeFunc={removeProduct}/>
                                  </Dropdown.Item>
                                </DropdownButton></td>
                        </tr>
                    ))}
                </tbody>:
                <tbody>لاتوجد منتجات فى المتجر</tbody>
                }
            </Table>
        </div>
    );
}

export default ProductsList;
