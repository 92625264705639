import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function TextAnswer({ index, handleAnswer,question }) {


  return (
    <div className="text-answer">
      <Form.Group>
        <Form.Label>الاجابة:</Form.Label>
        <Form.Control
          as="textarea"
          rows={4} // Adjust the number of visible rows as needed
          placeholder="Enter your answer..."
          defaultValue={question ? question?.answer : ''}
          onChange={(e) => handleAnswer(index,e.target.value)}
          style={{resize:"none"}}
        />
      </Form.Group>

      
    </div>
  );
}

export default TextAnswer;
