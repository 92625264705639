// import { useSelector } from "react-redux";

export const ADD_TO_CART="ADD_TO_CART";
export const AddToCart=(item)=>{
    return{
        type:ADD_TO_CART,
        payload:item
    }
}

export const GET_ALL_CART_ITEMS="GET_ALL_CART_ITEMS";
export const GetAllCartItems=()=>{
    return{
        type:GET_ALL_CART_ITEMS
    }
}

export const RESET_CART="RESET_CART";
export const ResetCart=()=>{
    return{
        type:RESET_CART
    }
}