import { sendNewMessage,getAllMyMessages,getMyMessagesWithContact } from "../utils/massenger";

export const SHOW_MESSAGE_BOX = 'SHOW_MESSAGE_BOX';
export const showMessageBox = ()=>{
   return {
    type:SHOW_MESSAGE_BOX,

   }
}
export const SEND_MESSAGE = 'SEND_MESSAGE';

export const sendMessage = (contact,data)=>{


    return{
        type:SEND_MESSAGE,
        contact,
        data // contactId, ownerId, contactRole, ownerRole, message
    }
}

export const Send_New_Message = (data)=>{
    return dispatch => sendNewMessage(data).then((res)=>{
        if(res.data.case){
            dispatch(sendMessage(res.data.contact,data));

        }
        return res
    })
}

export const GET_ALL_MY_MESSAGES = 'GET_ALL_MY_MESSAGES'
const getAllmyMessages = (contacts)=>{


   return {
    type:GET_ALL_MY_MESSAGES,
    contacts
   }
}
export const Get_All_My_Messages = (ownerID)=>{
    return dispatch => getAllMyMessages(ownerID).then(res=>{
        if(res.data.case){
            dispatch(getAllmyMessages(res.data.contacts))
        }
        return res
    })
}
export const GET_MESSAGES_OF_CONTACT = 'GET_MESSAGES_OF_CONTACT';

const getMessagesOfCont = ({contact,ownerID,contactID})=>{
    return {
        type:GET_MESSAGES_OF_CONTACT,
        contact,
        ownerID,
        contactID
    }
}

export const Get_Messages_Of_Contact = (data)=>{
    return dispatch=> getMyMessagesWithContact(data).then(res=>{
        if(res.data.case){
            dispatch(getMessagesOfCont({contact:res.data.contact,ownerID:data.ownerID,contactID:data.contactID}))
        }
        else{
            dispatch(getMessagesOfCont({contact:{...data,contactObj:res.data.contact},ownerID:data.ownerID,contactID:data.contactID}))
            
        }
        return res
    })
}

