import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { getUserByID } from '../../utils/API';

const HonoraryModal = ({ weeksStudents }) => {
    const [show, setShow] = useState(false);
    const [students, setStudents] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const formatToCairoTime = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Africa/Cairo',
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return date.toLocaleString('en-US', options);
    };

    // Get Student By Id
    const getStudent = async (id) => {
        try {
            const res = await getUserByID({ userID: id });
            return res?.data?.user?.name;
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const fetchStudents = async () => {
            const studentData = {};
            for (const std of weeksStudents) {
                const name = await getStudent(std.studentID);
                studentData[std.studentID] = name;
            }
            setStudents(studentData);
        };

        if (weeksStudents) {
            fetchStudents();
        }
    }, [weeksStudents]);

    return (
        <div>
            <p onClick={handleShow}>
                لوحة الشرف الاسبوع
            </p>
            <Modal show={show} onHide={handleClose} className='font text-center m-auto' centered>
                <header className="header" style={{ background: "#2C6DCE", padding: "10px", color: "white" }}>
                    <h4 className='font'>
                        لوحة شرف الاسبوع
                    </h4>
                </header>
                <Modal.Body>
                    <Table className='font'>
                        <thead>
                            <tr>
                                <th>اسم الطالب</th>
                                <th>التاريخ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {weeksStudents ? (
                                weeksStudents.map((std) => (
                                    <tr key={std.studentID}>
                                        <td>{students[std.studentID] || 'Not Found'}</td>
                                        <td>{formatToCairoTime(std.date)}</td>
                                    </tr>
                                ))
                            ) : null}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default HonoraryModal;
