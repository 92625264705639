import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../../../styles/dashboard.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { GetMaterialsByIds } from '../../../../utils/materials';
import Level from '../../Subscriptions/Level';
import Branch from '../../Subscriptions/Branch';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../../utils/Translation/HomePage';
const MaterialModal = ({ dynamic, title, subject }) => {
    const [show, setShow] = useState(false);
    const [visibleDetails, setVisibleDetails] = useState({});
    const [materials, setMaterials] = useState([]);
    const [loading, setLoading] = useState(false);
    const percentage = 30;

    const handleClose = () => {
        setShow(false);
        setVisibleDetails({});
    }


    const handleShow = async () => {
        setShow(true);
        if (dynamic === "programs") {
            if (subject?.packData?.materials) {
                setLoading(true);
                const materialCodes = subject.packData.materials.map(material => material?.materialCode);
                const materialsData = await GetMaterialsByIds({ codes: materialCodes });
                if (materialsData?.data?.case) {
                    setMaterials(materialsData?.data?.materials);
                }
                setLoading(false);
            }
        } else if (dynamic === "results") {
            return;
        }
    }

    const toggleDetails = (index) => {
        setVisibleDetails(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    function extractTime(isoString) {
        const date = new Date(isoString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            {dynamic === "programs" ? (
                <p onClick={handleShow}>{title}</p>
            ) : (
                <button className='btn buttonsGroub buttonOpacity' onClick={handleShow}>{title}</button>
            )}

            <Modal show={show} className='modal' onHide={handleClose} centered>
                <div className='modalMaterial'>
                    <div className="program-card">
                        <header className="header">
                            <h1 className='font'>
                                {
                                    <Branch branchId={subject?.packData?.branchId} />
                                }
                            </h1>
                            <p className='font'>
                                {
                                    <Level levelId={subject?.packData?.levelId} />
                                }
                            </p>
                        </header>
                        {dynamic === "programs" ? (
                            loading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                    <span className='loader'></span>
                                </div>
                            ) : (
                                <div className="cards-container font">
                                    {materials && materials.map((material, index) => (
                                        <div className='row justify-content-start align-items-center mb-5' key={material._id}>
                                            <div className='col-lg-6'>
                                                <CircularProgressbar value={percentage} text={`${percentage}%`} className='w-50' />
                                            </div>
                                            <div className='col-lg-6' style={{ textAlign: "right" }}>
                                                <p className='puplicBlack'>{material.name}</p>
                                                <button className='btn buttonsGroub' onClick={() => toggleDetails(index)}>
                                                    {HomePage.details[lang]}
                                                </button>
                                            </div>

                                            {visibleDetails[index] && (
                                                <div className='details'>
                                                    <p className='puplicBlack'>{HomePage.teach[lang]}:<span>{material?.teacherId?.name}</span></p>
                                                    <p className='puplicBlack'>{HomePage.date[lang]}:<span>{material?.teacherId?.date.split('T')[0]}</span></p>
                                                    <p className='puplicBlack'>{HomePage.hour[lang]}:<span>{extractTime(material?.teacherId?.date)}</span></p>
                                                    <p className='puplicBlack'>{HomePage.superVisor_name[lang]}:<span>{material?.teacherId?.username}</span></p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )
                        ) : null}

                        {dynamic === "request" ? (
                            <div className="cards-container font" dir={`${dir}`}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='me-3'>{subject?.packData?.price}</div>
                                    <div className='headTitle w-25'>{HomePage.price[lang]}</div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center mt-3'>
                                    <div className='me-3'>{subject?.response}</div>
                                    <div className='headTitle'>{HomePage.pay_status[lang]}</div>
                                </div>
                            </div>
                        ) : null}

                        {dynamic === "results" ? (
                            <div className="cards-container font">
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div className='me-3' style={{ textAlign: "right", color: "#0E3F89" }}>{subject?.testID?.name}</div>
                                    <div className='headTitle w-50'>{HomePage.material[lang]}</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div className='me-3' style={{ textAlign: "right", color: "#0E3F89" }}>{subject?.testID?.name}</div>
                                    <div className='headTitle w-50'>{HomePage.material_test[lang]}</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div className='me-3' style={{ textAlign: "right", color: "#0E3F89" }}>{subject?.date.split("T")[0]}</div>
                                    <div className='headTitle w-50'>{HomePage.dateTest[lang]}</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div className='me-3' style={{ textAlign: "right", color: "#0E3F89" }}>{subject?.tries}</div>
                                    <div className='headTitle w-50'>{HomePage.Tries[lang]}</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div className='me-3' style={{ textAlign: "right", color: "#0E3F89" }}>{subject?.result} \ {subject?.total}</div>
                                    <div className='headTitle w-50'>{HomePage.mark[lang]}</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div className='me-3' style={{ textAlign: "right", color: "#0E3F89" }}>{subject?.note}</div>
                                    <div className='headTitle w-50'>{HomePage.mark[lang]}</div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default MaterialModal;
