import { urlBase } from "../API";
import axios from 'axios';

// new product
// {name,coverImage,images,description,price,colors,category,stock}
export const newProduct = (data)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/create-product`,
        data:data
    })
}

// delete product 
export const deleteProduct = (data)=>{
    return axios({
        method:'DELETE',
        url:`${urlBase}/api/delete-product`,
        data:data

    })
}

// get all products

export const getAllProducts = ()=>{
    return axios({
        method:'GET',
        url:`${urlBase}/api/get-products`
    })
}