import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function MenuSelection({ setStatus, status }) {
  return (
    <ButtonGroup style={{ width: '100%', height: '50px' }}>
      <button
        className='btn special color font'
        variant={status ? 'btn buttonsGroub' : ' btn special color'}
        onClick={() => setStatus(true)}
        style={{ width: '40%', borderRadius: '10px' }}
      >
        طلبات قديمة
      </button>
      <button
        className='btn buttonsGroub font'
        variant={!status ? 'btn buttonsGroub' : ' btn special color'}
        onClick={() => setStatus(false)}
        style={{ width: '40%', borderRadius: '15px' }}
      >
        طلبات جديدة
      </button>
    </ButtonGroup>
  );
}

export default MenuSelection;
