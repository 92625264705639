import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Offcanvas } from 'react-bootstrap';
import AddQuestion from './addQuestion';
import './community.css';
import { useDispatch, useSelector } from 'react-redux';
import { Inetialize_Posts } from '../../actions/community';
import Posts from './posts';
import FilterPosts from './filterPosts';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoMenu } from "react-icons/io5";
import { getTeacherClasses } from '../../utils/class';

function CommunityMain({ branchs, levels }) {
  const [postsToShow, setPostsToShow] = useState([]);
  const [Tabs, setTabs] = useState([]);
  const [myClasses, setMyClasses] = useState(null);
  const [chosenClass, setChoosenClass] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [classSelected, setClassSelected] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const { toast, user, posts, dir, programs } = useSelector(({ notify, posts, loggedUser, language, branchs, levels }) => {
    return {
      toast: notify.toast,
      user: loggedUser,
      posts,
      dir: language?.dir,
      branchs,
      levels,
      programs: loggedUser?.programs
    };
  });

  const link = useParams();

  const inetializeTabs = async () => {
    try {
      if (!branchs || !levels) return;
      let newBranchs = [];
      for (let b of branchs) {
        const subLevels = b.levelsIDS;
        const levelsOBJ = levels.filter(l => subLevels.includes(l._id));
        b.levels = levelsOBJ ? levelsOBJ : [];
        newBranchs.push(b);
      }
      setTabs(newBranchs);
    } catch (err) {
      console.warn(err);
    }
  };

  const inetializeClassesTabs = async () => {
    try {
      if (user?.user?.role === "student") {
        if (!programs) return;
        let Classes = { name: 'فصلى', levels: [] };
        for (let p of programs) {
          if (p.Class) {
            let Class = p.Class;
            Classes.levels.push(Class);
          }
        }
        setMyClasses(Classes);
      } else if (user?.user?.role === "teacher") {
        const res = await getTeacherClasses({
          id: user?.user?._id
        });

        let Classes = { name: 'فصلى', levels: [] };
        for (let p of res?.data?.classes) {
          let Class = p;
          Classes.levels.push(Class);
        }
        setMyClasses(Classes);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const dispatch = useDispatch();

  const inetialize = async () => {
    try {
      let res = { data: '' };
      if (!posts.length) {
        res = await dispatch(Inetialize_Posts());
      } else {
        res.data = { posts };
      }
      if (!link.branchId_levelId_classId) return setPostsToShow(res.data.posts);
      if (link.branchId_levelId_classId.includes('class')) {
        const classId = link.branchId_levelId_classId.split('_')[1];
        const filteredPosts = res.data.posts.filter(p => p?.classID?._id === classId);
        return setPostsToShow(filteredPosts);
      } else if (link.branchId_levelId_classId) {
        const [branchId, levelId] = link.branchId_levelId_classId.split('_');
        const filteredPosts = res.data.posts.filter(p => p?.classID?.branchID === branchId || p?.classID?.levelId === levelId);
        return setPostsToShow(filteredPosts);
      }
      setPostsToShow(res.data.posts);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    inetialize();
  }, [link?.branchId_levelId_classId, showAll]);

  useEffect(() => {
    inetializeTabs();
    inetializeClassesTabs();
  }, [branchs, levels, programs]);

  const navigate = useNavigate();
  const handleLevelClick = (branchID, levelID) => {
    const newUrl = `/bardiat-community/${branchID}_${levelID}`;
    navigate(newUrl);
    setClassSelected(true);
    setShowOffcanvas(false); // Hide Offcanvas after action
  };

  const handelClassClick = (s, ClassID) => {
    const newUrl = `/bardiat-community/class_${ClassID}`;
    navigate(newUrl);
    setClassSelected(true);
    setShowOffcanvas(false); // Hide Offcanvas after action
  };

  const [searchTerm, setSearchTerm] = useState(null);
  const handleSearch = () => {
    const filteredPosts = posts.filter(post =>
      post.content.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setPostsToShow(filteredPosts);
    setShowOffcanvas(false); // Hide Offcanvas after action
  };

  const [teacherPost, setTeacherPost] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.filterTeacherPostRoute) {
      setTeacherPost(location.state.filterTeacherPostRoute);
    }
  }, [location]);

  const handleFilterTeacherPosts = () => {
    const filteredPosts = posts.filter(post => post.postType === "teacher");
    setPostsToShow(filteredPosts);
    setShowOffcanvas(false); // Hide Offcanvas after action
  };

  useEffect(() => {
    if (teacherPost) {
      handleFilterTeacherPosts();
    }
  }, [teacherPost]);

  return (
    <Container style={{ paddingTop: '50px', paddingBottom: '50px', marginTop: "90px" }} dir={dir}>
      <Row gap={4}>
        <Col xs={12} sm={4} md={3} className='d-sm-none'>
          <Button style={{ background: "rgb(15, 42, 81)", color: "white", marginBottom: "30px", marginTop: "35px", border: "none", outline: "none" }} onClick={() => setShowOffcanvas(true)}>
            <IoMenu />
          </Button>

          <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} className="font">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className='d-flex justify-content-between align-items-center'>
                <input type='search' className='form-control mt-3' placeholder='search' onChange={(e) => setSearchTerm(e.target.value)} />
                <div>
                  <button
                    className='btn'
                    style={{
                      backgroundColor: "#0F2A51",
                      color: "white",
                    }}
                    onClick={handleSearch}>
                    Search
                  </button>
                </div>
              </div>
              <hr />

              <div>
                {classSelected && (
                  <NavLink
                    className="allPosts"
                    style={{ color: "white", marginBottom: "65px" }}
                    onClick={handleFilterTeacherPosts}
                    to='#'>
                    اسئلة المسابقات
                  </NavLink>
                )}
              </div>


              <div className='mt-5 mb-5'></div>

              <div>
                <NavLink
                  className="allPosts mt-5"
                  onClick={() => {
                    setShowAll(!showAll);
                    setShowOffcanvas(false); // Hide Offcanvas after action
                  }}
                  to='/bardiat-community' style={{ color: "white" }}>
                  عرض كل المنشورات
                </NavLink>
              </div>
              {myClasses ? (
                <FilterPosts
                  title={'الفصل الدراسى'}
                  Tabs={[myClasses]}
                  handleLevelClick={handelClassClick}
                  OutPut={setChoosenClass}
                />
              ) : <></>}
              {branchs && levels ? (
                <>
                  <br />
                  <FilterPosts
                    title={'البحث بالمستوى الدراسى'}
                    Tabs={Tabs}
                    handleLevelClick={handleLevelClick}
                    OutPut={() => { }}
                  />
                </>
              ) : <></>}
            </Offcanvas.Body>
          </Offcanvas>
        </Col>

        <Col xs={12} sm={4} md={3} className='d-none d-sm-block righFilter font' style={{ border: '0px solid', boxShadow: '0 0 2px 1px gray' }}>
          <div className=''>
            <br />
            <div className='d-flex justify-content-between align-items-center'>
              <input type='search' className='form-control mt-3' placeholder='search' onChange={(e) => setSearchTerm(e.target.value)} />
              <div>
                <button
                  className='btn'
                  style={{
                    backgroundColor: "#0F2A51",
                    color: "white",
                  }}
                  onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
            <hr />
            <div>
              {classSelected && (
                <NavLink
                  className="allPosts"
                  style={{ color: "white" }}
                  onClick={handleFilterTeacherPosts}
                  to='#'>
                  اسئلة المسابقات
                </NavLink>
              )}
            </div>

            <div className='mb-5 mt-5'>
              <NavLink
                className="allPosts"
                onClick={() => setShowAll(!showAll)}
                to='/bardiat-community' style={{ color: "white" }}>
                عرض كل المنشورات
              </NavLink>
              {myClasses ? (
                <FilterPosts
                  title={'الفصل الدراسى'}
                  Tabs={[myClasses]}
                  handleLevelClick={handelClassClick}
                  OutPut={setChoosenClass}
                />
              ) : <></>}
            </div>
            {branchs && levels ? (
              <>
                <br />
                <FilterPosts
                  title={'البحث بالمستوى الدراسى'}
                  Tabs={Tabs}
                  handleLevelClick={handleLevelClick}
                  OutPut={() => { }}
                />
              </>
            ) : <></>}
          </div>
        </Col>

        <Col xs={12} sm={8} md={9} style={{ border: '0px solid', padding: "20px", boxShadow: '0 0 2px 1px gray' }} className='righFilter'>
          <br />
          {chosenClass ? (
            <AddQuestion dir={dir} chosenClass={chosenClass} postsToShow={postsToShow} setPostsToShow={setPostsToShow} />
          ) : (
            <p className='community-note font sharedColor'>
              <b className='font' style={{ fontWeight: "900" }}>ملحوظة:</b>
              <br />
              يجب ان تكون مشترك فى فصل دراسى حتى تتمكن من المشاركة فى الاسئلة التفاعلية بين الطلاب والمعلمين ولكن يمكنك التصفح بدون اشتراك
            </p>
          )}
          <h5 className='filter-title'>
            {chosenClass ? chosenClass.name : ''}
          </h5>
          <Posts posts={postsToShow} />
        </Col>
      </Row>
    </Container>
  );
}

export default CommunityMain;
