import { urlBase } from "./API"
// create && update parents awarness content 
import axios from "axios"
export const createAwarnessContent = (data)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/create-awarness-content`,
        data:data, // {title,content,video,_id if update}
    })
}

// // updata content
// export const updateAwarnessContent = (data)=>{
//     return axios({
//         method:'POST',
//         url:`${urlBase}/api/update-content`,
//         data:data
//     })
// }
 
// get content 

export const getAwarnessContent = (data)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/get-awarness-content`,
        data:data
    })
}

