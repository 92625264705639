import { useEffect, useState } from 'react';
import './post.css';
import { createComment, getAllCommentsForPost, getPostById } from '../../utils/community';
import { useParams } from 'react-router-dom';
// import him from "../../assets/him.png";
import { getUserByID, getUsersByIDS } from '../../utils/API';
import { AiFillLike } from "react-icons/ai";
import { FaCommentAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';


//import me from "../../assets/me.png";
//import { urlBase } from '../../utils/API';

//getAllCommentsForPost
export default function SinglePostPage() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const { user } = useSelector(state => state.loggedUser);
  const { toast } = useSelector(state => state.notify);



  useEffect(() => {
    const getPost = async () => {
      try {

        const post_res = await getPostById(id);
        // console.log('post_res',post_res?.data);
        let post_obj = { ...post_res?.data };
        const author = await getUserByID({ userID: post_obj.author });
        //console.log('author',author?.data?.user);
        post_obj.user = author?.data?.user;
        setPost(post_obj);
        // console.log('post_obj',post_obj);

      } catch (error) {
        console.error(error);
      }

    }

    const getComments = async () => {
      try {
        const res = await getAllCommentsForPost(id);
        //console.log('res...',res?.comments);
        let authors_arr = res?.comments.map(el => el.author);
        let authors = await getUsersByIDS({ IDS: authors_arr });

        //console.log('authors',authors.data.users);
        authors = authors.data.users;
        let comments = res?.comments.map(el => {
          const author = authors.find(ele => ele._id == el.author);
          return {
            ...el,
            user: author
          }
        });
        setComments(comments);
        //console.log('comments',comments);
        //setComments(res?.comments);
      } catch (error) {
        console.error(error.message);
      }

    }

    getPost();

    getComments();

  }, []);

  async function addComment() {
    try {
      console.log('newComment', newComment)
      if (!user?._id) toast().error('من فضلك قم بتسجيل الدخول');

      const data = {
        author: user?._id,
        content: newComment
      };
      const res = await createComment(id, data);
      let new_comment = { ...res?.data?.comment };

      new_comment.user = user;

      //console.log('new_comment add',new_comment);

      setComments(p => [...p, new_comment]);
      setNewComment('');

    } catch (error) {
      console.error(error.message);

      setNewComment('');
    }

  }

  useEffect(() => {
    if (newComment == '') return;
    let input = document.getElementById('newCommentField');
    const enterClickListener = async function (e) {
      if (e.key == 'Enter') {
        // console.log('mmmmmmm',newComment);
        let comment = e.target.value;

        await addComment(comment);
      }
    };

    input.addEventListener('keypress', enterClickListener);

    return () => {
      console.log('clean use effect');
      input.removeEventListener('keypress', enterClickListener);
    }
  }, [newComment]);


  //console.log('comments',comments);
  console.log(post)

  return (
    <div className='d-flex justify-content-center font mt-5'>
      <div className='container'>
        <div className="my-5 post-card shadow" dir='rtl'>
          <div className="oneUserSelectedMessagesChatBox" style={{ height: 'auto' }}>
            <div>
              <div className="d-flex flex-column">
                <div>
                  {/* <img src={ him} alt="" /> */}
                </div>
                <div
                  className="oneUserSelectedMessagesTopTitle"
                // style={{ width: "150px" }}
                >
                  {post?.user?.name}
                  <p style={{ fontSize: 'x-large' }}>{post?.content}</p>

                  <div className='d-flex gap-3'>
                    <div style={{ background: '#0F2A51', color: 'white', borderRadius: '12px', padding: '5px' }}>
                      <span className='mx-1'> <AiFillLike /> </span>
                      <span className='py-1'> اعجبني  </span>
                      <span className='mx-1'>{post?.upvotes}</span>
                    </div>

                    <div style={{ background: '#0F2A51', color: 'white', borderRadius: '12px', padding: '5px' }}>
                      <span className='mx-1'> <FaCommentAlt /> </span>
                      <span className='mx-1'> التعليقات </span>
                      <span>{comments?.length}</span>
                    </div>

                  </div>

                </div>


              </div>

            </div>
            <textarea
              id='newCommentField'
              onChange={(e) => setNewComment(e.target.value)}
              value={newComment}
              placeholder='اكتب تعليقك الان'
              className='w-100 my-3'
              style={{ height: '80px', borderRadius: "5px", background: 'rgba(244, 239, 238, 1)', resize: "none", outline: "none" }}
            />

            <button
              style={{ background: '#0F2A51', color: 'white', borderRadius: '12px', padding: '5px', outline: "none" , border:"none" , width:"15%" }}
              onClick={() => {
                console.log('call 2')
                addComment(newComment);
              }}
              className='my-3'
            >  ارسل
            </button>

            {
              comments?.map((el, i) =>
                <div key={i} className="d-flex flex-column">
                  <div>
                    {/* <img src={ him} alt="" /> */}
                  </div>
                  <div
                    className="oneUserSelectedMessagesTopTitle"
                  // style={{ width: "150px" }}
                  >
                    {el?.user?.name}
                    <p style={{ fontSize: 'x-large' }}>{el?.content}</p>
                  </div>


                </div>)
            }

          </div>
        </div>
      </div>
    </div>
  )
}
