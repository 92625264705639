import React, { useState } from 'react';
import { useDispatch } from 'react-redux'; // Assuming you're using Redux
import { New_Category } from '../../../actions/store';
import { Button, Modal, Form } from 'react-bootstrap';

function AddCategory() {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const dispatch = useDispatch(); // Redux hook for dispatching actions

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch the New_Category action with the name when the form is submitted
    dispatch(New_Category({ name }));
    setName('');
    setShowModal(false);
  };

  return (
    <div>
      <Button onClick={handleOpenModal}>
        اضافة صنف
      </Button>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>اضافة صنف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>اسم اصنف</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="ادخل اسم الصنف" 
                value={name} 
                onChange={handleChange} 
                required 
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              اضافة
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddCategory;
