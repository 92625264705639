import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserByID, getUsersByIDS } from "../../../utils/API";
import "./TeacherClassesComponent.css";
import { useDispatch, useSelector } from "react-redux";
import { get_teacher_students } from "../../../actions/login&register";
// import { getPackageById } from "../../../utils/packages";
import { getLessonsByIDS } from "../../../utils/lessons";
import { addWeekStudent } from "../../../utils/class";
import { useEffect, useState } from "react";
import ConfirmModalComponent from "./ConfirmModalComponent";
import { GetMaterialsByIds } from "../../../utils/materials";
import MeetingRoomModal from "./meetingRoomLink";
import { add_class_btn, meeting_btn } from "../../../constants";
import { getPackageOfStudentOnTeacher } from "../../../utils/packagesRequests";
import { Spinner } from "react-bootstrap";
import SupervisorMaterialsModal from "./SupervisorMaterialsModal";
import MaterialSuperVisorsModal from "./MaterialSuperVisorsModal";


export default function ClassRowComponent({ classObj, type, setResultsTable, setShowModal, setSelectedStudent, setWeekStudent, teacherMaterialCode }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { classId, packId } = useParams();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSupervisorMaterialsModal, setShowSuperVisorMaterialsModal] = useState(false);
    const [materials, setMaterials] = useState([]);
    const [showMeetingLinkModal, setShowMeetingLink] = useState(false);
    const [showMaterialSuperVisorsModal, setShowMaterialSuperVisorsModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const { toast } = useSelector(state => state.notify);

    // console.log('classObj?.teachersIDS',classObj?.teachersIDS);

    const { user } = useSelector(state => state.loggedUser);


    useEffect(() => {

        const get = async () => {
            try {
                const packMaterialsArr = classObj?.packID?.materials.map(
                    (el) => el?.materialCode
                );
                const response = await GetMaterialsByIds({ codes: packMaterialsArr });
                //classObj?.teachersIDS
                let teachersArr = classObj?.teachersIDS;
                let materialsRes = response?.data?.materials;
                // console.log('materialsRes',materialsRes);
                materialsRes = materialsRes.map(m => {
                    const teacherObj = teachersArr.find(el => el.materialCode === m.code);
                    if (teacherObj?.materialCode) {
                        return {
                            ...m,
                            teacher: teacherObj.teacher
                        }
                    }
                    else {
                        return m;
                    }
                }
                )

                // console.log('materialsRes_teacher', materialsRes);

                setMaterials(materialsRes);
            } catch (error) {
                console.error(error.message);
            }
        };

        if (user?.role === "supervisor") {
            get();
        }

    }, []);



    const getTeacherStudents = async () => {
        try {
            const response = await getUsersByIDS({ IDS: classObj?.studentsIDS });

            // console.log('students res',response?.data?.users);
            dispatch(get_teacher_students(response?.data?.users));
            navigate(`classId/${classObj?._id}/packId/${classObj?.packID}`, {
                state: classObj
            });
        } catch (error) {
            console.error(error.message);
        }

    };

    const getStudentResults = async (studentID) => {
        try {
            //setLoading(true);
            const res = await getPackageOfStudentOnTeacher({ userID: studentID, packID: packId });
            const packageRes = res?.data?.package?.materials;
            // console.log('ressssssss',res?.data?.package?.materials);  
            //console.log('location.state', location.state);
            const teacherMaterialCodeObj = location.state.teachersIDS.find(el => el.teacher === user?._id);
            const selectedLessons = packageRes.find(el => el.materialCode === teacherMaterialCodeObj.materialCode)?.selectedLessons;
            //console.log('selected lessons', selectedLessons);
            const lessonsRes = await getLessonsByIDS({ lessonsIDS: selectedLessons });
            const lessons = lessonsRes?.data?.lessons;
            //console.log('lessons', lessons);
            setResultsTable(lessons);
            setSelectedStudent(studentID);

            setShowModal(true);


        } catch (error) {
            console.error(error);
        }

    }

    const setStudentAsWeekStudent = async (studentID) => {
        try {
            if (showConfirmModal) {
                const teacherMaterialCode = location.state.teachersIDS.find(el => el.teacher === user?._id)?.materialCode;

                // console.log('teacherMaterialCode',teacherMaterialCode);
                const dataObj = {
                    materialCode: teacherMaterialCode,
                    classID: classId,
                    studentID: classObj?._id
                };

                setLoading(true);
                const res = await addWeekStudent(dataObj);

                //console.log('res.data',res);

                if (res?.data?.case) {
                    // toast().success(res.data.message, {
                    //     position: "top-center"
                    // });

                    //set week student here
                    const weekStudents = res.data.currentClass.weekStudents;

                    //const teacherSelectedStudent=weekStudents.find(el=>el.materialCode==teacherMaterialCode)?.materialCode;

                    const student = res.data.currentClass.weekStudents.find(el => el.materialCode === teacherMaterialCode)?.studentID;
                    const res_student = await getUserByID({ userID: student });

                    //console.log('studentRes',res_student?.data?.user);

                    setWeekStudent(res_student?.data?.user);
                }
                else {
                    toast().error(res.data.message, {
                        position: "top-center"
                    });
                }
                setLoading(false);
            }
            else {
                //confirm modal here
                setShowConfirmModal(true);
            }
        }
        catch (error) {
            console.error(error)
        }

    }

    return (
        <div className="classBox">
            {showConfirmModal &&
                <ConfirmModalComponent
                    showModal={showConfirmModal}
                    setShowModal={setShowConfirmModal}
                    onClick={setStudentAsWeekStudent}
                />
            }
            {
                showSupervisorMaterialsModal &&
                <SupervisorMaterialsModal
                    showModal={showSupervisorMaterialsModal}
                    setShowModal={setShowSuperVisorMaterialsModal}
                    classObj={classObj}
                />
            }
            <div className="box">
                <div className="classBoxContainer font" style={{color:"white"}}>
                    {classObj?.name}

                    {
                        (type === 'class') &&
                        <div className="mt-3 font" style={{color:"white"}}>  عدد الطلاب ({classObj?.studentsIDS?.length})</div>
                    }

                    {
                        type === 'supervisor' &&
                        <>
                            <div className="mt-3 font">  عدد الطلاب ({classObj?.studentsIDS?.length})</div>

                            <div>المواد</div>
                            {
                                materials.map((el, i) =>
                                    <div key={i}>
                                        <div className="font mt-3">
                                            المادة:  {el?.name}
                                            -
                                            المعلم: {el.teacher?.name}
                                        </div>
                                    </div>

                                )
                            }

                        </>
                    }

                </div>

                <div className="d-flex flex-column justify-content-center align-items-center m-3">
                    {
                        type === 'class' && <div className="d-flex flex-column">
                            <button className="addclass_btn font"
                                onClick={() => setShowMeetingLink(true)} style={add_class_btn}>اضافة رابط اجتماع </button>
                            <MeetingRoomModal show={showMeetingLinkModal}
                                setShow={setShowMeetingLink}
                                classObj={classObj}
                            />
                            <button onClick={() => getTeacherStudents()} className="addclass_btn">الطلاب</button>
                            <button onClick={() => setShowMaterialSuperVisorsModal(true)} className="addclass_btn">تواصل مع المشرف</button>
                            <MaterialSuperVisorsModal
                                showModal={showMaterialSuperVisorsModal}
                                setShowModal={setShowMaterialSuperVisorsModal}
                                classObj={classObj}
                            />
                        </div>
                    }

                    {
                        type === 'student' && <>
                            <button onClick={() => getStudentResults(classObj?._id)} className="addclass_btn " style={add_class_btn}>  {loading && <Spinner />} عرض النتائج </button>
                            <button onClick={() => setStudentAsWeekStudent(classObj?._id)} className="addclass_btn font"  >
                                {loading && <Spinner />}       تعيين كطالب الاسبوع
                            </button>
                        </>

                    }

                    {
                        type === 'supervisor' && <>
                            <button onClick={() => setShowSuperVisorMaterialsModal(true)} className="btn btn-primary mt-3">الطلاب</button>

                        </>
                    }


                </div>
            </div>
        </div>
    )
}
