import React, { useContext } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { ThemeContext } from '../../../ThemeProvider';
import { HomePage } from "../../../utils/Translation/HomePage";
import { IoSettingsSharp } from "react-icons/io5";
import { useSelector } from "react-redux";

const SidNav = ({ minimized, user }) => {
    const { theme } = useContext(ThemeContext);



    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    let taps = [];

    if (user?.user?.role === "student") {
        taps = [
            {
                name: `${HomePage.dashboard[lang]}`,
                path: "profile",
            },
            {
                name: `${HomePage.subscriptionsRequest[lang]}`,
                path: "subscriptions",
            },
            {
                name: `${HomePage.messages[lang]}`,
                path: "messages",
            },
            {
                name: `${HomePage.results[lang]}`,
                path: "results",
            },
            {
                name: `${HomePage.dashboard_programs[lang]}`,
                path: "subjects",
            },
        ];
    } else if (user?.user?.role === "teacher") {
        taps = [
            { name: `${HomePage.subscriptions[lang]}`, path: "subscriptions" },
            { name: `${HomePage.students[lang]}`, path: "my-students" },
            { name: `${HomePage.programs[lang]}`, path: "programs-teacher" },
            { name: `${HomePage.create_Program[lang]}`, path: "create-program-teacher" },
            { name: `${HomePage.classes[lang]}`, path: "classes" },
            { name: `${HomePage.materials[lang]}`, path: "my-materials" },
            { name: `${HomePage.dashboard_society[lang]}`, path: "society" },
            { name: `${HomePage.personalInformation[lang]}`, path: "profile" },
        ];
    } else if (user?.user?.role === "parent") {
        taps = [
            { name: `${HomePage.dashboard_programs[lang]}`, path: "subjects" },
            { name: `${HomePage.results[lang]}`, path: "results" },
            // { name: HomePage.dashboard_subscription_req[lang], to: "schedual" },
            { name: `${HomePage.subscriptionsRequest[lang]}`, path: "subscriptions" },
            { name: `${HomePage.AwarenessTips[lang]}`, path: 'awareness' },
            { name: `${HomePage.personalInformation[lang]}`, path: "profile" },
        ]
    } else if (user?.user?.role === "supervisor") {
        taps = [
            { name: `${HomePage.classes[lang]}`, path: "classes" },
            // { name: "الطلاب", to: "supervisor-students" },
            { name: `${HomePage.personalInformation[lang]}`, path: "profile" },

        ]
    }

    const navigate = useNavigate();

    const handleSettingRoute = () => {
        navigate('settings')
    }


    return (
        <div style={{ position: "relative" }}>
            <div className={`sidnav ${theme}`} >
                <div className="side-menu">
                    {taps.map((t, index) => (
                        <NavLink
                            key={index}
                            to={t.path}
                            style={{ textDecoration: "none", color: `${theme === "light" ? "" : "white"}` }}
                            className={({ isActive }) => (isActive ? "active-link" : "")}
                        >
                            <span className="link-text">{t.name}</span>
                            {({ isActive }) => isActive && <span className="active-indicator"></span>}
                        </NavLink>
                    ))}
                </div>
            </div>

            {
                user?.user?.role === "teacher" ? (<div className="settings shadow" style={{ cursor: "pointer", position: "fixed", right: "10px", background: "white", padding: "10px", borderRadius: "50%" }}>
                    <IoSettingsSharp size={"25px"} onClick={handleSettingRoute} />
                </div>) : null
            }

            <div>
                <Outlet />
            </div>
        </div>
    );
}

export default SidNav;