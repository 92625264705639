import { createBranch,getBranchs,updateBranch,deleteBranch as delete_Branch, } from "../../utils/branchs";

export const ADD_BRANCH = "ADD_BRANCH";
const add_Branch = (branch)=>{
    return {
        type:ADD_BRANCH,
        branch
    }
}

export const Add_Branch = (branch)=>{
    return dispatch=>createBranch(branch).then(res=>{
        if(res.data.case){
            dispatch(add_Branch(res.data.branch))
        }
        return res
    })
}

export const DELETE_BRANCH ='DELETE_BRANCH';

const deleteBranch = (id)=>{
    return {
        type:DELETE_BRANCH,
        id
    }
}
// Delete branch
export const Delete_Branch =(id)=>{
    return (dispatch)=>{
        return delete_Branch(id).then(res=>{
        if(res.data.case){
            dispatch(deleteBranch(id))
        }
        return res
       })
    }
}
// get Branche

export const INETIALIZE_BRANCHS = 'INETIALIZE_BRANCHS'; 

const inetializeBranchs = (branchs)=>{
    return {
        type:INETIALIZE_BRANCHS,
        branchs
    }
}

export const Inetialize_Branchs = ()=>{
    return (dispatch)=>{
        return getBranchs().then(res=>{
            if(res.data.case){
                console.log(res.data)
                dispatch(inetializeBranchs(res.data.branchs))
            }
            return res
        })
    }
}

// edit Branch

export const EDIT_BRANCH = "EDIT_BRANCH";

const editBranch = (branch)=>{
   return {
    type:EDIT_BRANCH,
    branch // only properties wanted to be updated with id sent in an object
   }
}

export const Update_Branch = (branch)=>dispatch=>{
    return updateBranch(branch).then(res=>{
        if(res.data.case){
            dispatch(editBranch(res.data.branch))
        }
        return res
    })
}
