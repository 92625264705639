import React from 'react'
import dashboard from "../../../assets/dashboard.png"


import SideNav from '../modules/SideNav'
import { useSelector } from 'react-redux'
import { urlBase } from '../../../utils/API'
import ChangeImgProfile from './ChangeImgProfile'
const HeaderDashboard = () => {
    const loggedUser = useSelector((state) => state.loggedUser);
    return (
        <div className=' font dashboard' style={{ marginTop: "100px" }}>

            <div className='w-75 py-3' style={{ margin: "20px auto", height: "25vh" }}>
                <div>
                    <img src={loggedUser?.user?.coverImage ? `${urlBase}/${loggedUser?.user?.coverImage}` : ""} alt='dashboardImage' className='w-100 ' style={{ height: "25vh", zIndex:"-1"}} />
                    <div className='handleCamera'>
                        <ChangeImgProfile type={"coverImage"} />
                    </div>
                </div>
            </div>

            <div className='divProfile'>
                {/* <img src={imageProfile} alt="imageProfile" style={{ width: "80px", height: "80px", borderRadius: "50%" }} /> */}
                {
                    <>
                        <img src={loggedUser?.user?.personalImage ? `${urlBase}/${loggedUser?.user?.personalImage}` : ""} alt="imageProfile" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                        <div style={{ marginTop: "-10px", cursor: "pointer", fontSize: "20px" }} >
                            <ChangeImgProfile type={"personalImage"} />
                        </div>
                    </>
                }
                <p style={{ fontWeight: "600", fontSize: "20px" }}>{loggedUser?.user?.username}</p>
            </div>

            <div>
                <SideNav user={loggedUser} />
            </div>
        </div>
    )
}

export default HeaderDashboard
