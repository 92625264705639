import React, { useEffect, useState } from 'react';
import "../../../styles/dashboard.css";
import { useDispatch, useSelector } from 'react-redux';
import { update_user_info, Update_User_Info, Verify_Pass } from '../../../actions/login&register';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { changeUserPassword } from '../../../utils/API';

const FormAccount = () => {
    const loggedUser = useSelector((state) => state.loggedUser);

    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [pass, setPass] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showChangePass, setShowChangePass] = useState(false); // State to manage visibility
    const [oldPass, setOldPass] = useState("");
    const [newPass, setNewPass] = useState("");

    useEffect(() => {
        if (loggedUser?.auth) {
            setName(loggedUser?.user?.name);
            setEmail(loggedUser?.user?.email);
            setPhone(loggedUser?.user?.phone);
            setUserName(loggedUser?.user?.username);
        }
    }, [loggedUser]);

    const updateUser = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loading spinner
        const res = await dispatch(Verify_Pass({
            id: loggedUser?.user._id,
            password: pass
        }));

        if (!res?.data?.case) {
            setLoading(false);
            setErrorMessage("هناك مشكله في كلمة السر"); // Set error message
            return;
        }

        const infoUser = await dispatch(Update_User_Info({
            name,
            username: userName,
            email,
            phone,
            id: loggedUser?.user?._id,
        }));

        setLoading(false); // Hide loading spinner

        if (infoUser?.data?.case) {
            setMessage(infoUser?.data?.message);
            dispatch(update_user_info(infoUser?.data?.user));
        }

        setName("");
        setUserName("");
        setEmail("");
        setPhone("");
        setPass("");
        setErrorMessage("");
    };


    const changePass = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const resPass = await dispatch(Verify_Pass({
                id: loggedUser?.user._id,
                password: oldPass
            }));

            if (!resPass?.data?.case) {
                setLoading(false);
                setErrorMessage(" هناك مشكله في كلمة السر القديمه "); // Set error message
                return;
            }

            const res = await changeUserPassword({
                newPassword: newPass,
                id: loggedUser?.user?._id
            })
            if (res?.data?.case) {
                setLoading(false);
                setMessage(res?.data?.message);
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div>
            {
                loading ? (
                    <div className="spinner-container">
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : (
                    <>
                        {message && (
                            <Alert className='w-75 m-auto text-center mb-3' key={'info'} variant={'info'}>
                                {message}
                            </Alert>
                        )}
                        {errorMessage && (
                            <Alert className='w-75 m-auto text-center mb-3' key={'danger'} variant={'danger'}>
                                {errorMessage}
                            </Alert>
                        )}
                    </>
                )
            }

            {!showChangePass && (
                <div className='m-auto w-75 formAccount'>
                    <form>
                        <div className='row'>
                            <div className='col-lg-6 col-sm-12 col-md-6'>
                                <input type="text" placeholder='الاسم الاول' value={name} onChange={(e) => setName(e.target.value)} className='w-100 form-control py-3' />
                            </div>
                            <div className='col-lg-6 col-sm-12 col-md-6'>
                                <input type="text" placeholder='الاسم الثاني' value={userName} onChange={(e) => setUserName(e.target.value)} className='w-100 form-control py-3' />
                            </div>
                        </div>
                        <div>
                            <div>
                                <input type="email" placeholder='الايميل' value={email} onChange={(e) => setEmail(e.target.value)} className='w-100 form-control py-3' />
                            </div>
                        </div>
                        <div>
                            <input type="text" placeholder='رقم الهاتف' className='w-100 form-control py-3' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div>
                            <div>
                                <input type="password" value={pass} onChange={(e) => setPass(e.target.value)} placeholder='ادخل كلمة السر الخاصه بك لتاكيد التعديل' className='w-100 form-control py-3' />
                            </div>
                        </div>

                        <div className='m-auto text-center mt-3'>
                            <div>
                                <button type="button" className='btn special color buttonOpacity' onClick={() => setShowChangePass(true)}>تغيير كلمة السر</button>
                                <button type="button" className='btn buttonsGroub buttonOpacity' onClick={(e) => updateUser(e)}>حفظ</button>
                            </div>
                        </div>
                    </form>
                </div>
            )}

            {showChangePass && (
                <div className='m-auto w-75 changePass'>
                    <div>
                        <label>ادخل كلمة السر القديمه</label>
                        <input type="password" value={oldPass} onChange={(e) => setOldPass(e.target.value)} className='w-100 form-control py-3' />
                    </div>
                    <div>
                        <label>ادخل كلمة السر الجديده</label>
                        <input type="password" value={newPass} onChange={(e) => setNewPass(e.target.value)} className='w-100 form-control py-3' />
                    </div>
                    <div className='m-auto text-center mt-3'>
                        <button type="button" className='btn special color buttonOpacity' onClick={() => setShowChangePass(false)}>إلغاء</button>
                        <button type="button" className='btn buttonsGroub buttonOpacity' onClick={(e) => { changePass(e) }}>حفظ</button>
                    </div>
                </div>
            )}

            <style jsx>{`
                .spinner-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 10vh;
                }
            `}</style>
        </div>
    );
};

export default FormAccount;
