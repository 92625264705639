
import { getTranslation } from "../utils/API";
export const TRANSLATION_STATE = "TRANSLATION_STATE";

const translateState = (state) => {
    return {
        type: TRANSLATION_STATE,
        state
    }
}

export const Translation_State = (state) => {
    return (dispatch) => {
        return dispatch(translateState(state))
    }
}
// COLLECT TEXT........
export const SET_TEXT_PER_LANG = "SET_TEXT_PER_LANG";

const collectText = (text) => {
    return {
        type: SET_TEXT_PER_LANG,
        text,
    }
}
export const Collect_Text = (text) => {
    return (dispatch) => dispatch(collectText(text))
}
//handel change per text
export const HANDEL_TEXT_CHANGE = 'HANDEL_TEXT_CHANGE'
const handelTextChange = (id, text) => {
    return {
        type: HANDEL_TEXT_CHANGE,
        id,
        text,
    }
}
export const Handel_Text_Change = (id, text) => {
    return (dispatch) => dispatch(handelTextChange(id, text))
}

export const INETIALIZE_TRANSLATION = "INETIALIZE_TRANSLATION"

const inetializeTranslation = (lang) => {
    return {
        type: INETIALIZE_TRANSLATION,
        lang,
    }
}

export const InetializeTranslation = () => {
    return (dispatch) => {
        return getTranslation()
            .then(data => {
                if (data) { dispatch(inetializeTranslation(data)) }
                return data
            })
            .catch(e => console.log(e))
    }
}
// current language
export const CURRENT_LANGUAGE = "CURRENT_LANGUAGE";
const currentLanguage = (current) => {
    return {
        type: CURRENT_LANGUAGE,
        current,
    }
}
export const Set_Lang = (current) => {
    return (dispatch) => {
        dispatch(currentLanguage(current))
    }
}

