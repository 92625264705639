import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Inetialize_Orders } from '../../../actions/store'; // Assuming Inetialize_Orders is your action creator
import { Table, Form, Row, Col } from 'react-bootstrap';
import ChangeOrderState from './changeOrderState';
import OrderInvoice from './orderInvoice';

function Orders() {
    const dispatch = useDispatch();
    const { orders, categories } = useSelector(state => state.store); // Assuming categories are stored in Redux state

    const [filters, setFilters] = useState({
        userName: '',
        phone: '',
        category: '',
        productName: '',
        response: '',
    });

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                await dispatch(Inetialize_Orders()); // Dispatch the Inetialize_Orders action
            } catch (err) {
                console.log('Error fetching orders:', err);
            }
        };

        fetchOrders();
    }, [dispatch]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value.trim() === '' ? null : value,
        });
    };

    const filteredOrders = orders?.filter((order) => {
        const { userName, phone, productName, category, response } = filters;

        let userNameMatch = true;
        if (userName) {
            userNameMatch = order?.userID?.name?.trim().toLowerCase().includes(userName.toLowerCase());
        }

        let phoneMatch = true;
        if (phone) {
            phoneMatch = order?.userID?.phone?.trim().includes(phone);
        }

        let productNameMatch = true;
        if (productName) {
            productNameMatch = order?.productID?.name?.trim().toLowerCase().includes(productName.toLowerCase());
        }

        let categoryMatch = true;
        if (category) {
            categoryMatch = order?.productID?.category === category;
        }

        let responseMatch = true;
        if (response) {
            responseMatch = order.response === response;
        }

        return userNameMatch && phoneMatch && productNameMatch && categoryMatch && responseMatch;
    });

    // Order Response 
    const getResponse = (res) => {
        if (res === "PROCCESSING") {
            return "قيد المعالجة"
        }
        else if (res === "DELIVERED") {
            return "تم التسليم"
        }
        else if (res === "REJECTED") {
            return "مرفوض"
        }
        else if (res === "ON THE ROAD") {
            return "فى الطريق"
        }
    }
    return (
        <div className='font mb-5'>
            <h2 className='font mt-5 mb-5'>طلبات الشراء</h2>
            <Form>
                <Row>
                    <Col>
                        <Form.Group controlId="filterUserName">
                            <Form.Label>بحث باسم العميل</Form.Label>
                            <Form.Control
                                type="text"
                                name="userName"
                                value={filters.userName}
                                onChange={handleFilterChange}
                                placeholder="ادخل اسم العميل"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterPhone">
                            <Form.Label>بحث برقم الهاتف</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={filters.phone}
                                onChange={handleFilterChange}
                                placeholder="ادخل رقم الهاتف"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterProductName">
                            <Form.Label>بحث باسم المنتج</Form.Label>
                            <Form.Control
                                type="text"
                                name="productName"
                                value={filters.productName}
                                onChange={handleFilterChange}
                                placeholder="ادخل اسم المنتج"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="filterCategory">
                            <Form.Label>بحث بالفئة</Form.Label>
                            <Form.Control
                                as="select"
                                name="category"
                                value={filters.category}
                                onChange={handleFilterChange}
                            >
                                <option value="">اختر الفئة</option>
                                {categories.map(category => (
                                    <option key={category._id} value={category._id}>{category.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterResponse">
                            <Form.Label>بحث بالحالة</Form.Label>
                            <Form.Control
                                as="select"
                                name="response"
                                value={filters.response}
                                onChange={handleFilterChange}
                            >
                                <option value="">اختر الحالة</option>
                                <option value="PROCCESSING">قيد المعالجة</option>
                                <option value="DELIVERED">تم التسليم</option>
                                <option value="REJECTED">مرفوض</option>
                                <option value="ON THE ROAD">فى الطريق</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                </Row>
            </Form>
            {filteredOrders?.length > 0 ? (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>كود الطلب</th>
                            <th>العميل</th>
                            <th>التاريخ</th>
                            <th>المنتج</th>
                            <th>السعر</th>
                            <th>العدد</th>
                            <th>الهاتف</th>
                            <th>العنوان</th>
                            <th>فوترة</th>
                            <th>الحالة</th>
                            <th>تغيير الحالة</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: '13px' }}>
                        {filteredOrders.map((order) => (
                            <tr key={order._id}>
                                <td>{order._id}</td>
                                <td>{order?.userID?.name}</td>
                                <td>{new Date(order.date).toLocaleString()}</td>
                                <td>{order.productID?.name}</td>
                                <td>{order.productID?.price}</td>
                                <td>{order.units}</td>
                                <td>{order?.userID?.phone}</td>
                                <td>{order.address}</td>
                                <td><OrderInvoice getResponse={getResponse} order={order} /></td>
                                <td>{getResponse(order.response)}</td>
                                <td><ChangeOrderState id={order._id} oldState={order.response} /></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <h2>لايوجدطلبات</h2>
            )}
        </div>
    );
}

export default Orders;
