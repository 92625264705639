// Level.js
import React, { useEffect, useState } from 'react';
import { getLevelById } from '../../../utils/API';
import { getBranchByID } from '../../../utils/branchs';

const Branch = ({ branchId }) => {
    const [branchName, setBranchName] = useState('');

    useEffect(() => {
        const fetchBranchName = async () => {
            const branch = await getBranchByID({ _id: branchId });
            setBranchName(branch?.data?.branch?.name || 'Unknown');
        };

        if (branchId) {
            fetchBranchName();
        }
    }, [branchId]);

    return (
        <span>{branchName}</span>
    );
};

export default Branch;
