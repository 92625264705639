import React from 'react'
import BuyRequests from '../../components-old/Admin/requests/requests'
import { useSelector } from 'react-redux';

const Subscription = () => {
    const loggedUser = useSelector((state)=>state.loggedUser);
    console.log(loggedUser)
    return (
        <div className='container'>
            <BuyRequests role="teacher" teacherId={loggedUser?.user?._id}/>
        </div>
    )
}

export default Subscription
