import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Inetialize_Branchs } from '../../../actions/curriculums/branchs';
// import { BoxLoading, WaveLoading, SolarSystemLoading } from 'react-loadingg';
import { GrView } from 'react-icons/gr';
import { Container, Table, Button, Modal, Row, Col } from 'react-bootstrap';
import ModalEdit from './editBranch';
import ModalDelete from './delete_Branch';
import ModalAddMaterial from '../levels/ModalAddMaterial';
import ExpandedContent from '../levels/ExpandedContent';
import AddLevel from '../levels/AddLevelComponent';
import AddBranch from './newBranch';
import ExpandedLevels from './expandLevles';
import "./branchs.css"
const AllBranchs = () => {

  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState('');
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [selectedLevelId, setSelectedLevelId] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoad(true);
    dispatch(Inetialize_Branchs())
      .then((res) => {
        setLoad(false);
        if (!res.data.case) {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.warn(err);
        setMessage(err.message);
      });
  }, [message]);
// 
  const branchsContainer = useSelector((state) => state.branchs);
//  
const {materials,levels} =useSelector(state=>{
    return {materials:state.materials,levels:state.levels}
  })

  const handleShowAddMaterialModal = (levelId) => {
    setSelectedLevelId(levelId);
    setShowAddMaterialModal(true);
  };
//   
  const handleCloseAddMaterialModal = () => {
    setSelectedLevelId(null);
    setShowAddMaterialModal(false);
  };

  const toggleRow = (levelId) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(levelId) ? prevRows.filter((row) => row !== levelId) : [...prevRows, levelId]
    );
  };

  return (
    <div className='mt-5'>
    <AddBranch/>
    <div className="">
    <span style={{ color: 'red' }}>{message}</span>

    <Table responsive bordered hover className='mt-5 font'>
      <thead>
        <tr>
          <th>الفئة</th>
          <th>اضافة مستوى</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {branchsContainer.length ? (
          branchsContainer.map((item) => (
            <React.Fragment key={item._id}>
              <tr className='branch-row' style={{ cursor: 'pointer' }}>
                <td>{item.name}</td>
                <td>
                  <AddLevel branchID={item._id} levelsIDS={item.levelsIDS?item.levelsIDS:[]}/>
                </td>
                <td>
                  <div className="d-flex justify-content-around">
                    <ModalEdit item={item} setMessage={setMessage} />
                    <ModalDelete id={item._id} setMessage={setMessage} />
                    <GrView onClick={() => toggleRow(item._id)} size="25px" color="#0F2A51" />
                  </div>
                </td>
              </tr>
              {expandedRows.includes(item._id) && (
                <tr>
                  <td colSpan="3">
                    <ExpandedLevels levels={levels.filter((level) => item?.levelsIDS.includes(level._id))}
                    materials={materials}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan="3" className="text-center">
              <h2>لا يوجد فئات حاليا</h2>
              <div className="loaderIcon">
              <span className="loader"></span>
            </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
  </div>
  );
};

export default AllBranchs;
