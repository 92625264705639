import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { createAwarnessContent, getAwarnessContent } from '../../../utils/content';
import { uploadSingleFile } from '../../../utils/upload';
import { urlBase } from '../../../utils/API';

function AddAwareness() {
  const [content, setContent] = useState({ title: '', content: '', video: {} });
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    // Example to get content, replace with actual id or logic to fetch content
    const fetchContent = async () => {
      try {
        const fetchedContent = await getAwarnessContent({});
        if (fetchedContent?.data?.awarnessContent) {
          const currentContent = fetchedContent.data.awarnessContent[0];
          setContent(currentContent?.awarnessContent);
          setEditingId(currentContent._id);
        }
      } catch (error) {
        console.error('Failed to fetch content:', error);
      }
    };

    // Example: Uncomment and replace 'contentId' with actual content ID
    fetchContent();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContent((prevContent) => ({ ...prevContent, [name]: value }));
  };

  const handleVideoChange = (e) => {
    const { value } = e.target;
    setContent((prevContent) => ({ ...prevContent, video: { url: value } }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createAwarnessContent(editingId ? { _id: editingId, ...content } : content);
      console.log('Content saved:', response);
      setContent(response.data?.awarnessContent?.awarnessContent)
    } catch (error) {
      console.error('Failed to save content:', error);
    }
  };

  const uploadFile = async(e)=>{
    try{

     const formData = new FormData();
     const fileToUpload = e.target.files[0];
     formData.append("file",fileToUpload);
     const res = await uploadSingleFile(formData);
    //  console.log(res?.data)
    setContent((prevContent) => ({ ...prevContent, video: { name: res.data?.fileName} }));
    }
    catch(err){
      console.warn(err)
    }
  }
  
  return (
    <Container className='font'>
      <h2>{editingId ? 'تحديث' : 'اضافة'} المحتوى التوعوى</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle">
          <Form.Label>العنوان</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={content?.title}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formContent">
          <Form.Label>المحتوى</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="content"
            value={content?.content}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formVideoUrl">
          <Form.Label>ملف الفيديو</Form.Label>
          <Form.Control
            type="file"
            name="videoUrl"
            onChange={uploadFile}
          />
        </Form.Group>
        <video width={'300px'}>
          <source src={`${urlBase}/${content?.video?.name}`}></source>
        </video>
        <Button variant="primary" type="submit">
          {editingId ? 'تحديث' : 'اضافة'} المحتوى
        </Button>
      </Form>
    </Container>
  );
}

export default AddAwareness;
