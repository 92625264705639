// find element in an array
export const findByID = (c, p, id) => {
  return c.filter((e) => e[p] === id);
};
// ----filter by name
export const filterByntype = (value, source,type) => {
  try {
    let data = [];

    if (value !== "") {
      const newValue = value.trim().toLowerCase();
      //let arr= source.filter((p) => p.name );
      data = source.filter((p) => p[type].toLowerCase().includes(newValue));
      return data;
    } else {
      return source;
    }
  } catch (err) {
    console.log(err);
  }
};

export const filterByPhone = (value, source) => {
  try {
    if (value !== "") {
      const newValue = value.trim();
      return source.filter((p) => p.phone.includes(newValue));
    } else {
      return source;
    }
  } catch (e) {
    console.log(e);
  }
};
export const filterByEmail = (value, source) => {
  try {
    if (value !== "") {
      const newValue = value.trim();
      return source.filter((p) => p.email.includes(newValue));
    } else {
      return source;
    }
  } catch (e) {
    console.log(e);
  }
};
export const filterByIdentefication = (value, source) => {
  try {
    if (value !== "") {
      const newValue = value.trim();
      return source.filter((p) =>
        p.identification.toString().includes(newValue)
      );
    } else {
      return source;
    }
  } catch (e) {
    console.log(e);
  }
};

//-----trim String object data-------
export const trimStringData = (data) => {
  const keys = Object.keys(data);
  let newObj = {};
  keys.forEach((e) => {
    if (typeof data[e] == "string") {
      newObj[e] = data[e].trim();
    } else {
      newObj[e] = data[e];
    }
  });
  return newObj;
};
//-----convert array to object------
export function convertArrToObj(arr, key) {
  let obj = {};
  for (let i = 0; i < arr.length; i++) {
    const newkey = arr[i][key];
    obj[newkey] = arr[i];
  }
  return obj;
}

// --------convert obj to arr
export const convertObjToArr = (obj) => {
  const keys = Object.keys(obj);
  let arr = [];
  keys.forEach((k) => {
    arr.push(obj[k]);
  });
  return arr;
};

//convert date as an array of year , month , day
export function getdate(date) {
  const arr = date.split("-");
  const year = arr[0];
  const month = arr[1];
  const arr1 = arr[2].split("T");
  const day = arr1[0];
  return [year, month, day];
}

//sort by date
const sortByDate = (a, b) => {
  const date1 = getdate(a.date);
  const date2 = getdate(b.date);
  if (date1[0] - date2[0] !== 0) return date1[0] - date2[0];
  if (date1[1] - date2[1] !== 0) return date1[1] - date2[1];
  if (date1[2] - date2[2] !== 0) return date1[2] - date2[2];
};


//
export const createArray = (length) => {
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push(i);
  }
  return arr;
};
//
export function getDateXDays(numOfDays, date = new Date()) {
  const daysAgo = new Date(date.getTime());

  daysAgo.setDate(date.getDate() + 1 + numOfDays);

  return daysAgo;
}
export function getMonthAfter(numofmonth, dat ) {
  const date = new Date(dat)
  const daysAgo = new Date(date.getTime());
  daysAgo.setMonth(date.getMonth() + numofmonth);
  return daysAgo;
}
//---------------------------------Teacher bausy hours----------------
export function getBusyHours(appointments, packageName, subCategory) {
  try {
    var busy = {};
    appointments.forEach((a, i) => {
      const days = a.days; // weekly days
      // console.log(new Date(a.startDate).getHours())
      const time =new Date(a.startDate).getHours();
    
        days.forEach((d, i) => {
          busy = {
            ...busy,
            [d]: busy[d] ? [...busy[d], time] : [time],
          };
        });
      
    });
    // console.log('busy',busy)
    return busy;
  } catch (e) {
    console.log(e.message);
  }
}
//................
//appointments ==> teacher appointments.
// packageName & subCategory ==> from package details.
// intersection of arrays

function getInterSection(arr1,arr2){
     const arr3 = arr1.filter(e=>arr2.includes(e))
     return arr3
}
//
export function groupIntersection(group,key){
  // console.log(group)
  // const arr1 = group[0][key];
  // const arr2 = group[1][key]
  // console.log(getInterSection(arr1,arr2))

   const length = group.length
  //  let loop = length%2 ? length-2 : length -  
   var i =0;

  //  getInterSection(group[0][key],group[1][key])
   var newArr = []
    while (i< length) {
       if(i===0){
       newArr=  getInterSection(group[i][key],group[i+1][key])
         i+=2
       }
       else{
       newArr= getInterSection(newArr,group[i][key])
        i++
       }
      //  console.log('newArr',newArr)
    }
    return newArr
}
export const onChooseItem =(e,p,aimClass)=>{ //
  
//e:event
//p:new class

  let arr = document.querySelectorAll(aimClass);
  arr.forEach(a=>{
    a.classList.remove(p);
    
  });
  if(e.target.className.includes(p)){
    e.target.classList.remove(p);  
    
    // console.log(e.target.classList)
  }
  if(!e.target.className.includes(p)){
    
    e.target.classList.add(p);
    
  }

}