import React from 'react'
import imageJoin from '../../assets/joinImage.png'
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
const JoinUs = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='join background' style={{ width: "90%", color: "white", margin: "200px auto", borderRadius: "15px" }}>
            <div className='container'>
                <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-6 col-sm-12 col-md-6' style={{ marginTop: "-100px" }}>
                        <img src={imageJoin} alt="imageJoin" className='imgJoin' />
                    </div>
                    <div className='col-lg-6 col-sm-12 col-md-6 handleJoin' style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                        <h4>{HomePage.joinUs[lang]}</h4>
                        <p className='mt-5'>
                            {HomePage.joinUsContent[lang]}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinUs
