// import React, { useEffect, useState } from "react";
// import "./styles/StdResulte.css";
// import { useDispatch, useSelector } from "react-redux";
// import ShowProgramsComponent from "./ShowProgramsComponent";
// import ResultsTableComponent from "./ResultsTableComponent";
// import ShowSelectedTestResultsComponent from "./ShowSelectedTestResultsComponent";
// import { programs_per_user } from "../../../../actions/login&register";

// function StdResults(props) {
//   const [resultsTable, setResultsTable] = useState([]);
//   const [showResultsComponent, setShowResultsComponent] = useState(false);
//   const [selectedTest, setSelectedTest] = useState();

//   const dispatch = useDispatch();

//   const loggedUser = useSelector((state) => state.loggedUser);

//   const fetchPrograms = async () => {
//     if (loggedUser?.user?.role === "student") {
//       await dispatch(programs_per_user({
//         userID: loggedUser?.user?._id
//       }));
//     } else if (loggedUser?.user?.role === "parent") {
//       await dispatch(programs_per_user({
//         userID: loggedUser?.user?.childId
//       }));
//     }
//   };

//   useEffect(() => {
//     fetchPrograms();
//   }, []);

//   const programs = useSelector(state => state.loggedUser.programs);

//   console.log(programs)

//   //console.log('resultsTable',resultsTable);


//   return (
//     <div className="stdResults" style={{ overFlow: 'auto' }}>
//       <div className="">
//         <div className='sharedBackground'>
//           <p>النتائج</p>
//         </div>
//         <div className="container mainBgDiv pb-5 pt-3 ">
//           <div className="w-100">
//             {
//               showResultsComponent ?
//                 <ShowSelectedTestResultsComponent selectedTest={selectedTest} />
//                 :
//                 // لصفحة النتائج الخاصة بالطالب
//                 resultsTable && resultsTable.length === 0 ?
//                   programs && programs.map((p, i) =>
//                     <ShowProgramsComponent key={p?._id} program={p} setResultsTable={setResultsTable} />
//                   )
//                   :
//                   <ResultsTableComponent
//                     resultsTable={resultsTable}
//                     setSelectedTest={setSelectedTest}
//                     setShowResultsComponent={setShowResultsComponent}
//                   />
//             }

//           </div>

//         </div>
//       </div>
//     </div>
//   );
// }

// export default StdResults;

import React, { useEffect, useState } from "react";
import "./styles/StdResulte.css";
import { useDispatch, useSelector } from "react-redux";
import ShowProgramsComponent from "./ShowProgramsComponent";
import ResultsTableComponent from "./ResultsTableComponent";
import ShowSelectedTestResultsComponent from "./ShowSelectedTestResultsComponent";
import { programs_per_user } from "../../../../actions/login&register";
import { HomePage } from "../../../../utils/Translation/HomePage";

function StdResults(props) {
  const [resultsTable, setResultsTable] = useState([]);
  const [showResultsComponent, setShowResultsComponent] = useState(false);
  const [selectedTest, setSelectedTest] = useState();
  const [loading, setLoading] = useState(true); // Loading state

  const dispatch = useDispatch();

  const loggedUser = useSelector((state) => state.loggedUser);

  const fetchPrograms = async () => {
    setLoading(true); // Start loading
    try {
      if (loggedUser?.user?.role === "student") {
        await dispatch(
          programs_per_user({
            userID: loggedUser?.user?._id,
          })
        );
      } else if (loggedUser?.user?.role === "parent") {
        await dispatch(
          programs_per_user({
            userID: loggedUser?.user?.childId,
          })
        );
      }
    } catch (error) {
      console.error("Error fetching programs:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  const programs = useSelector((state) => state.loggedUser.programs);

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <div className="stdResults" style={{ overflow: "auto" }}>
      <div className="">
        <div className="sharedBackground">
          <p>{HomePage.results[lang]}</p>
        </div>
        <div className="container mainBgDiv pb-5 pt-3 ">
          <div className="w-100">
            {loading ? (
              <div className="text-center my-5">
                <div className="loaderIcon">
                  <span className="loader"></span>
                </div>
              </div>
            ) : showResultsComponent ? (
              <ShowSelectedTestResultsComponent selectedTest={selectedTest} />
            ) : resultsTable && resultsTable.length === 0 ? (
              programs &&
              programs.map((p, i) => (
                <ShowProgramsComponent
                  key={p?._id}
                  program={p}
                  setResultsTable={setResultsTable}
                />
              ))
            ) : (
              <ResultsTableComponent
                resultsTable={resultsTable}
                setSelectedTest={setSelectedTest}
                setShowResultsComponent={setShowResultsComponent}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StdResults;
