// import React, { useState } from 'react';
// import { update_User, updateTeacher, uploadFileLocaly, urlBase } from '../../utils/API';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { ErrorMsg, success } from '../../components/shared/modules/Notifications';
// import { update_user_info } from '../../actions/login&register';
// import axios from 'axios';

// const Settings = () => {
//     const loggedUser = useSelector((state) => state.loggedUser);
//     const [photo, setPhoto] = useState(loggedUser?.user?.photo);
//     const [logo, setLogo] = useState(loggedUser?.user?.logo);
//     const [logoName, setLogoName] = useState(loggedUser?.user?.logo);
//     const [photoName, setPhotoName] = useState(loggedUser?.user?.photo);
//     const [brandName, setBrandName] = useState(loggedUser?.user?.brandName);
//     const [about, setAbout] = useState(loggedUser?.user?.about);
//     const [myWallet, setMyWallet] = useState(loggedUser?.user?.myWallet);
//     const [loading, setLoading] = useState(false); // حالة التحميل
//     const [progress, setProgress] = useState(0);

//     const params = useParams();
//     const dispatch = useDispatch();

//     const handelSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true); // بدأ التحميل
//         let uploadedLogoName = null;
//         let uploadedPhotoName = null;

//         if (logo) {
//             try {
//                 const logoRes = await uploadingFileLocally(logo);
//                 uploadedLogoName = logoRes?.data?.data?.name;
//                 if (uploadedLogoName) {
//                     console.log(uploadedLogoName);
//                     setLogoName(uploadedLogoName);
//                 }
//             } catch (error) {
//                 console.error("Error uploading logo:", error);
//                 ErrorMsg("Failed to upload logo");
//             }
//         }

//         if (photo) {
//             try {
//                 const photoRes = await uploadingFileLocally(photo);
//                 uploadedPhotoName = photoRes?.data?.data?.name;
//                 if (uploadedPhotoName) {
//                     console.log(uploadedPhotoName);
//                     setPhotoName(uploadedPhotoName);
//                 }
//             } catch (error) {
//                 console.error("Error uploading photo:", error);
//                 ErrorMsg("Failed to upload photo");
//             }
//         }

//         if (uploadedLogoName || uploadedPhotoName) {
//             try {
//                 const res = await updateTeacher({
//                     logo: uploadedLogoName || logoName,
//                     photo: uploadedPhotoName || photoName,
//                     about,
//                     brandName,
//                     userId: params?.id,
//                     myWallet,
//                 });
//                 console.log(res);

//                 if (res?.data?.case) {
//                     success(res?.data?.message);
//                     setLogo(null);
//                     setPhoto(null);
//                     setAbout(null);
//                     setBrandName(null);
//                 }
//             } catch (e) {
//                 console.error("Error updating teacher:", e);
//                 ErrorMsg("Failed to update teacher information");
//             }
//         }
//         setLoading(false); // انتهاء التحميل
//     };

//     function uploadingFileLocally(data) {
//         const formData = new FormData();
//         formData.append("file", data)
//         return axios({
//             method: "POST",
//             url: `${urlBase}/api/upload-file-locally?userId=${params?.id}`,
//             data: formData,
//             onUploadProgress: (progressEvent) => {
//                 const percentage = Math.round(
//                     (progressEvent.loaded * 100) / progressEvent.total
//                 );
//                 setProgress(percentage)

//             }
//         })
//     }

//     return (
//         <div>
//             <main id="contact-us">
//                 <div className='container'>
//                     <div className='contactForm'>
//                         <div className=' mb-5'>
//                             <div className='w-75 m-auto'>
//                                 <div className='m-auto mx-5 formContactData shadow mb-5'>
//                                     <form>
//                                         <div style={{ textAlign: "right" }}>
//                                             <label className='mb-2'>ادخل صورة الشعار</label>
//                                             <input type="file" onChange={(e) => setLogo(e.target.files[0])} className='w-100 form-control py-3' />
//                                         </div>
//                                         <div style={{ textAlign: "right" }}>
//                                             <label className='mb-2'>ادخل الصوره الشخصيه</label>
//                                             <input type="file" onChange={(e) => setPhoto(e.target.files[0])} className='w-100 form-control py-3' />
//                                         </div>

//                                         <div>
//                                             <input type="text"
//                                                 onChange={(e) => setAbout(e.target.value)}
//                                                 value={about || ''}
//                                                 placeholder='نبذه عنا' className='w-100 form-control py-3' />
//                                         </div>
//                                         <div>
//                                             <input type="text"
//                                                 onChange={(e) => setBrandName(e.target.value)}
//                                                 value={brandName || ''}
//                                                 placeholder='الاكاديميه' className='w-100 form-control py-3' />
//                                         </div>
//                                         <div style={{ textAlign: "right" }}>
//                                             <label className='mb-2'>رقم المحفظه</label>
//                                             <input type="number"
//                                                 onChange={(e) => setMyWallet(e.target.value)}
//                                                 value={myWallet || ''}
//                                                 placeholder='رقم المحفظه' className='w-100 form-control py-3' />
//                                         </div>

//                                         <div className='divBtn'>
//                                             <button className='btn sendNow' onClick={(e) => { handelSubmit(e) }}>تعديل</button>
//                                         </div>
//                                     </form>
//                                     {loading && <div className="loading-spinner">جار التحميل...</div>} {/* عرض مؤشر التحميل */}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </div>
//     );
// }

// export default Settings;


import React, { useState } from 'react';
import { updateTeacher, uploadFileLocaly, urlBase } from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorMsg, success } from '../../components/shared/modules/Notifications';
import axios from 'axios';

const Settings = () => {
    const loggedUser = useSelector((state) => state.loggedUser);
    const [photo, setPhoto] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logoName, setLogoName] = useState(loggedUser?.user?.logo);
    const [photoName, setPhotoName] = useState(loggedUser?.user?.photo);
    const [brandName, setBrandName] = useState(loggedUser?.user?.brandName);
    const [about, setAbout] = useState(loggedUser?.user?.about);
    const [myWallet, setMyWallet] = useState(loggedUser?.user?.myWallet);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const params = useParams();
    const dispatch = useDispatch();

    const handelSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Initialize with current values
        let uploadedLogoName = logoName;
        let uploadedPhotoName = photoName;

        if (logo) {
            try {
                const logoRes = await uploadingFileLocally(logo);
                uploadedLogoName = logoRes?.data?.data?.name || logoName;
                if (uploadedLogoName !== logoName) {
                    console.log(uploadedLogoName);
                    setLogoName(uploadedLogoName);
                }
            } catch (error) {
                console.error("Error uploading logo:", error);
                ErrorMsg("Failed to upload logo");
            }
        }

        if (photo) {
            try {
                const photoRes = await uploadingFileLocally(photo);
                uploadedPhotoName = photoRes?.data?.data?.name || photoName;
                if (uploadedPhotoName !== photoName) {
                    console.log(uploadedPhotoName);
                    setPhotoName(uploadedPhotoName);
                }
            } catch (error) {
                console.error("Error uploading photo:", error);
                ErrorMsg("Failed to upload photo");
            }
        }

        try {
            const res = await updateTeacher({
                logo: uploadedLogoName,
                photo: uploadedPhotoName,
                about,
                brandName,
                userId: params?.id,
                myWallet,
            });
            console.log(res);

            if (res?.data?.case) {
                success(res?.data?.message);
                setLogo(null);
                setPhoto(null);
                setAbout(null);
                setBrandName(null);
            }
        } catch (e) {
            console.error("Error updating teacher:", e);
            ErrorMsg("Failed to update teacher information");
        }

        setLoading(false);
    };

    function uploadingFileLocally(data) {
        const formData = new FormData();
        formData.append("file", data);
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${params?.id}`,
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentage);
            }
        });
    }

    return (
        <div>
            <main id="contact-us">
                <div className='container'>
                    <div className='contactForm'>
                        <div className=' mb-5'>
                            <div className='w-75 m-auto'>
                                <div className='m-auto mx-5 formContactData shadow mb-5'>
                                    <form>
                                        <div style={{ textAlign: "right" }}>
                                            <label className='mb-2'>ادخل صورة الشعار</label>
                                            <input type="file" onChange={(e) => setLogo(e.target.files[0])} className='w-100 form-control py-3' />
                                        </div>
                                        <div style={{ textAlign: "right" }}>
                                            <label className='mb-2'>ادخل الصوره الشخصيه</label>
                                            <input type="file" onChange={(e) => setPhoto(e.target.files[0])} className='w-100 form-control py-3' />
                                        </div>

                                        <div>
                                            <input type="text"
                                                onChange={(e) => setAbout(e.target.value)}
                                                value={about || ''}
                                                placeholder='نبذه عنا' className='w-100 form-control py-3' />
                                        </div>
                                        <div>
                                            <input type="text"
                                                onChange={(e) => setBrandName(e.target.value)}
                                                value={brandName || ''}
                                                placeholder='الاكاديميه' className='w-100 form-control py-3' />
                                        </div>
                                        <div style={{ textAlign: "right" }}>
                                            <label className='mb-2'>رقم المحفظه</label>
                                            <input type="number"
                                                onChange={(e) => setMyWallet(e.target.value)}
                                                value={myWallet || ''}
                                                placeholder='رقم المحفظه' className='w-100 form-control py-3' />
                                        </div>

                                        <div className='divBtn'>
                                            <button className='btn sendNow' onClick={(e) => { handelSubmit(e) }}>تعديل</button>
                                        </div>
                                    </form>
                                    {loading && <div className="loading-spinner">جار التحميل...</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Settings;
