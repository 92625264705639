import { urlBase } from "./API";
import axios from "axios";
export const setSubscribe = (data)=>{
    console.log(data)
    return axios({
        method:"POST",
        url:`${urlBase}/api/subscribe/user`,
        data:data,//Request: {subscriber,target} ​ Target: id of the teacher i want to subscrib , Subscriber: current user id
        withCredentials:true
    })
}