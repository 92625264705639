import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Container } from "react-bootstrap";
import Col from "../styled-components/col";
import NewAccount from "./newAccount/newAccount";
import "./teacher.css";
import { FaRegTrashAlt } from "react-icons/fa";
import { Delete_Teacher } from "../../actions/teachers.js";

function TeachersList() {
  const [render, setRender] = useState(false);
  const dispatch = useDispatch();
  const teachers = useSelector(state => state.users.teachers);

  useEffect(() => {

  }, [render]);

  return (
    <Container className="teacher-admin-page" style={{ height: '100%' }}>
      <Col fg='1' dir='column'>
        <NewAccount type='teacher' />
        <Col width={"80%"} dir="column" align={"flex-start"}>
          <div className="scroll-overflow" style={{ width: "100%" }}>
            <Table responsive striped bordered hover className="font" style={{ fontSize: "20px" }}
            >
              <thead>
                <tr>
                  <th>Image</th>
                  <th>الاسم</th>
                  <th>البريد الالكترونى</th>
                  <th>تاريخ التسجيل</th>
                  <th>حذف</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "20px" }}>
                {teachers.map((t, i) => (
                  <TeacherList
                    key={i}
                    item={t}
                    icon={
                      <FaRegTrashAlt
                        size='20px'
                        onClick={() => {
                          dispatch(Delete_Teacher(t._id, t.role));
                          setRender(!render);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    }
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Col>
    </Container>
  );
}

function TeacherList({ item, icon }) {
  const { loggedUser } = useSelector(({ users, loggedUser }) => {
    return {
      loggedUser
    };
  });
  const IM = item.personalImage ? item.personalImage : '';

  return (
    <tr style={{ fontSize: "20px" }}>
      <td style={{ fontSize: "18px" }}><img src={IM} alt="teacher" style={{ width: "50px", height: "50px", }} /></td>
      <td style={{ fontSize: "18px" }}>{item.name}</td>
      <td style={{ fontSize: "18px" }}>{item.email}</td>
      <td style={{ fontSize: "18px" }}>{item.date.split('T')[0]}</td>
      <td style={{ fontSize: "18px" }}>{icon}</td>
    </tr>
  )
}

export default TeachersList;
