import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GetMaterialsByIds } from "../../../utils/materials";
import { useNavigate } from "react-router-dom";
import { getUsersByIDS } from "../../../utils/API";
// GetMaterialsByIds
export default function SupervisorMaterialsModal({showModal,setShowModal,classObj}) {
    const navigate=useNavigate();
    const[loading,setLoading]=useState(false);
    const[materials,setMaterials]=useState(null);

    useEffect(()=>{
        const get=async()=>{
            try {
                console.log('class obj in modal',classObj.supervisorIDS);
            setLoading(true);
            let materialCodesArr=[];
            classObj?.supervisorIDS.map(el=>materialCodesArr.push(el?.materialCode));
           //console.log('materialCodesArr',materialCodesArr);
            const res=await GetMaterialsByIds({codes:materialCodesArr});
            setMaterials(res?.data?.materials);
            setLoading(false);
            //console.log('res',res);

            } catch (error) {
                console.error(error);
            }
            
        };

        get();
        
    },[]);

    const getSuperVisorStudents = async (selectedMaterial) => {
        try {
          setLoading(true);
            console.log('selectedMaterial',selectedMaterial);
            const selectedMaterialStudents=classObj?.supervisorIDS?.find(el=>el.materialCode==selectedMaterial.code);
            //console.log('selectedMaterialStudents',selectedMaterialStudents.students);

            const response_students=await getUsersByIDS({IDS:selectedMaterialStudents.students});
            console.log('response_students',response_students?.data?.users);
            classObj={
              ...classObj,
              selectedMaterialstudentIDS:response_students?.data?.users,
              selectedMaterial:selectedMaterial
            }

            setLoading(false);

           // console.log('classObj_edit',classObj);

            navigate(`classId/${classObj?._id}/packId/${classObj?.packID?._id}`, {
                state: classObj
            });
        }
        catch (error) {
            console.error(error.message);
        }
    }

    console.log('materials',materials);

  return (
    <Modal
    show={showModal}
    onHide={() => setShowModal(false)}
    style={{ direction: "rtl" }}
    centered
   

  >
    <Modal.Header closeButton={false}>
      <Modal.Title className="font" style={{ color: "#0F2A51" }}> {loading&&<Spinner />} إختر المادة :</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ol>
        {materials?.map((el, i) => (
          <li key={i} 
          className="font"
          onClick={()=>getSuperVisorStudents(el)}
          style={{ cursor: "pointer" }}>
            {el?.name}
          </li>
        ))}
      </ol>
      <div className="d-flex justify-content-end font">
        <button className='btn special color' onClick={()=>setShowModal(false)}> الغاء </button>
      </div>
    </Modal.Body>
  </Modal>
  )
}
