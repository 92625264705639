import React, { useEffect, useState } from 'react';
import AddClass from './addClass';
import { Get_Classes } from '../../../actions/class';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import DeactivateClass from './deactivateClass';
import { getStudents } from '../../../utils/API';
import AddStudentsModal from './addStudentsModal';
import ClassStudents from './classStudents';
import SendGroupMessage from './sendGroupMessage';
import AddTeacherToClass from './addTeacherToClass';
import "./classes.css"
import { Dropdown, DropdownButton, DropdownMenu } from 'react-bootstrap';
import AddSupervisorModal from './addSupervisorModal';
import MenuBar from './menuBar';
import DayTimeSelector from './addTimes';
import { GetMaterialsByIds } from '../../../utils/materials';
import SingleClass from './class';
// import Schedual from './schedual';

function Classes() {

    const [status, setStatus] = useState(true);
    const [students, setStudents] = useState([]);
    const [Class, setClass] = useState(null)

    const { toast } = useSelector(({ notify }) => {
        return { toast: notify.toast }
    })

    const loggedUser = useSelector((state) => state.loggedUser)

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchStudentsAndClasses = async () => {
            try {
                const res = await getStudents();
                setStudents(res.data.users)
                await dispatch(Get_Classes());
            }
            catch (err) {
                console.warn(err.message)
            }
        }
        fetchStudentsAndClasses();
    }, [dispatch]);

    const { classes, packages } = useSelector(({ classes, packages }) => ({
        classes, packages
    }));

    const getPackageByID = (id) => {
        const pack = packages.find(p => p._id === id);
        return pack;
    }

    // // Filter classes based on active/inactive status
    // const filteredClasses = status ? classes.filter(cls => cls.active) : classes.filter(cls => !cls.active);
    // // const filteredClasses = status 
    // // ? classes.filter(cls => cls.active && cls.teachersIDS.some(teacherObj => teacherObj?.teacher._id === loggedUser?.user?._id))
    // // : classes.filter(cls => !cls.active && cls.teachersIDS.some(teacherObj => teacherObj?.teacher._id === loggedUser?.user?._id));

    const filteredClasses = loggedUser?.user?.role === "Admin"
        ? (status
            ? classes.filter(cls => cls.active)
            : classes.filter(cls => !cls.active)
        )
        : (loggedUser?.user?.role === "teacher"
            ? (status
                ? classes.filter(cls => cls.active && Array.isArray(cls.teachersIDS) && cls.teachersIDS.length > 0 && cls.teachersIDS.some(teacherObj => teacherObj?.teacher?._id === loggedUser?.user?._id))
                : classes.filter(cls => !cls.active && Array.isArray(cls.teachersIDS) && cls.teachersIDS.length > 0 && cls.teachersIDS.some(teacherObj => teacherObj?.teacher?._id === loggedUser?.user?._id))
            )
            : [] 
        );




    console.log(filteredClasses)

    return (
        <>
            {!Class ?
                <div className='mt-5'>
                    <AddClass />
                    <MenuBar
                        status={status}
                        setStatus={setStatus}
                        name1={'الفصول الحالية'}
                        name2={'الفصول غير المفعلة'}
                    />
                    <Table responsive className='mt-5 font'>
                        <thead>
                            <tr>
                                <th>الفصل</th>
                                <th> الكورس</th>
                                <th> جدد </th>
                                <th>عدد الطلاب</th>
                                <th>عرض الطلاب</th>
                                <th>المعلمون</th>
                                <th>اضافة مشرف</th>
                                <th>اعدادات</th>
                                <th>الجدول</th>
                                <th>تعطيل الفصل</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredClasses.map((cls, index) => (
                                <SingleClass key={index}
                                    cls={cls}
                                    pack={getPackageByID(cls?.packID)}
                                    toast={toast}
                                    students={students}
                                />
                            ))}
                        </tbody>
                    </Table>
                    <br />
                    <br />
                    <br />
                </div>
                : null
            }
        </>
    );
}

export default Classes;
