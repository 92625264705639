import { useSelector , useDispatch } from "react-redux";
import { Get_Messages_Of_Contact, showMessageBox } from "../../../../actions/massenger";
//Get_Messages_Of_Contact
export default function ContactButton({contactID,contactRole,name,class_name,span}) {
    const dispatch=useDispatch();
    const{user}=useSelector(state=>state.loggedUser);
    const {toast}=useSelector(state=>state.notify)
    //console.log('user in contact button',user);
    async function handleGetMessagesOfContact(){
      if(!contactID){
        return toast().error('This contact does not exist')
      }
        await dispatch(Get_Messages_Of_Contact({
            ownerID:user?._id,
            contactID:contactID,
            // comming data note needed in api but
            // sent to used with sending api in case 
            //sending to user for first time.  
            contactRole,
            ownerRole:user?.role
        }));
        dispatch(showMessageBox())
    }
    console.log(contactID,contactRole,name,class_name,span)
  return (
    <button 
    onClick={()=>handleGetMessagesOfContact()}
    className={`btn special color font ${class_name ? class_name : 'font'}`}
      style={{color:"black"}}
    >
      {name} {span? `(${span})` : ''}
    </button>
  )
}
