import React, { useEffect } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaCirclePlus } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux"

import { Add_Branch } from '../../../actions/curriculums/branchs';
const AddBranch = () => {
    const [show, setShow] = useState(false);
    const [name , setName] = useState("");
    const [load , setLoad] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();


    const onSubmit = async () => {
        setLoad(false)
            await dispatch(Add_Branch({
                name,
            }))
        setLoad(true)
        handleClose()
    }

    return (

        <div  className='mainHome'>
            <button onClick={handleShow} className='addLevelIcon btn buttonsGroub'>
                <FaCirclePlus size='25' color='#0F2A51' className='me-2 mx-2'/>
                <span className='font'>اضافة فئة</span>
            </button>

            {/* Modal Has Form  */}
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <Modal.Title> اضافة فئة تعليمية </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <form>
                            <label>الاسم : </label>
                            <input className='form-control mt-2' type="text" id="branchname" value={name} onChange={(e)=>setName(e.target.value)}/>
                        </form>
                        <button   className='btn mt-2  special color'  onClick={onSubmit}>
                            حفظ
                        </button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddBranch
