import React from 'react'
import SubjectCard from './modules/SubjectCard'

const SubscripeMaterials = ({ programs }) => {
    return (
        <div className='' dir="rtl">
            <div className='row justify-content-between align-items-center'>
                {
                    programs && programs.length > 0 ? (
                        programs.map((program, index) => {
                            return (
                                <div className='col-lg-4 col-md-6 col-sm-12 col-6' key={program.date}>
                                    <SubjectCard program={program} />
                                </div>
                            )
                        })
                    ) : null
                }
            </div>
        </div>
    )
}

export default SubscripeMaterials
