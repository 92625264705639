


import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProgramCard from '../../components/shared/modules/ProgramCard';
import PageContainer from '../../components/layout/PagecContainer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "../../styles/details.css"
import { setSubscribe } from '../../utils/subscribe';
import { ErrorMsg, success } from '../../components/shared/modules/Notifications';
import { getTeachers, urlBase } from '../../utils/API';
import { HomePage } from '../../utils/Translation/HomePage';

const DetailsTeacherPrograms = () => {
    const packages = useSelector((state) => state.packages);
    const location = useLocation();
    const [teacher, setTeacher] = useState([]);
    // const teacher = location.state?.teacherData;

    const [about, setAbout] = useState(true);
    const [showPackages, setShowPackages] = useState(false);

    const handleAbout = () => {
        setAbout(!about);
    };

    const handleBookNow = () => {
        setShowPackages(!showPackages);
    };

    // Filter packages based on the teacherId
    const filteredPackages = packages?.filter((item) => item?.teacher?._id === teacher[0]?._id);

    const loggedUser = useSelector((state) => state.loggedUser);

    // Check if logged user is already a subscriber
    const initialIsSubscribed = teacher[0]?.subscribers?.some(subscriber => subscriber?.user === loggedUser?.user?._id);
    const [localIsSubscribed, setLocalIsSubscribed] = useState(initialIsSubscribed);

    const navigate = useNavigate();

    // For Subscribe
    const handleSubscribe = async () => {
        try {
            if (loggedUser?.auth === false) {
                ErrorMsg("يرجي تسجيل الدخول");
                navigate('/login')
                return;
            }
            const res = await setSubscribe({
                subscriber: loggedUser?.user?._id,
                target: teacher[0]?._id
            });

            if (res?.data?.case) {
                success(res?.data?.message);
                setLocalIsSubscribed(!localIsSubscribed); // Update state on successful subscription
            } else {
                ErrorMsg(res?.data?.message);
            }
        } catch (e) {
            console.error("Error during subscription:", e);
            ErrorMsg("Failed to subscribe.");
        }
    }

    const handleSubscriptionSuccess = () => {
        setLocalIsSubscribed(!localIsSubscribed)
    }


    // For Translation
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    // Use Params 
    const params = useParams();


    useEffect(() => {
        const getTeachersData = async () => {
            const res = await getTeachers("teacher");
            const data = res?.data?.users.filter((teacher) => teacher?._id === params.id)
            setTeacher(data)
        }
        getTeachersData();
    }, [params.id])


    console.log(teacher[0])

    return (
        <PageContainer>
            <div style={{ marginTop: "120px" }} dir={`${dir}`}>
                <div className='container' dir={`${dir}`}>
                    <div className='sharedBackground font'>
                        <p>{HomePage.teachers[lang]}</p>
                    </div>
                    <div className='row justify-content-center align-items-center' dir={`${dir}`}>
                        <div className='col-lg-3 col-md-6 col-sm-12 font text-center'>
                            <img src={teacher[0]?.logo ? `${urlBase}/${teacher[0]?.logo}` : ""} alt='ForImageTeacher' width={"150px"} height={"150px"} />
                            <div className='mt-3'></div>
                            <img src={teacher[0]?.photo ? `${urlBase}/${teacher[0]?.photo}` : ""} alt='ForImageTeacher' width={"150px"} height={"150px"} />
                            <div className='m-auto mt-2 w-50 mb-3' style={{ textAlign: "center" }}>
                                <button className='btn buttonsGroub w-75' onClick={handleSubscribe}>
                                    {localIsSubscribed ? `${HomePage.subComplete[lang]}` : `${HomePage.following[lang]}`}
                                </button>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-6 col-sm-12'>
                            <div className='font'>
                                <div className='d-flex justify-content-between align-items-center py-5'>
                                    <div> <h5>الاكاديميه:{teacher[0]?.brandName}</h5></div>
                                    <div><h5 dir='rtl'>المعلم:<span style={{ fontSize: "20px" }}>{teacher[0]?.username}</span></h5></div>
                                </div>
                                <div className='mt-5' style={{ textAlign: "right", cursor: "pointer" }}>
                                    <h5 onClick={handleAbout}>
                                        {about ? (
                                            <IoIosArrowDown style={{ marginRight: "10px" }} />
                                        ) : (
                                            <IoIosArrowUp style={{ marginRight: "10px" }} />
                                        )}
                                        {HomePage.aboutTeacher[lang]}
                                    </h5>
                                    {about ? (
                                        <p>{teacher[0]?.about}</p>
                                    ) : null}
                                </div>

                                <div className='m-auto mt-5 mb-5' style={{ textAlign: "center" }}>
                                    {
                                        showPackages && (
                                            <Link className='btn special color' to="/teachers" >{HomePage.prevPage[lang]}</Link>
                                        )
                                    }
                                    <button className='btn buttonsGroub' onClick={handleBookNow}>{HomePage.booking[lang]}</button>

                                </div>


                            </div>
                        </div>
                    </div>
                    {/* Programs belongs to Teachers */}
                    <div
                        className={`row programs justify-content-end  mb-5 align-items-start programsTeacher ${showPackages ? 'show' : ''}`}
                    >
                        {showPackages && (
                            filteredPackages.length > 0 ? (
                                filteredPackages.map((item) => {
                                    return (
                                        <div className='col-lg-4 col-sm-4 col-md-6' key={item._id}>
                                            <div>
                                                <ProgramCard item={item} isSubscribed={localIsSubscribed} targetTeacher={teacher[0]?._id} onLocalSubscribed={handleSubscriptionSuccess} />
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <p style={{ height: "50vh", textAlign: "center" }} className='font'>No packages found for this teacher.</p>
                            )
                        )}
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}

export default DetailsTeacherPrograms;
