import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import "../../styles/modal.css";
import { GetMaterialsByIds } from '../../utils/materials';
import { Link } from 'react-router-dom';
import CodeModal from './CodeModal';

function ChooseMaterialModal({ subject }) {
    const loggedUser = useSelector((state) => state.loggedUser);


    // console.log(subject)

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [materials, setMaterials] = useState([]);
    const handleClose = () => setShow(false);
    const [showCodeModal, setShowCodeModal] = useState(false);
    const [codeMaterial, setCodeMaterial] = useState();
    const [packID, setPackID] = useState();

    const handleShow = async () => {
        setShow(true);

        if (subject?.packData?.materials) {
            setLoading(true);
            const materialCodes = subject.packData.materials.map(material => material?.materialCode);
            const materialsData = await GetMaterialsByIds({ codes: materialCodes });
            if (materialsData?.data?.case) {
                setMaterials(materialsData?.data?.materials);
            }
            setLoading(false);
        }
    }

    const handleCodeModalOpen = (code, packID) => {
        setShow(false);
        setShowCodeModal(true);
        setCodeMaterial(code)
        setPackID(packID)
    };

    console.log(materials)

    return (
        <>
            <button className="card-btn text-decoration-none text-center" onClick={handleShow}>متابعة الدراسه</button>

            <Modal show={show} className='font modalBook' onHide={handleClose} centered dir="rtl">
                <Modal.Header className='headerTitle'>
                    <Modal.Title className='text-center'>اختر الماده</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {loading ? (
                        <div className="text-center">
                            <div className="loaderIcon">
                                <span className="loader"></span>
                            </div>
                        </div>
                    ) : (
                        materials && materials.map((material) => (
                            subject?.requestType !== "CODE" ? (
                                <div className='row justify-content-start align-items-center mb-2' key={material._id}>
                                    <div className='col-lg-6' style={{ textAlign: "right" }}>
                                        <Link to={`/dashboard/${loggedUser?.user?.name}/${loggedUser?.user?._id}/education-board/${subject?._id}/${material.code}`} className='puplicBlack text-decoration-none'>{material.name}</Link>
                                    </div>
                                </div>
                            ) : (
                                <div className='row justify-content-start align-items-center mb-2' key={material._id}>
                                    <div className='col-lg-6' style={{ textAlign: "right" }}>
                                        <p className='puplicBlack text-decoration-none' key={material._id} onClick={() => handleCodeModalOpen(material?.code, subject?.packID)} style={{ cursor: "pointer" }}>
                                            {material.name}
                                        </p>
                                    </div>
                                </div>
                            )
                        ))
                    )}
                </Modal.Body>
            </Modal>


            <CodeModal show={showCodeModal} setShow={setShowCodeModal} code={codeMaterial} packId={packID} />
        </>
    );
}

export default ChooseMaterialModal;
