import React from 'react'
import emptyImage from '../../../assets/subject_empty.png'
import { Link } from 'react-router-dom'
const EmptyComponent = ({ showFooter, title }) => {
    return (
        <div className='text-center' style={{ marginTop: "-80px" }}>
            <img src={emptyImage} alt="EmptyImage" className='w-25' />
            <p>{title}</p>
            {
                showFooter ? (
                    <div>
                        <Link to="/subjects" className='btn buttonsGroub subscripeNow buttonOpacity'>اشترك الان</Link>
                    </div>
                ) : null
            }
        </div>
    )
}

export default EmptyComponent
