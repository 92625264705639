import React from 'react'
import './style.css'

const Contain=({children,border,perfix,bgColor,bgImage,height,color}) =>{
     
 const styleSheet = {
    border:`${border?border:'0'} solid #333`,
    backgroundColor:bgColor,
    backgroundImage:bgImage,
    height:height,
    color:color
    // color:color?color:'#fff'
 }
  return (
    <div className={`stcontainer ${perfix}`}  style={styleSheet}>
        {children}
    </div>
  )
}

export default Contain