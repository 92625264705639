import React from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import support from "../../assets/support.png"
import SharedStatic from './modules/SharedStatic'
import { useSelector } from 'react-redux'
import { HomePage } from '../../utils/Translation/HomePage'
const TechnicalSupport = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us">
                    <SharedImg img={support} title=" OLP مرحبا بكم في" line="الدعم الفني" />
                    <div className='container'>
                        <p className='sharedColor'>
                            <div style={{ textAlign: "right" }}>
                                <h4> {HomePage.communicateSupport[lang]} </h4>
                                <p>
                                    {HomePage.sendReasons[lang]}
                                </p>
                                <p>{HomePage.address[lang]}</p>
                                <p>
                                    support@yourplatform.com
                                </p>
                                <p> mailto:support@yocom.u</p>
                            </div>
                            <div style={{ textAlign: "right", marginTop: "50px" }}>
                                <h4>{HomePage.ticketSystem[lang]}</h4>
                                <p>
                                    {HomePage.reach[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign: "right", marginTop: "50px" }}>
                                <h4>{HomePage.phone[lang]}</h4>
                                <p>
                                    توفر بعض المنصات خدمة دعم عبر الهاتف
                                    لطلبات المساعدة العاجلة.
                                </p>
                                <p>{HomePage.phone[lang]}</p>
                                <p>7890-456-123 <br /> 123-456-7890</p>
                            </div>
                            <div style={{ textAlign: "right", marginTop: "50px" }}>
                                <h4>{HomePage.steps[lang]}</h4>
                                <div>
                                    <h6>{HomePage.selectProplem[lang]}</h6>
                                    <p>{HomePage.selectAllProplems[lang]}</p>
                                </div>
                                <div>
                                    <h6>{HomePage.collectInformation[lang]}</h6>
                                    <p>
                                        {HomePage.infoCollectInfo[lang]}
                                    </p>
                                </div>
                            </div>

                            <div style={{ textAlign: "right", marginTop: "50px" }}>
                                <h4> : التواصل مع الدعم الفني</h4>
                                <p>
                                    استخدم إحدى طرق التواصل المتاحة للوصول إلى فريق
                                    الدعم الفني. تأكد من تقديم كافة التفاصيل والمعلومات التي
                                    جمعتها.
                                </p>
                            </div>
                            <div style={{ textAlign: "right", marginTop: "50px" }}>
                                <h4> : متابعة الحالة:</h4>
                                <p>
                                    إذا كنت قد قدمت تذكرة دعم أو طلبًا عبر البريد اإللكتروني،
                                    قم بمتابعة الحالة بانتظام للتأكد من حل المشكلة في الوقت
                                    المناسب.
                                </p>
                            </div>
                            <div style={{ textAlign: "right", marginTop: "50px" }}>
                                <h4> : تقديم التغذية الراجعة:</h4>
                                <p>
                                    بعد حل المشكلة، قد يتم طلب تغذية راجعة حول تجربة الدعم
                                    الفني. قدم مالحظاتك لتحسين جودة الخدمة.
                                </p>
                            </div>
                            <div style={{ textAlign: "right", marginTop: "50px" }}>
                                <div>
                                    <h4> : نصائح للحصول على دعم فعال</h4>
                                    <p>
                                        بعد حل المشكلة، قد يتم طلب تغذية راجعة حول تجربة الدعم
                                        الفني. قدم مالحظاتك لتحسين جودة الخدمة.
                                    </p>
                                </div>
                                <div className='mt-5'>
                                    <h4> : كن صبورا</h4>
                                    <p>
                                        قد يستغرق الرد على طلبات الدعم بعض الوقت،
                                        خاصة خالل ساعات الذروة.
                                    </p>
                                </div>
                                <div className='mt-5'>
                                    <h4> :كن محددًا </h4>
                                    <p>
                                        كلما كنت أكثر تحديدًا في وصف المشكلة، كان من
                                        األسهل على فريق الدعم مساعدتك.
                                    </p>
                                </div>
                                <div className='mt-5'>
                                    <h4> :استخدم الموارد المتاحة </h4>
                                    <p>
                                        تحقق من مركز المساعدة والمنتديات
                                        قبل االتصال بالدعم الفني، حيث قد تجد إجابات ألسئلتك هناك.
                                    </p>
                                </div>
                                <p className='text-center mt-5'>إذا كنت بحاجة إلى أي مساعدة إضافية أو لديك استفسارات
                                    أخرى، فال تتردد في طرحها. نحن هنا لدعمك!</p>
                            </div>
                        </p>
                    </div>


                    <SharedStatic hint="support" />

                </main>
            </PageContainer>
        </div>
    )
}

export default TechnicalSupport
