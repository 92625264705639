import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Add_Class_SuperVisor } from '../../../actions/class';
import SupervisorsList from './supervisorList';

function AddSupervisor({ Class, show, setShow }) {
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [currentMaterials, setCurrentMaterials] = useState([])
  const [materialCode, setMaterialCode] = useState(null);
  const [message, setMessage] = useState(null)
  const { supervisors, materials, toast } = useSelector(({ users, materials, packages, notify }) => {
    return {
      supervisors: users.supervisors,
      materials,
      toast: notify.toast
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      const getMaterials = () => {
        try {
          const program = Class.packID;
          const materialsObjs = program?.materials;
          // console.log('OBJ', Class)
          const mats = []
          // console.log('mats',materialsObjs,Class)  
          materialsObjs.forEach(m => {
            const mt = materials.find(mm => mm.code === m.materialCode);
            if (mt) mats.push(mt);
          })
          setCurrentMaterials(mats);
        }
        catch (err) {
          console.warn(err.message)
        }
      }
      getMaterials();
    }
  }, [Class, show])

  const handleConfirm = async () => {
    try {
      if (!selectedSupervisor, !materialCode) return toast().error('برجاء اختيار المشرف والمادة.', {
        position: "top-center"
      });
      if (selectedSupervisor, materialCode) {
        const res = await dispatch(Add_Class_SuperVisor({ id: Class._id, supervisorID: selectedSupervisor, materialCode }))
        setMessage(res.data)
        // setShow(false);
        // console.log(toast())
        if (res.data.case) {
          // console.log('success')
          // Success notification
          toast().success(res.data.message, {
            position: "top-right"
          });
          setSelectedSupervisor(null);
          setMaterialCode(null)
        } else {
          // Error notification
          // console.log('unsuccess')

          toast().error(res.data.message, {
            position: "top-right"
          });
        }
      }
    } catch (err) {
      console.warn(err.message);
      setMessage({ case: false, message: err.message })
      toast().error(err.message, {
        position: "top-center"
      })
    } finally {
      // Close the modal
      // setShow(false);
    }
  }

  return (
    <div>

      <Modal
        style={{
          width: '100vw',
          maxWidth: '100vw !important'
        }}

        className='supervisor-modal font'
        centered
        dir='rtl' show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>ادارة المشرفين</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control as="select" onChange={(e) => setSelectedSupervisor(e.target.value)}>
            <option value="">اختر مشرفًا</option>
            {supervisors.map((supervisor) => (
              <option key={supervisor._id} value={supervisor._id}>
                {supervisor.name}
              </option>
            ))}
          </Form.Control>
          <Form.Group controlId="selectMaterial">
            <Form.Label>اختر المادة </Form.Label>
            <Form.Control as="select" onChange={(event) => setMaterialCode(event.target.value)}>
              <option value="">اختر</option>
              {/* Render the list of teachers */}
              {/* Replace teachers with your actual list of teachers */}
              {currentMaterials.map(m => (
                <option key={m.code} value={m.code}>{m.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          {selectedSupervisor && materialCode ?
            <Button
              variant={selectedSupervisor && materialCode ? 'primary' : 'secondary'} disabled={selectedSupervisor && materialCode ? false : true} onClick={handleConfirm}>اضافة المشرف</Button>
            :
            <></>}
        </Modal.Body>
        <Modal.Body>
          <SupervisorsList Class={Class} />
        </Modal.Body>
        <Modal.Footer>
          {message ? <Alert variant={message && message.case ? 'success' : message?.message}>{message?.message}</Alert> : <></>}
          <button className='btn special color' onClick={() => setShow(false)}>إغلاق</button>

        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default AddSupervisor;
