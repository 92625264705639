import React from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import about from "../../assets/aboutUs.png"
import SharedStatic from './modules/SharedStatic'
const About = () => {
    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us">
                    <SharedImg img={about} title=" OLP مرحبا بكم في" line="من نحن" />
                    <div className='container'>
                        <p className='sharedColor mt-5'>
                            <p className='fs-5'>
                                Welcome to Osbash Agency, where innovation
                                meets expertise. We are a dynamic team
                                dedicated to pushing the boundaries of
                                programming and digital marketing. Our leader
                                and founder, Dr. Ali Afifi, is not just a senior MERN
                                stack developer; he's a visionary with a
                                remarkable journey in both technology and
                                academia.
                            </p>

                            <p className='mt-5'>
                                About Dr. Ali Afifi:
                                Dr. Ali Afifi embarked on his programming journey
                                in 2020, initially learning from Udacity's FWD
                                program. He began as a Front-End developer and
                                swiftly transitioned into a proficient MERN stack
                                developer. Dr. Afifi's work has extended far and
                                wide, collaborating with clients from across the
                                globe, including Saudi Arabia, Morocco, Kiewit,
                                India, USA, France, Syria, Egypt, Jordan, Lebanon,
                                and the United Arab Emirates.
                                Some of his notable clients include:
                                Alfuttiam Company (Emirates)
                                Makzany Company (The first self-storage company
                                in Saudi Arabia)
                                Almasri Group Company (Kiewit)
                                Harmony Company (Kiewit)
                                But Dr. Afifi's expertise isn't confined to the tech
                                world. He's also a physicist researcher, having
                                earned his degree from Al-Azhar University in
                                2014. After a 3-year tenure as a Reserve Officer in
                                the Egyptian Army, he furthered his career as a
                                physics teaching assistant for 2 years at the
                                Faculty of Engineering – Modern Academy. In
                                2023, he completed his Master's degree in solidstate physics and boasts international publications
                                in the field.
                                In 2023, Dr. Afifi founded Osbash Agency, where
                                he leads and oversees the Programming
                                department. Our Marketing department is under
                                the capable management of Mr. Al-Hassan
                                Ahmed, a seasoned marketer with 5 years of
                                experience across various trading sectors.
                            </p>

                            <h5 className='mt-5'>  Our Services:</h5>
                            <p className='fs-6'>

                                At Osbash Agency, we offer a comprehensive suite
                                of services to meet your business needs:
                                <p> Custom Management Systems (ERP, CRM)</p>
                                <p>Mobile Applications</p>
                                <p> Websites</p>
                                <p>Web Platforms</p>
                                <p>Marketing Campaigns (Google, Social Media)</p>
                                <p> UI/UX Design</p>
                                <p>Graphic Design</p>
                            </p>
                        </p>
                    </div>


                    <SharedStatic hint="about" />

                </main>
            </PageContainer>
        </div>
    )
}

export default About
