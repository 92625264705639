import React from 'react'
import { useState,useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { TiEdit } from "react-icons/ti";
import { useDispatch } from 'react-redux';
import { Update_Branch } from '../../../actions/curriculums/branchs';
const ModalEdit = ({ item, setMessage }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const name =  useRef(item.name);
    const dispatch = useDispatch();

    const onUpdate = async () => {
        try {
            const res = await dispatch(Update_Branch({ _id: item._id, name:name.current.value }));
            if (res.data.case) {    
                setMessage(res.data.message);
            }
            else {
                setMessage(res.data.message);

            }
            handleClose()
        }
        catch (err) {
            console.log(err);
            setMessage("Supmit function err", err.message);
            handleClose();
        }
    }
    
    return (
        <div>

            <TiEdit
                onClick={handleShow}
                size='25px' color='#0F2A51' />

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>تعديل الفئة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <label className='mb-2'>تعديل اسم الفئة</label>
                        <input
                            type="text"
                            id="nameLevel"
                            className='form-control'
                            ref={name}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        اغلاق
                    </Button>
                    <Button variant="primary" onClick={onUpdate}>
                        حفظ
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalEdit
