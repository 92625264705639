import { getTeachers, setSchedual,update_User,delTeacher } from "../utils/API"
import  {authedUser} from './login&register';
export const INETIALIZE_USERS = "INETIALIZE_USERS"
export const SET_SCHEDUAL = "SET_SCHEDUAL"

const inetialize = (users,role)=>{
    return{
        type:INETIALIZE_USERS,
        users,
        role
    }
}
const Schedual = (schedual,id)=>{
    return {
        type:SET_SCHEDUAL,
        schedual,
        id
    }
}

// inetialize any user exept admin
export const Inetialize_Users = (role)=>{ //users is an array
    return (dispatch)=>{
        return getTeachers(role).then(res=>{
            if(res.data.case){
                dispatch(inetialize(res.data.users,role))
            }
            return res
        })
    }
}
export const Set_Schedual = (schedual,id)=>{
   return (dispatch)=>{
    return setSchedual({schedual:schedual,email:id}).then((res)=>{
        if (res.status===200){
            dispatch(Schedual(schedual,id))
            dispatch(authedUser({user:res.data,auth:true}))
            alert('Saved')
        }
        else{alert('error')}
    })
   }
}
export const UPDATE_TEACHER = "UPDATE_TEACHER";

const updateTeacher = (id,teacher)=>{
return {
    type:UPDATE_TEACHER,
    id,
    teacher
}
}
export const Update_Teacher = (teacher)=>{
    return (dispatch)=>{
        return update_User(teacher).then((res)=>{
            if (res.status===201){
                dispatch(updateTeacher(teacher._id,res.data.user))
                alert('Saved')
            }
            else{alert('error')}
        })
    }
   
}
export const DELETE_TEACHER = "DELETE_TEACHER";
const deleteTeacher=(id,role)=>{
return{
    type:DELETE_TEACHER,
    id,
    role
}
}
export const Delete_Teacher=(id)=>{
  return (dispatch)=>{
    return delTeacher(id).then(res=>{
        dispatch(deleteTeacher(id))
        alert(res.data)
    })
  }

}