import React, {useState} from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Take_Action_On_Order } from '../../../actions/store';
import { useDispatch, useSelector } from 'react-redux';
function ChangeOrderState({id,oldState}) {
    const [show,setShow] = useState(false);
    const [newOrderState,setNewOrderState] = useState(null);
    const {toast} = useSelector(state => state.notify)
    const dispatch = useDispatch();

    const handleOrder =async ()=>{
        try{
            if(!newOrderState){
                return toast().error('برجاء اختيار حالة الطلب قبل التاكيد.')
            }
           const res =await dispatch(Take_Action_On_Order({id,response:newOrderState}));
           if(res?.data?.case){
             toast().success(res?.data?.message);
             }
             else{
                 toast().error(res?.data?.message)
                 }
                setShow(false)
                
        }
        catch(err){
          console.warn(err);
          toast().error(err.message)
        }
    }
  return (
    <>
    <Button onClick={()=>setShow(true)}>
        حالة الطلب
    </Button>
    <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header>
            :تغيير حالة الطلب 

        </Modal.Header>
        <Modal.Body>
        <div className="form-group">
                        <label>{oldState}</label>
                        <label htmlFor="orderState">اختر حالة الطلب</label>
                        
                        <select
                            id="orderState"
                            className="form-control"
                            value={newOrderState}
                            onChange={(e) => setNewOrderState(e.target.value)}
                        >
                            <option value="">اختر...</option>
                            <option value="PROCCESSING">قيد المعالجة</option>
                            <option value="DELIVERED">تم التسليم</option>
                            <option value="REJECTED">مرفوض</option>
                            <option value="ON THE ROAD">فى الطريق</option>
                        </select>
                    </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            لا
          </Button>
          <Button variant="danger" onClick={()=>handleOrder()}>
            تأكيد
          </Button>
        </Modal.Footer>

    </Modal>
    </>
  )
}

export default ChangeOrderState