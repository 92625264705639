import React from 'react';
import { Card, ListGroup, ListGroupItem, Form, Alert } from 'react-bootstrap';

function MultibleChoicesAnswer({ question, index, handleSelectAnswer }) {
    console.log('question', question);
    return (
        <>
            <Card className="mb-5 w-100 m-auto" style={{ background: "transparent", border: "none", boxShadow: "none" }}>
                <Card.Body>
                    {/* <Card.Title>{`Question ${index + 1}: ${question.question}`}</Card.Title> */}

                    {question?.choices && question.choices.length > 0 ? (
                        <ListGroup style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            {question.choices.map((choice, choiceIndex) => (
                                <div key={choiceIndex} style={{ background: "#120C54", color: "white", padding: "10px", borderRadius: "5px", height: "40px", margin: "5px", width: "100px" }}>
                                    <div>
                                        
                                        <Form.Check
                                            type="radio"
                                            name={`question-${index}`}
                                            id={`question-${index}-choice-${choiceIndex}`}
                                            checked={choice.value === question.chosenAnswer}
                                            label={choice.value}
                                            onChange={() => handleSelectAnswer(index, choice.value)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </ListGroup>
                    ) : (
                        null
                    )}
                </Card.Body>
            </Card>
        </>
    );
}

export default MultibleChoicesAnswer;