import React from 'react'
import { Container,Row } from 'react-bootstrap'
import AddAwareness from './addAwareness'

function Parents() {
  return (
<Container fluid>
<Row>
    <AddAwareness/>
</Row>
</Container>
)
}

export default Parents