import { getValidRequests,getNotValidRequests, changeRequestState,getAllRequests } from '../utils/packagesRequests';
// 
export const GET_BUY_REQUESTS = "GET_BUY_REQUESTS";

const buyRequests = (data)=>{
    return{
        type:GET_BUY_REQUESTS,
        data
    }
}

export const Get_Buy_Requests = ()=>{
    return (dispatch)=>{
        return getAllRequests().then(res=>{
            if(res.data.case){
                dispatch(buyRequests(res.data.requests));
            }
            return res
        })
    }
}
// GET BUY REQUESTS

export const GET_ACTIVE_BUY_REQUESTS = "GET_BUY_REQUESTS";

const activeBuyRequests = (data)=>{
    return{
        type:GET_ACTIVE_BUY_REQUESTS,
        data
    }
}

export const Get_Active_Buy_Requests = ()=>{
    return (dispatch)=>{
        return getAllRequests().then(res=>{
            if(res.data.case){
                dispatch(activeBuyRequests(res.data.requests));
            }
            return res
        })
    }
}
export const GET_NONE_ACTIVE_BUY_REQUESTS = "GET_BUY_REQUESTS";

const noneActiveBuyRequests = (data)=>{
    return{
        type:GET_NONE_ACTIVE_BUY_REQUESTS,
        data
    }
}

export const Get_None_Active_Buy_Requests = ()=>{
    return (dispatch)=>{
        return getNotValidRequests().then(res=>{
            if(res.data.case){
                dispatch(noneActiveBuyRequests(res.data.requests));
            }
            return res
        })
    }
}

// HANDEL BUY REQUEST
export const HANDEL_REQUEST = 'HANDEL_REQUEST';

const HandelRequest = (req)=>{
  return {
    type:HANDEL_REQUEST,
    req
  }
}

export const Handel_Request = (req)=>{
   return (dispatch)=>{
     return changeRequestState(req).then(res=>{
        if(res.data.case){
           dispatch(HandelRequest(res.data.request));
        }
        return res;
     })
   }
}