import axios from 'axios';
import { urlBase } from './API';

export const forgetPass = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/send-reset-password-email`,
        data: data
    })
}
export const resetPass = (data) => {
    console.log(data)
    return axios({
        method: "POST",
        url: `${urlBase}/api/reset/password/account`,
        data: data
    })
}