import React from 'react'
import { Card, ListGroup, ListGroupItem,Form } from 'react-bootstrap'

function MultibleChoicesAnswer({question,index,handleSelectAnswer}) {

    //console.log('question',question);
  return (
    <>
    <Card  className="mb-3">
                            <Card.Body>
                                {/* <Card.Title>{`Question ${index + 1}: ${question.question}`}</Card.Title> */}

                                <ListGroup>
                                    {question.choices.map((choice, choiceIndex) => (
                                        <ListGroupItem key={choiceIndex}>
                                            <Form.Check
                                                type="radio"
                                                name={`question-${index}`}
                                                id={`question-${index}-choice-${choiceIndex}`}
                                                label={choice.value}
                                                checked={choice.value==question.chosenAnswer}
                                                // checked={answers[index] === choice.value}
                                                onChange={() => handleSelectAnswer(index, choice.value)}
                                            />
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                        </Card>
    </>
  )
}

export default MultibleChoicesAnswer