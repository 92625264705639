import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLessonsByIDS } from "../../../utils/lessons";
import "./courseBoard.css";
import { FaArrowLeft } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import LessonsTaps from "./lessonsTaps";
import { Set_Lesson } from "../../../actions/curriculums/view";
import ViewArea from "./viewArea";
import ViewTest from "./tests/viewTest";
import PageContainer from "../../layout/PagecContainer";
import { getRequestPerUser } from "../../../utils/packagesRequests";
import { increaseTries } from "../../../utils/codes";

function CourseBoard() {

  const params = useParams();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [showTest, setShowTest] = useState(false);
  const [program, setProgram] = useState([]);
  const [isCompressed, setIsCompressed] = useState(false);
  const handleToggleMinimize = () => {
    setIsCompressed((prev) => !prev);
  };
  const { course, lesson, user } = useSelector(({ view, loggedUser }) => {
    return { course: view?.course, lesson: view?.lesson, user: loggedUser };
  });


  const fetchPrograms = async () => {
    const res = await getRequestPerUser({
      userID: params?.id
    })
    const programUnique = res?.data?.requests.filter((program) => {
      return (
        program._id === params.requestId
      )
    })

    setProgram(programUnique);
  }






  useEffect(() => {
    fetchPrograms();
  }, [params?.id])



  // FETCH LESSONS
  const fetchLessons = async () => {
    try {
      setLoading(true);
      if (params?.code !== "codeingProgram") {
        const material = program[0]?.packData?.materials.filter((material) => {
          return (
            material.materialCode === params.materialId
          )
        })
        // const IDS = course?.packData.selectedLessons;
        const res = await getLessonsByIDS({ lessonsIDS: material[0]?.selectedLessons });
        setMessage({ case: res.data.case, message: res.data.message });
        setLessons(res.data.lessons);
      } else {
        const res = await getLessonsByIDS({ lessonsIDS: params?.lesson });
        setMessage({ case: res.data.case, message: res.data.message });
        setLessons(res.data.lessons);

        const increase = await increaseTries({
          sheetId: params?.sheetId,
          lesson: { _id: params?.lessonId }
        })
        console.log(increase);
      }

      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setMessage(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLessons();
  }, [program]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!lesson && lessons) {
      dispatch(Set_Lesson(lessons[0]));
    }
    return () => {
      dispatch(Set_Lesson(null));
    };
  }, [lessons]);

  return (
    <PageContainer>
      <div style={{ marginTop: "80px" }} className="font text-center px-5">
        {/* {!isCompressed && <NavigationBar />} */}
        <div className='sharedBackground mt-5'>
          <p>برامجي الدراسيه</p>
        </div>
        <div dir="rtl" className="board-cont">
          <div
            style={{
              // flex: isCompressed ? "0 0 auto" : "1",
              width: isCompressed ? "61px" : "210px",
              overflow: isCompressed ? "hidden" : "",
              transition: "width 1s ease-in-out",
              padding: "5px",
            }}
            className={`board-taps`}
          >
            <br />
            <br />

            <div
              className="d-flex justify-content-between"

            >
              {/* {isCompressed&& <div onClick={handleToggleMinimize}>
              <img src={arrow} alt="" />
            </div>} */}

              <div className="lessontitle  w-50"
                style={{
                  width: isCompressed ? "0px" : "284px",
                  display: isCompressed ? "none" : "",
                  color: "black"
                }}
              >
                <p className="font fs-6">{!isCompressed && lesson ? lesson.name : "جغرافيا"}</p>
              </div>

              <div onClick={handleToggleMinimize} className="arrow">
                <FaArrowLeft />
              </div>
            </div>

            {!isCompressed && <LessonsTaps setShowTest={setShowTest} lessons={lessons} />}


            {/* Render your lesson tabs here */}
          </div>

          {/* Column for Lesson Content */}
          <div className="d-flex flex-column"
            style={{
              width: isCompressed ? '100%' : '100%',
              transition: "width 1s ease-in-out , heigth 1s ease-in-out",
              // transition:"height 1s ease-in-out"
            }}

          >
            <div className="contentTitle font mb-5">الصف الاول الثانوي</div>

            <div
              className={`board-content ${isCompressed ? "board-content-compress" : ""
                }`}
            >
              {!showTest ? (
                <ViewArea Lesson={lesson ? lesson : null} />
              ) : (
                <ViewTest userID={user.user._id} />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default CourseBoard;
