// import React, { useEffect, useState } from 'react';
// // import { Document, Page } from 'react-pdf';
// // import ReactPlayer from 'react-player';
// import { useSelector } from 'react-redux';
// import { getContentUrls } from '../../../utils/lessons';
// // import { Container, Button } from 'react-bootstrap';
// import "./courseBoard.css";
// import "./view.css";
// import vid from "../../../assets/vid.mp4"


// const ViewArea = ({ Lesson }) => {
//   // const [pdfNumPages, setPdfNumPages] = useState(null);
//   // const [pdfPageNumber, setPdfPageNumber] = useState(1);
//   const [content, setContent] = useState([]);

//   const { lesson } = useSelector(({ view }) => {
//     return { lesson: view?.lesson };
//   });

//   const getURLS = async () => {
//     try {

//       const res = await getContentUrls(!Lesson ? lesson.content : Lesson.content);
//       setContent(res.data.newContent);
//       console.log(res);
//     } catch (err) {
//       console.log(err.message);
//     }
//   };

//   useEffect(() => {
//     getURLS();

//   }, [lesson]);

//   // const onDocumentLoadSuccess = ({ numPages }) => {
//   //   setPdfNumPages(numPages);
//   // };

//   // const preventRightClick = (e) => {
//   //   e.preventDefault();
//   // };

//   const preventContextMenu = (e) => {
//     e.preventDefault();
//   };

//   return (
//     <div className='view-area-cont' onContextMenu={preventContextMenu}>
//       {
//         content.length ? (
//           <>
//             {content.map((c, i) => {
//               if (c.type.split('/').includes('image')) {
//                 return (
//                   <div key={i} className='w-100' onContextMenu={preventContextMenu}>
//                     <div className='d-flex w-50 justify-content-between align-items-center'>
//                       <div className='col-lg-8 col-sm-6 col-md-6'>
//                         <h2>{lesson ? lesson?.name : "الحروف"}</h2>
//                       </div>

//                       <div className='w-100 col-lg-4 col-sm-6 col-md-6' onContextMenu={preventContextMenu}>
//                         {/* <hr /> */}
//                         <img
//                           // c?.url ? c?.url: watch
//                           src={`https://bardiaat.com/alex_logo.jpg`}
//                           alt="lesson cover"
//                           style={{ width: '10%', height: 'auto' }}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 );
//               } else if (c.type.split('/').includes('video')) {
//                 return (
//                   <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>
//                     <hr />
//                     <div style={{ margin: '20px auto', width: "90%" }} onContextMenu={preventContextMenu}>
//                       <hr />
//                       {/* <ReactPlayer url={c.url} controls width="100%" height="auto" /> */}
//                       <video controls width="100%" height="auto">
//                         <source src={vid} type={`video`} />
//                         Your browser does not support the video tag.
//                       </video>
//                     </div>
//                   </div>
//                 );
//               } else if (c.type.split('/').includes('pdf')) {
//                 return (
//                   <div key={i} style={{ marginBottom: '20px', width: '100%' }} onContextMenu={preventContextMenu}>
//                     {/* <Document file={c.url} onLoadSuccess={onDocumentLoadSuccess}>
//                     <Page pageNumber={pdfPageNumber} />
//                   </Document>
//                   <p>
//                     Page {pdfPageNumber} of {pdfNumPages}
//                   </p>
//                   <Button
//                     onClick={() => setPdfPageNumber((prevPage) => Math.max(prevPage - 1, 1))}
//                   >
//                     Previous Page
//                   </Button>
//                   <Button
//                     onClick={() => setPdfPageNumber((prevPage) => Math.min(prevPage + 1, pdfNumPages))}
//                   >
//                     Next Page
//                   </Button> */}
//                     {/* <iframe style={{backgroundColor:'#fff'}} src={`${c.url}`} width="100%" height="1200px" /> */}
//                     <div className="pdf-container">
//                       <object className="pdf-object" data={c.url} type="application/pdf">
//                         <embed src={c.url} type="application/pdf" width={'200vw'} />
//                       </object>
//                     </div>
//                     <div>
//                     </div>
//                   </div>
//                 );
//               }
//             })}
//           </>
//         ) : (
//           <>
//             <h1>لا يوجد محتوى متاح لهذا الدرس.</h1>
//           </>
//         )
//       }
//     </div >
//   );
// };

// export default ViewArea;


import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getContentUrls } from '../../../utils/lessons';
import "./courseBoard.css";
import "./view.css";
import { urlBase } from '../../../utils/API';
import ReactPlayer from "react-player"

const ViewArea = ({ Lesson }) => {

  const [content, setContent] = useState([]);

  const { lesson } = useSelector(({ view }) => {
    return { lesson: view?.lesson };
  });

  const getURLS = async () => {
    if (lesson) {
      try {
        const res = await getContentUrls(!Lesson ? lesson.content : Lesson.content);
        setContent(res?.data?.newContent);
        console.log(res);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  useEffect(() => {
    getURLS();
  }, [lesson]);

  console.log(content)

  const preventContextMenu = (e) => {
    e.preventDefault();
  };


  console.log(lesson)

  return (
    <div className='view-area-cont' onContextMenu={preventContextMenu}>
      {
        content.length ? (
          <>
            {lesson?.content.map((c, i) => (
              c.type.split('/').includes('image') ? (
                <div key={i} className='w-100' onContextMenu={preventContextMenu}>
                  <div className='d-flex w-50 justify-content-between align-items-center'>
                    <div className='col-lg-8 col-sm-6 col-md-6'>
                      <h2>{lesson ? lesson?.name : ""}</h2>
                    </div>
                    <div className='w-100 col-lg-4 col-sm-6 col-md-6' onContextMenu={preventContextMenu}>
                      <img
                        src={`${urlBase}/${c.name}`}
                        alt="lesson cover"
                        type={c.type}
                        style={{ width: '150px', height: '150px' }}
                      />
                    </div>
                  </div>
                </div>
              ) : c.type.split('/').includes('video') ? (
                <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>

                  <hr />
                  <div style={{ margin: '20px auto', width: "90%" }} onContextMenu={preventContextMenu}>
                    <hr />
                    <video controls width="100%" height="auto">
                      <source src={`${urlBase}/${c?.url}`} type={`video`} />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              ) : c.type.split('/').includes('pdf') ? (
                <div key={i} style={{ marginBottom: '20px', marginTop: "50px", width: '100%' }} onContextMenu={preventContextMenu}>
                  <div key={i} style={{ marginBottom: '20px', marginTop: "80px" }} onContextMenu={preventContextMenu}>
                    <div>

                      <iframe src={`${urlBase}/${c.name}`} width="100%" height="500px" />
                    </div>
                    {/* <PdfViewer fileURL={`${urlBase}/${c.name}`}/> */}
                  </div>
                </div>
              ) : c.type === "link" ? (
                <div className='w-100 m-auto'>
                  <ReactPlayer
                    url={c?.name}
                    controls={true}
                    width="100%"
                    height="500px"
                    config={{
                      youtube: {
                        playerVars: {
                          modestbranding: 1,
                          rel: 0,
                          showinfo: 0,
                          iv_load_policy: 3,
                        }
                      },
                      dailymotion: {
                        params: {
                          controls: true,
                          autoplay: false,
                          mute: false,
                          'queue-enable': false,
                          'sharing-enable': false,
                          'ui-start-screen-info': false, // Hide video title and uploader info
                          'ui-logo': false, // Hide Dailymotion logo
                        }
                      }
                    }}
                  />
                  
                </div>
              ) : null
            ))}
          </>
        ) : (
          <h1>لا يوجد محتوى متاح لهذا الدرس.</h1>
        )
      }
    </div>
  );
};

export default ViewArea;
