import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { urlBase } from '../../../utils/API';
import { createLesson } from '../../../utils/lessons';
// import { BoxLoading } from 'react-loadingg';
import axios from 'axios';
// import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { useSelector } from 'react-redux';
// import S3FileUpload from 'react-s3';
// import { uploadFile } from 'react-s3';

function AddLesson({ materialCode }) {


  // ------------
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lessonName, setLessonName] = useState('');

  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoLink, setVideoLink] = useState(null);
  const [pdf, setPdf] = useState(null);

  const [imageUrl, setImageUrl] = useState();
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0); // State to handle upload progress
  // const {user} = useSelector(state => state.loggedUser);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLessonNameChange = (e) => {
    setLessonName(e.target.value);
  };
  //-------

  //-------
  // const s3 = new S3Client({
  //   region: 'me-central-1',
  //   credentials: {
  //     accessKeyId: 'AKIA5LSNJPKWPMFJUFTN',
  //     secretAccessKey: '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'
  //   }
  // });

  //
  const { user } = useSelector(({ loggedUser }) => {
    return { user: loggedUser.user }
  })
  const handleFileChange = (type) => {
    switch (type) {
      case 'image':
        setMessage('Uploading image...');
        break;
      case 'video':
        setMessage('Uploading video...');
        break;
      case 'pdf':
        setMessage('Uploading PDF...');
        break;
      default:
        setMessage('Uploading...');
    }

    // You may want to handle the file upload process here
    // For now, let's assume the file upload happens instantly
  };

  //-------------
  //                                 LOCAL UPLOAD ENDPOINT
  const uploadingFileLocally = (data) => {
    const formData = new FormData();
    formData.append("file", data);
    formData.append("user", user?._id)
    return axios({
      method: "POST",
      url: `${urlBase}/api/upload-file-locally?userId=${user?._id}`,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentage)

      }
    })
  }

  //-------------



  const handleSaveChanges = async () => {
    try {
      setLoading(true);

      let content = [];

      if (image) {
        // await AWSUpload(image)
        console.log("image")
        handleFileChange(image.type)
        const res = await uploadingFileLocally(image)

        // await uploadReact_S3(image);
        content.push({ ...res?.data?.data, type: image?.type });

      }
      if (video) {
        console.log("video")
        handleFileChange(video.type)
        const res = await uploadingFileLocally(video)

        content.push({ ...res?.data?.data, type: video?.type });

      }
      if (videoLink) {  
        console.log("link")
        // handleFileChange(video.type)
        const res = await uploadingFileLocally(videoLink)

        console.log(res)

        content.push({ ...res?.data?.data, type: "link", name: videoLink, source: "external" });

      }
      if (pdf) {
        console.log("pdf")
        handleFileChange(pdf.type)
        const res = await uploadingFileLocally(pdf)

        content.push({ ...res?.data?.data, type: pdf?.type });

      }

      const lessonData = {
        name: lessonName,
        materialCode,
        content,
      };

      const response = await createLesson(lessonData);
      console.log(response)
      setMessage(response.data.message);
      setLoading(false);

      handleCloseModal();
      alert(response.data.message);
    } catch (error) {
      console.error('Error:', error);
      setMessage(error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <button className='btn btn buttonsGroub' onClick={handleOpenModal}>
        <span className='font'> إضافة درس</span>
      </button>

      <Modal show={showModal} onHide={handleCloseModal} className='font'>
        <Modal.Header closeButton>
          <Modal.Title>اضافة درس جديد</Modal.Title>
          <div>{message}</div>
        </Modal.Header>
        <Modal.Body>
          <div dir={"ltr"}>{progress}% uploaded / {message}</div>
          {!loading ? (
            <>
              <Form.Group controlId="lessonName">
                <Form.Label>اسم الدرس</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ادخل اسم الدرس"
                  value={lessonName}
                  onChange={handleLessonNameChange}
                  multiple={false}
                />
              </Form.Group>

              <Form.Group controlId="lessonFile">
                <Form.Label>رفع صورة</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => setImage(event.target.files[0])}
                />
              </Form.Group>
              {user?.role === "Admin" ?

                <Form.Group controlId="lessonFile">
                  <Form.Label>رفع الفيديو</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(event) => setVideo(event.target.files[0])}
                  />
                </Form.Group>
                : <></>}
              <Form.Group controlId="lessonFile">
                <Form.Label>اضف رابط الفيديو</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) => setVideoLink(event.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="lessonFile">
                <Form.Label>رفع pdf</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => setPdf(event.target.files[0])}
                />
              </Form.Group>

              <Button variant="primary" onClick={handleSaveChanges}>
                اضافة الدرس
              </Button>
            </>
          ) : (
            <div className="loaderIcon">
              <span className="loader"></span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddLesson;
