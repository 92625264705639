import React, { Fragment, useState } from 'react';
import Message from './Message';
import Progress from './Progress';
import axios from 'axios';
// import Context from '../context/context';
// import Back from '../back';
import {urlBase} from "../../utils/API"
const FileUpload = ({id,stateProperty,user,type}) => {
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('Choose File');
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  
  // console.log(user)
  //on Change of input values
  const onChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };
    
  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append("type", type);
    formData.append("email", user.email);
    formData.append("origin",window.location.origin)
    try {
      // console.log(window.location.origin)
      await axios.post(`${urlBase}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      }).then((res)=>{

        // console.log(res)
        setUploadedFile( res.data.link)
      }
        )
      // Clear percentage
      // alert('woking')
      // const { fileName, filePath ,user} = res.data;
      
      
      // setUploadedFile({ fileName, filePath });

      setMessage('File Uploaded');
      // setUser(user);
      setTimeout(() => setUploadPercentage(0), 3000);
    } catch (err) {
      if (err.response.status === 500) {
        setMessage('There was a problem with the server');
      } else {
        setMessage(err.response.data.msg);
      }
      setUploadPercentage(0)
    }
  };

  return (
    <Fragment>
      {message ? <Message msg={message} /> : null}
      <form onSubmit={onSubmit}>
      
        <div className='custom-file mb-5  '>
          <input
            type='file'
            className='custom-file-input'
            id='customFile'
            onChange={onChange}
          />
          <label className='custom-file-label' htmlFor='customFile'>
            {filename}
          </label>
        </div>

        <Progress percentage={uploadPercentage} />

        <input
          type='submit'
          value='Upload'
          className='btn btn-primary btn-block mt-4'
        />
      </form>
   
    </Fragment>
  );
};

export default FileUpload;
