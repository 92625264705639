import { registerStudentParent as RegisterStP, getAuthedUser, intializeUser, logOut, register, getAccounts, sendMessage, verifyPassword, updateUserInfo } from "../utils/API";
import { getClassByStudentByProgram } from "../utils/class";
import { getRequestPerUser } from "../utils/packagesRequests";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const REGISTER_ADMIN = "REGISTER_ADMIN";
export const REGISTER_USERS = "REGISTER_USERS";
export const REGISTER_STUDENT = "REGISTER_STUDENT";
export const SIGNUP_USER = "SIGNU_USER";
export const UPDATE_USER_IMG = "UPDATE_USER_IMG";
export const PROGRAMS_PER_USER = "PROGRAMS_PER_USER";
export const GET_TEACHER_STUDENTS = "GET_TEACHER_STUDENTS";
export const STORE_CLASSES = "STORE_CLASSES";
export const TEACHER_SELECTED_TEST = "TEACHER_SELECTED_TEST";

export const VERIFY_PASSWORD = 'VERIFY_PASSWORD';

// register student parent account

const registerStudentParent = (student, parent) => {
  return {
    type: SIGNUP_USER,
    student,
    parent
  }
}

export const Register_Student_Parent = (data) => {
  return dispatch => RegisterStP(data).then(res => {
    if (res.data.case) {
      dispatch(registerStudentParent(res.data.student, res.data.parent))
    }
    return res
  })
}


// ----- user is an object of data
const admin = (user) => {
  return {
    type: REGISTER_ADMIN,
    user,
  };
};
const teacher = (user) => {
  return {
    type: REGISTER_USERS,
    user,
  };
};
const student = (user) => {
  return {
    type: REGISTER_STUDENT,
    user,
  };
};

export const Register_Admin = (user) => {
  return (dispatch) => {
    return register(user).then((res) => {
      if (res.data === "created") {
        dispatch(admin(user));
        alert(`account created with email: ${user.username}`);
      }
      else if (res.data === "exist") {
        alert(`account with email: ${user.email} already exist`);
      }
      else { console.log(res.data); }
    });
  };
};
export const Register_Teacher = (user) => {
  return (dispatch) => {
    return register(user).then((res) => {
      if (res.data.case) {
        dispatch(teacher(res.data.user));
        alert(`account created with email: ${user.username}`);
      }
      else if (res.data.message === "exist") {
        alert(`account with email: ${user.email} already exist`);
      }
      else { console.log(res.data); }
      return res
    });
  };
};
export const Register_Student = (user) => {
  return (dispatch) => {
    return register(user).then((res) => {
      if (res.data === "created") {
        dispatch(student(user));
        alert(`account created with email: ${user.email}`);
      }
      else if (res.data === "exist") {
        alert(`account with email: ${user.email} already exist`);
      }
      else { console.log(res.data); }
      return res;
    });
  };
};
export const authedUser = (user) => {
  return {
    type: AUTH_LOGIN,
    user,
  };
};

export const logIn = (authentcation) => {
  return (dispatch) => {
    return getAuthedUser(authentcation).then((data) => {
      if (data.auth) {
        dispatch(authedUser(data));
      }
      else {
        // alert('password or username is not correct or username does not exist')
      }
      return data

    });
  };
};
//  inetialize useres

export const intializeLogIn = () => {
  return (dispatch) => {
    return intializeUser().then((user) => {
      dispatch(authedUser(user));
    });
  };
};
export const Log_Out = () => {
  return (dispatch) => {
    return logOut().then((res) => {
      console.log(res);
    });
  };
}
//get ADmS
export const INETIALIZE_ADS = 'INETIALIZE_ADS'
const inetializeAds = (ads) => {
  return {
    type: INETIALIZE_ADS,
    ads
  }
}
export const Inetialize_Ads = () => {
  return (dispatch) => {
    return getAccounts().then((res) => dispatch(inetializeAds(res.data))
    )
  }
}
// messages
export const SEND_MESSAGE = 'SEND_MESSAGE';
const newMessage = (data) => {
  return {
    type: SEND_MESSAGE,
    data
  }
}
export const Send_Message = (data) => {
  return (dispatch) => {
    return sendMessage(data).then(res => {
      if (res.status === 200) {
        dispatch(newMessage(data));
        alert('Your Message Sent Succesfully');
      }
      else {
        alert(res.data)
      }
    })
  }
}

export const update_user_info = (user) => {
  return {
    type: UPDATE_USER_IMG,
    payload: user
  }
}
const programPerUser = (programs) => {
  return {
    type: PROGRAMS_PER_USER,
    payload: programs
  }
}

// Valid Progrmas  (APi => All Progrmas)
export const programs_per_user = (id) => async (dispatch) => {
  try {
    const res = await getRequestPerUser(id);
    const validPrograms = res.data.requests.filter(p => p.valid);
    let vProgs = [];

    if (validPrograms.length > 0) {
      for (let program of validPrograms) {
        const response = await getClassByStudentByProgram({ studentId: program.userID, packID: program.packID });
        program.Class = response.data.Class;
        vProgs.push(program);
      }
      dispatch(programPerUser(vProgs)); // Dispatch action with the updated programs
    }

    return { res, validPrograms }; // Return response and valid programs
  } catch (err) {
    console.error('Error in programsPerUser:', err);
    // Handle errors appropriately (e.g., display an error message, dispatch an error action)
    throw err; // Rethrow the error to propagate it further if needed
  }
};
export const get_teacher_students = (students) => {
  return {
    type: GET_TEACHER_STUDENTS,
    payload: students
  }
}

export const store_classes = (classes) => {
  return {
    type: STORE_CLASSES,
    payload: classes
  }
}

export const set_teacher_selected_test = (test) => {
  return {
    type: TEACHER_SELECTED_TEST,
    payload: test
  }
}


// Verify Password
export const Verify_Pass = (data) => {
  return (dispatch) => {
      return verifyPassword(data).then((res) => {
          return res;
      }).catch((error) => {
          console.error(`Error fetching : `, error);
      });
  };
};
// Update User Info
export const Update_User_Info = (data) => {
  return (dispatch) => {
      return updateUserInfo(data).then((res) => {
          return res;
      }).catch((error) => {
          console.error(`Error fetching : `, error);
      });
  };
};