import React, { useState,useRef } from "react";
import Col from '../styled-components/col'
function SubCategory({ p, onChange, options }) {
 //----chose days of subCategory-------------
  const [max, setMax] = useState(1);
  const [text, setText] = useState(null);
  const [choosed, setChoosed] = useState([]);
  const daysRef =useRef();
  
  //------function of choose
  
  const onChooseDays = (ev) => {
    let newChoosed=[]
    if (choosed.length < max) {
      newChoosed=[...choosed, ev]      
    } else {
      // console.log(max)
      const Choosed = choosed.filter((e, i) => i > 0);
      newChoosed= [...Choosed,ev]
    }
    setChoosed(newChoosed);
    onChange({ name: "days", value: [...newChoosed] }, p);
    const Tt = options.filter((o, i) => newChoosed.some(el=>el==i));
    setText(Tt);
  };
  React.useEffect(()=>{
    setText(null)
  },[max])
  //-------------------------------------------
  return (
    <>
      <h2 className='sub-category-title'>SubCategory {p} </h2>
      <label  htmlFor="scpr">Price 'SR':</label>
      <input
        className="tex"
        id='scpr'
        type="number"
        name="price"
        placeholder="price"
        onChange={(event) =>{
          onChange(event.target, p)

      }
       }
        required
      />
      <label  htmlFor="scn">Name:</label>

      <input
        className="tex"
        id='scn'
        type="text"
        name="name"
        onChange={(event) => onChange(event.target, p)}
        placeholder={`subcategory${p}`}
        required
      />
      <label  htmlFor="tnos">Total number of sessions per course:</label>

      <input
        className="tex"
        id='tnos'
        type="number"
        name="period"
        min="1"
        onChange={(event) => {
          onChange(event.target, p);
        }}
        placeholder={'Total Number Of Sessions....'}
        required
      />
      <label  htmlFor="spw">Sessions Per Week:</label>

      <input
        className="tex"
        id='spw'
        type="number"
        name="session"
        min="0"
        onChange={(event) => {
          onChange(event.target, p);
          setMax(event.target.value);
        }}
        placeholder={`Sessions Per Week`}
        required
      />
  
       <div className='drop-dw-title'>
        <span >{text}</span>
        </div>
        <Col>
        <label > Choose Days => .</label>
      <select className="drop-dw-select" ref={daysRef}  onChange={(event) => onChooseDays(daysRef.current.value)}>
        {options.map((o, i) => (
          <Option className="drop-dw-option" key={i} index={i} value={o} />
        ))}
      </select>

      </Col>
    </>
  );
}
function Option({ index, value }) {
  return (
    <>
      <option className="drop-down-option" value={index} id={index}>
        {value}
      </option>
    </>
  );
}
export default SubCategory;
