import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../ThemeProvider';
import imgCard from '../../../assets/Image.png'
import BookingModal from '../../modals/BookingModal';
import { Link } from 'react-router-dom';
import { urlBase } from '../../../utils/API';
import SubscribeToTeacher from '../../modals/SubscribeToTeacher';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../utils/Translation/HomePage';
const ProgramCard = ({ item, isSubscribed, targetTeacher }) => {
    const { theme } = useContext(ThemeContext);
    const loggedUser = useSelector((state) => state.loggedUser)

    const [isSub, setIsSub] = useState(isSubscribed)

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    useEffect(() => {
        setIsSub(isSubscribed);
    }, [isSubscribed]);

    const initialIsSubscribed = item?.teacher?.subscribers?.some(
        (subscriber) => subscriber?.user === loggedUser?.user?._id
    );


    // console.log(item)


    return (
        <div>
            <div className={`card ${theme}`} style={{ minHeight: "50vh" }}>
                <div className="text-center m-auto  w-100" >
                    {
                        item?.coverImage ? (
                            <img src={item?.coverImage ? `${urlBase}/${item?.coverImage}` : ""} alt="" style={{ width: "100%" }} className='' />
                        ) : (
                            <img src={imgCard} alt="imageData" />
                        )
                    }
                </div>
                <div className="card-content font">
                    <p style={{ color: `${theme === "light" ? "black" : "white"}` }}>{item.name}</p>
                    <hr />
                    <div className="card-details">
                        {
                            item.price === 0 ? (
                                <div>
                                    <span>السعر : مجانااا</span>
                                </div>
                            ) : (
                                item.price > item.discountPrice ? (
                                    <div>
                                        <span style={{  textDecoration:
                                                        "line-through",
                                                    color: "red", }}>السعر : {item.price}</span>
                                        <span>السعر المخفض : {item.discountPrice}</span>
                                    </div>
                                ) : (
                                    <div>
                                        <span>السعر : {item.price}</span>
                                    </div>
                                )
                            )
                        }
                    </div>
                    <div className="card-buttons mt-5">
                        <Link to={`/details/${item.name}/${item._id}`} className="details-button text-center text-decoration-none">{HomePage.details[lang]}</Link>

                        {
                            initialIsSubscribed ? (<BookingModal item={item} title={"احجز الان"} />) : (<SubscribeToTeacher targetTeacher={targetTeacher} item={item} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramCard
