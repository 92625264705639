// import React, { useEffect } from 'react'
// import EmptyComponent from './EmptyComponent'
// import "../../../styles/dashboard.css"
// import SubscripeMaterials from './SubscripeMaterials'
// import { useDispatch, useSelector } from 'react-redux'
// import { programs_per_user } from '../../../actions/login&register'
// const Subject = () => {
//     const dispatch = useDispatch();
//     const loggedUser = useSelector((state) => state.loggedUser);


//     const fetchPrograms = async () => {
//         await dispatch(programs_per_user({
//             userID: loggedUser?.user?._id
//         }));
//     }

//     useEffect(() => {
//         fetchPrograms();
//     }, [])
//     return (
//         <div className='font'>
//             <div className='sharedBackground'>
//                 <p>برامجي الدراسيه</p>
//             </div>


//             {
//                 loggedUser?.programs && loggedUser?.programs?.length > 0 ? (
//                     <div className='container'>
//                         <SubscripeMaterials programs={loggedUser?.programs} />
//                     </div>
//                 ) : (
//                     <div className='emptyComponent'>
//                         <EmptyComponent showFooter={true}/>
//                     </div>
//                 )

//             }


//         </div>
//     )
// }

// export default Subject
import React, { useEffect, useState } from 'react';
import EmptyComponent from './EmptyComponent';
import "../../../styles/dashboard.css";
import SubscripeMaterials from './SubscripeMaterials';
import { useDispatch, useSelector } from 'react-redux';
import { programs_per_user } from '../../../actions/login&register';
import Spinner from 'react-bootstrap/Spinner';

const Subject = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);

    const [loading, setLoading] = useState(true); // Add loading state

    const fetchPrograms = async () => {
        setLoading(true); // Set loading to true before fetching
        if(loggedUser?.user?.role==="student"){
            await dispatch(programs_per_user({
                userID: loggedUser?.user?._id
            }));
        }else if (loggedUser?.user?.role === "parent"){
            await dispatch(programs_per_user({
                userID: loggedUser?.user?.childId
            }));
        }
        setLoading(false); // Set loading to false after fetching
    };

    useEffect(() => {
        fetchPrograms();
    }, []);


    return (
        <div className='font'>
            <div className='sharedBackground'>
                <p>برامجي الدراسيه</p>
            </div>

            {loading ? (
                <div className="spinner-container d-flex justify-content-center align-items-center">
                    <span className='loader'></span>
                </div>
            ) : (
                loggedUser?.programs && loggedUser?.programs?.length > 0 ? (
                    <div className='container'>
                        <SubscripeMaterials programs={loggedUser?.programs} />
                    </div>
                ) : (
                    <div className='emptyComponent'>
                        <EmptyComponent showFooter={true} title={"انت غير مشترك في برامج دراسيه بعد"}/>
                    </div>
                )
            )}
        </div>
    );
};

export default Subject;
