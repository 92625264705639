import React from 'react'
import PageContainer from '../layout/PagecContainer'
import { Link } from 'react-router-dom'
import thanks from '../../assets/thanksImage.png'

const ThanksPage = () => {
    return (
        <PageContainer>
            <div className='text-center shadow' style={{ width: "90%", margin: "160px auto", borderRadius: "5px", background: "White" }}>
                <div>
                    <p className='font' style={{ background: "#2B6CCD", padding: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", color: "white", textAlign: "right" }}>اشتراك البرنامج</p>
                    <div>
                        <img src={thanks} alt="ThanksImage" className="w-25"/>
                        <p className='font'>تمت العمليه بنجاح</p>
                    </div>
                </div>
                <div className='mb-5 font' style={{ textAlign: "left" }}>
                    <Link className='btn mb-3 mx-5 font' to="/subjects" style={{ background: "#2B6CCD ", color: "white", border: "none", outline: "none", borderRadius: "5px" }}>العودة الي صفحة الصفوف</Link>
                </div>
            </div>
        </PageContainer>
    )
}

export default ThanksPage
