import React, { useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import SendGroupMessage from './sendGroupMessage';
import { useSelector } from 'react-redux';

function TeachersList({ cls }) {
  const [showList, setShowList] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleList = () => {
    setShowList(!showList);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };
const {materials} = useSelector(state=>state.materials);

const getMaterial = (code)=>{
    const mat = materials?.filter(m=>m.code===code);

    if(mat & mat?.length){return mat}
    else{ return {name:'هذة المادة لم تعد متاحة'}}
}
  return (
    <div>

      <Button onClick={toggleModal}>المعلمون</Button>

      <Modal show={showModal} onHide={toggleModal} size="lg" className='font' centered>
        <Modal.Header closeButton>
          <Modal.Title>معلمى الفصل</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: 'auto' }}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>الاسم</th>
                <th>الهاتف</th>
                <th>البريد الالكترونى</th>
                <th>المادة</th>
                <th>التواصل</th>
              </tr>
            </thead>
            <tbody>
              {cls.teachersIDS && cls.teachersIDS.length > 0 ? (
                cls.teachersIDS.map((teacherObj, index) => (
                  <tr key={index}>
                    <td>{teacherObj?.teacher?.name}</td>
                    <td>{teacherObj?.teacher?.phone}</td>
                    <td>{teacherObj?.teacher?.email}</td>
                    <td>{getMaterial(teacherObj.materialCode)?.name | getMaterial(teacherObj?.materialCode)?.code }</td>
                    <td><SendGroupMessage acceptorsIDS={[teacherObj?.teache?._id]}/></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">لا يوجد معلمين</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn special color' onClick={toggleModal}>
            اغلاق
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TeachersList;
