import React from 'react'
import { Set_Lesson } from '../../../actions/curriculums/view';
import { Get_Test } from '../../../actions/curriculums/test';

import { useDispatch } from 'react-redux';
function LessonsTaps({ lessons, setShowTest }) {
  const dispatch = useDispatch();



  const getTest = async (id) => {
    try {

      const res = await dispatch(Get_Test([id]));
      setShowTest(true)
    }
    catch (err) {
      console.log(err.message);

    }
  }
  return (
    <div>
      <ol className='lessons-list'>
        {lessons.map((l, i) =>
          <>
            <li
              onClick={() => {
                dispatch(Set_Lesson(l))
                setShowTest(false)
              }}
              className='lesson-tap font my-4' key={i}>
              <span className='font fs-6'>
                {
                  i + 1 + ' . ' + l.name
                }
              </span>
            </li>
            {l.testIDS.length ? l.testIDS.map((t, i) =>
              <li key={t}
                className='lesson-tap my-4'
                onClick={() => getTest(t)}>
                  <span className='font fs-6'>Exam {i + 1}</span>
                </li>
            ) : null}
          </>
        )}
      </ol>
    </div>
  )
}

export default LessonsTaps