import { urlBase } from "./API";
import axios from 'axios';

// CREATE REQUEST
export const createRequest = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/create-request`,
        data:data,//{userID,packID,receipt}
        withCredentials:true
    })
} 
//UPDATE REQUEST
export const updateRequest = (data)=>{
    return axios ({
        method:"POST",
        url:`${urlBase}/api/update-request`,
        data:data,
        withCredentials:true
    })
}

// CHANGE REQUEST STATE 
export const changeRequestState = (data)=>{
return axios({
    method:'POST',
    url:`${urlBase}/api/change-request-state`,
    data:data,
    withCredentials:true
})
}
//GET REQUESTS PER USER
export const getRequestPerUser =(userID)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-requests/per-user`,
        data:userID,//{userID}
        withCredentials:true
    })
}
// get all valid requests 
export const getValidRequests =()=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/get-all-valid-requests`,
        withCredentials:true
    })
}
//get all not valid requests
export const getNotValidRequests =()=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/get-all-not-valid-requests`,
        withCredentials:true
    })
}
//get all requests 
export const getAllRequests = ()=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/get-all-requests`
    })
}

// get students in program and doesn't exist in any class
export const getStudentsInProgramNotInClass = (data)=> {
    return axios ({
        method:"POST",
        url:`${urlBase}/api/get-students-in-program/not-in-any-class`,
        data:data// {programId}
    })
}
// get Teacher class package  of a user subscription
// data {packID,userID}
export const getPackageOfStudentOnTeacher = (data)=>{
    try{
        return axios({
            method:'POST',
            url:`${urlBase}/api/get-package-per-user-per-teacher`,
            data:data
        })
    }
    catch(err){
        console.warn(err)
    }
}