import { GET_ALL_USER_STORED_MESSAGES, STORE_ALL_USER_MESSAGES } from "../actions/userMessages.actions";


export const userMessages=(state=[],action)=>{
    switch(action.type){
        case STORE_ALL_USER_MESSAGES:
            return state=action.payload
        
        case GET_ALL_USER_STORED_MESSAGES:
            return state
        default:
            return state;
    }
}