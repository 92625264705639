
// import { useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import { getRequestPerUser } from '../../utils/packagesRequests';
// import SubjectCard from '../Dashboard/Material/modules/SubjectCard';

// function ModalGetPackage({ studentId ,teacher}) {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     const [programs, setPrograms] = useState([]);
//     const fetchData = async () => {
//         try {
//             const res = await getRequestPerUser({
//                 userID: studentId
//             })
//             console.log(res)
//             setPrograms(res?.data?.requests)
//         } catch (e) {

//         }
//     }
//     useEffect(() => {
//         if (show) {
//             fetchData();
//         }
//     }, [show])



//     return (
//         <>
//             <button className='btn special color' onClick={handleShow}>
//                 التفاصيل
//             </button>

//             <Modal show={show} onHide={handleClose} className='font' centered>
//                 <Modal.Header closeButton>
//                     <Modal.Title>البرامج</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {
//                         programs ? (
//                             programs.map((program) => {
//                                 return (
//                                     <div style={{ backgroundColor: "white", textAlign: "center", padding: "10px", borderRadius: "10px", marginBottom: "15px" }} className="shadow">
//                                         <h6>{program?.packData?.name}</h6>
//                                         <p>تاريخ التسجيل: {program?.date.split('T')[0]}</p>
//                                         <p>اسم البرنامج: {program?.packData?.name}</p>
//                                         <p>سعر البرنامج: {program?.packData?.price}</p>
//                                     </div>

//                                 )
//                             })
//                         ) : null
//                     }
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                         Close
//                     </Button>
//                     <Button variant="primary" onClick={handleClose}>
//                         Save Changes
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// }

// export default ModalGetPackage;



import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getRequestPerUser } from '../../utils/packagesRequests';

function ModalGetPackage({ studentId, teacher }) {
    const [show, setShow] = useState(false);
    const [programs, setPrograms] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchData = async () => {
        try {
            const res = await getRequestPerUser({
                userID: studentId
            });
            console.log(res);

            // Set the programs with filtering based on the teacher
            const filteredPrograms = res?.data?.requests.filter(program => program?.packData?.teacher?._id === teacher);
            setPrograms(filteredPrograms);
        } catch (e) {
            console.error('Error fetching data:', e);
        }
    };

    useEffect(() => {
        if (show) {
            fetchData();
        }
    }, [show]);

    return (
        <>
            <button className='btn special color' onClick={handleShow}>
                التفاصيل
            </button>

            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header closeButton>
                    <Modal.Title>البرامج</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        programs && programs.length > 0 ? (
                            programs.map((program, index) => (
                                <div key={index} style={{ backgroundColor: "white", textAlign: "center", padding: "10px", borderRadius: "10px", marginBottom: "15px" }} className="shadow">
                                    <h6>{program?.packData?.name}</h6>
                                    <p>تاريخ التسجيل: {program?.date.split('T')[0]}</p>
                                    <p>اسم البرنامج: {program?.packData?.name}</p>
                                    <p>سعر البرنامج: {program?.packData?.price}</p>
                                </div>
                            ))
                        ) : (
                            <p>لا توجد برامج متاحة.</p>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalGetPackage;
