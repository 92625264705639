import { newCategory,getCategories,deleteCategory } from "../utils/store/category";
import { newProduct,deleteProduct,getAllProducts } from "../utils/store/products";
import  {newOrder,getAllOrders, actionOnOrder} from '../utils/store/orders'
import { removeStudentOnSupervisor } from "../utils/class";

// -------------categories -----------
export const NEW_CATEGORY = 'NEW_CATEGORY';
 const createCategory = (cat)=>{
    return {
        type:NEW_CATEGORY,
        cat
    }
}
export const New_Category = (data)=>{
   return dispatch => newCategory(data).then(res=>{
    if(res.data.case){
        dispatch(createCategory(res.data.newCat))
    }
    return res
   })
}
// get categories
export const GET_CATEGORIES = 'GET_CATEGORIES';
const inetializeCategories = (cats)=>{
    return {
        type:GET_CATEGORIES,
        cats
    }
}
export const Inetialize_Categories = ()=>{
    return dispatch => getCategories().then(res=>{
        if(res.data.case){
            dispatch(inetializeCategories(res.data.categories))
        }
        return res
    })
}
//-----------products-------------
export const NEW_PRODUCT_TO_STORE = 'NEW_PRODUCT_TO_STORE';
const createProduct = (product)=>{
    return {
        type:NEW_PRODUCT_TO_STORE,
        product
    }
}
export const Create_Product = (data)=>{
    return dispatch=>newProduct(data).then(
        res=>{
            if(res.data.case){
                dispatch(createProduct(res.data.newProduct))
            }
            return res
        }
    )
}

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
const removeProduct = (id)=>{
    return {
        type:DELETE_PRODUCT,
        id
    }
}
export const Remove_product = (id)=>{
   return dispatch=>deleteProduct(id).then(res=>{
    if(res.data.case){
        dispatch(removeProduct(id))
    }
    return res
   })
}

export const GET_STORE_PRODUCTS = 'GET_STORE_PRODUCTS';
const getProducts = (products)=>{
    return {
        type:GET_STORE_PRODUCTS,
        products
    }
}
export const Get_Store_Products = ()=>{
    return dispatch => getAllProducts().then(res=>{
        if(res.data.case){
            dispatch(getProducts(res.data.products))
        }
        return res
    })
}

// -----------ORDERS--------
export const  NEW_ORDER_FROM_STORE = 'NEW_ORDER_FROM_STORE';
const newRequest = (order)=>{
   return {
    type:NEW_ORDER_FROM_STORE,
    order
   }
}
export const Create_Order_From_Store = (order)=>{
    return dispatch => newOrder(order).then(res=>{
        if(res.data.case){
            dispatch(newRequest(res.data.order))
        }
    })

}

// get all orders 
export const GET_ALL_STORE_ORDERS = 'GET_ALL_STORE_ORDERS'
 const getOrders = (orders)=>{
    return {
        type:GET_ALL_STORE_ORDERS,
        orders
    }
}
export const Inetialize_Orders = ()=>{
    return dispatch => getAllOrders().then(res =>{
        if(res.data.case){
            dispatch(getOrders(res.data.orders))
        }
        return res
    })
}
// Action on Request 
export const ACTION_ON_REQUEST = 'ACTION_ON_REQUEST';

const actionOnReq = (request)=>{
    return {
        type:ACTION_ON_REQUEST,
        request
    }
}

export const Take_Action_On_Order = (data)=>{
    return dispatch => actionOnOrder(data).then(res=>{
        if(res.data.case){
            dispatch(actionOnReq(res.data.request))
        }
        return res
    })
}