import React, { useRef, useState } from "react";
import axios from "axios";
import "./disscount.css";
function NewDisscountCode({ urlBase, afterSubmitFunc }) {
  const code = useRef(null);
  const ammount = useRef(null);
  const type = useRef(0);
  const [message, setMessage] = useState();

  // submit form

  const submitData = (data) => {
    const url = `${urlBase}/api/create/code`;
    return axios({
      method: "POST",
      data: data,
      withCredentials: true,
      url: url,
    });
  };

  const onSubmit = (e) => {
    try {
      e.preventDefault();
      
      // console.log(typeof(type.current.value))
      const data = {
        code: code.current.value,
        ammount: ammount.current.value,
        type: type.current.value === 0 ? "amount" : "ratio",
      };
      // console.log(data)
      // if(type!=='0'||type!=='1')return setMessage('type only take 0 or 1 value')
      submitData(data).then((res) => {
        // console.log(res)
        if (res.status === 200) {
          setMessage(res.data.state);
          afterSubmitFunc(res.data);
        } else {
          setMessage(res.data);
        }

        setTimeout(() => {
          setMessage(" ");
        }, 5000);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="disscount-form-cont">
      <form className="disscount-form-in" onSubmit={onSubmit}>
        <span id="diss1">Add Disscount Code</span>
        <span id="diss2" className="mess add-disscount-message">
          {message}
        </span>
        
        <label id="diss8">If ratio 1, if ammount 0</label>
        <input
          id="diss3"
          className=""
          type="number"
          min='0'
          max='1'
          placeholder="Type of code"
          required
          ref={type}
        />
<label id='dissc1'>Insert code</label>
        <input
          id="diss4"
          className="c"
          ref={code}
          name="code"
          type="text"
          placeholder="insert code"
          required
        />

        <label id='dissam1'>Disscount ammount in RS currency or percentage ratio%</label>
        <input
          id="diss5"
          className="am"
          ref={ammount}
          name="ammount"
          type="number"
          placeholder="disscount ammount in RS currency or percentage ratio"
          required
        />

        <input id="diss6" className="s" type="submit" value="create" />
      </form>
    </div>
  );
}

export default NewDisscountCode;
