import React, { useContext } from 'react';
import { ThemeContext } from '../../../ThemeProvider';
import { urlBase } from '../../../utils/API';
import Image from "../../../styles/subjects.css"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../utils/Translation/HomePage';

const TeacherCard = ({ teacher }) => {
    const { theme } = useContext(ThemeContext);
    const aboutText = teacher?.about || "";
    const displayText = aboutText.length > 50 ? `${aboutText.slice(0, 50)}...` : aboutText;

    const navigate = useNavigate();

    const handleRouteNavigateTeacher = () => {
        navigate(`/teachers/${teacher?._id}/${teacher?.username.split(" ").join("")}`, { state: { teacherData: teacher } })
    }
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div className='teacher text-center' >
                <div className={`card ${theme} font`} style={{ minHeight: "60vh" }}>
                    <div className='header-card mb-5'>
                        <p>★★★★★</p>
                    </div>
                    <div className='img-card'>
                        <img
                            src={teacher?.personalImage ? `${urlBase}/${teacher?.personalImage}` : ""}
                            alt="Teacher"
                            className="teacherImage"
                            style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%"
                            }}
                        />
                    </div>
                    <div className="card-content font text-center">
                        <h6 className="text-center"> {teacher?.name} - {teacher?.username}</h6>
                        <p style={{ color: `${theme === "light" ? "#001C45" : "white"}` }}>
                            {displayText}
                        </p>
                    </div>
                    <div className="font">
                        <button onClick={handleRouteNavigateTeacher} className="book-button  buttonOpacity w-75">{HomePage.details[lang]}</button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TeacherCard;
