import {
  REGISTER_USERS,
  REGISTER_STUDENT,
  REGISTER_ADMIN,
  INETIALIZE_ADS,
  SIGNUP_USER
} from "../actions/login&register";
import {INETIALIZE_STUDENTS,DELETE_STUDENT} from "../actions/students"
import { INETIALIZE_USERS, SET_SCHEDUAL,UPDATE_TEACHER,DELETE_TEACHER } from "../actions/teachers";
export const users = (state = { teachers: [], students:[],parents:[],supervisors:[] }, action) => {
  const role = action?.user?.role;
  switch (action.type) {
    case REGISTER_USERS:
      if(role === 'teacher'){
        return state = { ...state, teachers: [...state.teachers, action.user] };

      }
      else if(role==='student'){
        return state = { ...state, students: [...state.students, action.user] };

      }
      else if(role === 'supervisor'){

        return state = { ...state, supervisors: [...state.supervisors, action.user] };
      }

    case DELETE_TEACHER:
      if(role === 'teacher'){
        return state={...state,teachers:state.teachers.filter(st=>st._id!==action.id)}

      }
      else if(role==='student'){
        return state={...state,students:state.students.filter(st=>st._id!==action.id)}

      }
      else if(role === 'supervisor'){
      return state={...state,supervisors:state.supervisors.filter(st=>st._id!==action.id)}        
      }
      return state={...state,teachers:state.teachers.filter(st=>st._id!==action.id)}
      case REGISTER_STUDENT:
      return state = { ...state, students: [...state.students, action.user] };
    case DELETE_STUDENT:
      return state={...state,students:state.students.filter(st=>st._id!==action.id)}
      case INETIALIZE_STUDENTS:
      return state = {...state, students: action.students };
      case INETIALIZE_USERS:
        // const role = action.role
        console.log('roleeeeeeeeeeeeeeee',role)
        if(action.role === 'teacher'){
          return state={...state,teachers:action.users}
          
        }
        else if(action.role==='student'){
          return state={...state,students:action.users}
  
        }
        else if(action.role === 'supervisor'){
          return state={...state,supervisors:action.users}
                 }
    case SET_SCHEDUAL:
        const newTeachers = state.teachers.map(t=>{if(t.email===action.id){return t.schedual=action.schedual}})
      return state = {...state, teachers:newTeachers}
    case UPDATE_TEACHER:
      return state = {...state, teachers: state.teachers.map((t)=>{
        if(t._id===action.id)return action.teacher;
        else return t })}
    case SIGNUP_USER:
        return state = {...state,parents:[...state.parents,action.parents],students:[...state.students,action?.student]}
     default: return state
    }
};
export const admins = (state = [], action) => {
  switch (action.type) {
    case REGISTER_ADMIN:
      return (state = [...state, action.user])
    case INETIALIZE_ADS:
      return state=action.ads
    default: return state
  }
};
