import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { getMaterialByCode } from '../../../utils/materials'
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { CiSquareRemove } from 'react-icons/ci';

function AddMaterialLessons({material,onChangeMaterialLessons,onRemoveMaterial}) {

    const [currentMaterial,setCurrentMaterial] = useState(null);
    const [allLessons, setAllLessons] = useState([]);
    const [lessonsForSelection,setLessonsForSelection] = useState([]);
    const [selectedLesson,setSelectedLessons] = useState([]);
    const [selectedLessonObjects , setSelectedLessonsObjects] = useState([]);

    const findMaterialByCode = async()=>{
        try{
     const res = await getMaterialByCode({code:material.materialCode});
     setCurrentMaterial(res.data.material);

        }
        catch(err){console.warn(err.message)}
    }
   
    const getLessonsOfCurrentMaterial = async()=>{
        try{
            const res = await getLessonsOfSpecificMaterial({ code: material.materialCode });
            const { lessons } = res.data;
            setAllLessons(lessons);
            setLessonsForSelection(lessons)
        }
        catch(err){
            console.log(err.message)
        }
    }

    const onChangeLessons = (lessonId)=>{
        try{
        setSelectedLessons([...selectedLesson,lessonId]);
        const selectedLessonObject = allLessons.find(l=>l._id ===lessonId);
        setSelectedLessonsObjects([...selectedLessonObjects,selectedLessonObject]);
        onChangeMaterialLessons([...selectedLesson,lessonId],currentMaterial.code)
        // remove lesson from lessonsForSelection
        const newLessonsForSelection = lessonsForSelection.filter(l=> l._id!== lessonId);
        console.log('nnnnnnnnn',newLessonsForSelection);
        setLessonsForSelection(newLessonsForSelection);
        }
        catch(err){console.log(err.message)}
    }

    const removeLesson = (lessonId)=>{
        try{
    const newSelectedLessons = selectedLesson.filter((id)=> id!==lessonId);
    setSelectedLessons(newSelectedLessons);
    onChangeMaterialLessons(newSelectedLessons,currentMaterial.code)
    
    const newSelectedLessonsObjects = selectedLessonObjects.filter(l=>l._id !== lessonId);
    setSelectedLessonsObjects(newSelectedLessonsObjects)

    // add lesson into lessonsForSelection
    const removedLessonObject = allLessons.find(l=> l._id === lessonId)
     setLessonsForSelection([...lessonsForSelection,removedLessonObject])
        }
        catch(err){
            console.log(err.message)
        }
    }
    useEffect(()=>{
        findMaterialByCode();
        getLessonsOfCurrentMaterial(); 
    },[])
  return (
<>
<Col >
    <Form.Group controlId="lessonsSelect">
    <Form.Label>دروس {currentMaterial?.name} - Code:  {material?.materialCode}
    <CiSquareRemove
             style={{ cursor: 'pointer', marginLeft: '5px' }}
             size={25}
             onClick={() => onRemoveMaterial(material?.materialCode)}
           />
    </Form.Label>
    <Form.Control
      as="select"
      onChange={(e) => onChangeLessons(e.target.value)}
    >
      <option value={null}>Select lessons</option>
      {lessonsForSelection.map((lesson) => (
        <option key={lesson._id} value={lesson._id}>
          {lesson.name}
        </option>
      ))}
    </Form.Control>
  </Form.Group>
  </Col>
   <Row>
   <Col style={{ margin: '20px 0 20px',padding:'20px' }}>
     <div>
       <strong>الدروس المختارة:</strong>
       {selectedLessonObjects.map((selectedLesson) => (
         <div key={selectedLesson._id} style={{padding:'20px'}}>
           <span>
{selectedLesson.name}
           </span>
           <CiSquareRemove
             style={{ cursor: 'pointer', marginLeft: '10px' }}
             size={25}
             onClick={() => removeLesson(selectedLesson._id)}
           />
         </div>
       ))}
     </div>
   </Col>
 </Row>
 </>
    )
}

export default AddMaterialLessons