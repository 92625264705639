import { Modal } from "react-bootstrap";
import ResultsTableComponent from "../../StudentPages/StudentTabPages/StdResults/ResultsTableComponent";
import { useState } from "react";
import ShowSelectedTestResultsComponent from "../../StudentPages/StudentTabPages/StdResults/ShowSelectedTestResultsComponent";

export default function ShowLessonsResultsModal({
  showModal,
  setShowModal,
  resultsTable,
  selectedStudent
}) {
  const [selectedTest, setSelectedTest] = useState(null);
  const [showResultsComponent, setShowResultsComponent] = useState(false);

  return (

    <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="chat-modal">
      <Modal.Body className="w-100" style={{ direction: "rtl" }}>
        {
          showResultsComponent ?
            <ShowSelectedTestResultsComponent selectedTest={selectedTest} />
            :
            <ResultsTableComponent
              resultsTable={resultsTable}
              selectedStudent={selectedStudent}
              setSelectedTest={setSelectedTest}
              setShowResultsComponent={setShowResultsComponent}
            />
        }

      </Modal.Body>
    </Modal>

  );
}
