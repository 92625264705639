import {TRANSLATION_STATE,SET_TEXT_PER_LANG,HANDEL_TEXT_CHANGE,CURRENT_LANGUAGE,INETIALIZE_TRANSLATION} from "../actions/translation";
export const translation = (state={
    state:true ,
    lang:{},
    original:'en',
    current:'ar'
},action)=>{
    switch(action.type){
        case TRANSLATION_STATE:
            return state = {state:!state.state,...state}
        case SET_TEXT_PER_LANG:
            let langT = state.lang//object
            // let keys = Object.keys(langT);

            let Newtext = action.text;//Array
            Newtext.forEach(t=>{
                if(!langT[t.id]){langT[t.id]={en:t.en,ar:t.ar,type:t.type}}
            })
            return state={...state,lang:langT}
        case HANDEL_TEXT_CHANGE:
            return state={...state,lang:{...state.lang,[action.id]:{...state.lang[action.id],ar:action.text}}}
        case INETIALIZE_TRANSLATION:
            return state ={...state,lang:{...state.lang,...action.lang}}
        case CURRENT_LANGUAGE:
            return state={...state,current:action.current}
        default: return state
    }
}