// import React, { useState } from 'react'
// import Modal from 'react-bootstrap/Modal';
// // import "../../styles/modal.css";
// import "../../styles/modal.css";
// import { setSubscribe } from '../../utils/subscribe';
// import { useSelector } from 'react-redux';
// import { ErrorMsg, success } from '../shared/modules/Notifications';
// import AnotherBookingModal from './AnotherBookingModal';
// const SubscribeToTeacher = ({ targetTeacher, item }) => {
//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     const [showBooking, setShowBooking] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const handleCloseBooking = () => setShowBooking(false);
//     const handleShowBooking = () => setShowBooking(true);

//     console.log(item)

//     const loggedUser = useSelector((state) => state.loggedUser);

//     console.log(loggedUser)

//     const handleSubscribe = async () => {
//         try {
//             if (loggedUser?.auth === false) {

//                 ErrorMsg("يرجي تسجيل الدخول اولا")
//             } else {
//                 const res = await setSubscribe({
//                     subscriber: loggedUser?.user?._id,
//                     target: item?.teacher?._id
//                 });
//                 if (res?.data?.case) {
//                     success(res?.data?.message);
//                     if(res?.data?.message === "Subscribed successfully."){

//                     }
//                 } else {
//                     ErrorMsg(res?.data?.message);
//                 }
//             }
//         } catch (e) {
//             console.log(e)
//         }
//     }
//     return (
//         <div>
//             <button className="book-button buttonOpacity w-100" onClick={handleShow}>اشترك الان</button>

//             <Modal show={show} className='font modalBook' onHide={handleClose} centered dir="rtl">
//                 <Modal.Header className='headerTitle'>
//                     <Modal.Title className='text-center'>اشتراك </Modal.Title>
//                 </Modal.Header>

//                 <Modal.Body>
//                     {loading ? (
//                         <div className="loaderIcon" style={{ height: "10vh", textAlign: "center" }}>
//                             <span className="loader"></span>
//                         </div>
//                     ) : (
//                         <>

//                             <h5 style={{ color: "black", marginBottom: "15px" }}>
//                                 عليك  متابعة المعلم للاشتراك في البرامج
//                             </h5>
//                             <div style={{ textAlign: "left" }}>
//                                 <button className='btn buttonsGroub buttonOpacity' onClick={handleSubscribe}>اشتراك</button>
//                                 <button className='btn special color' onClick={handleClose}>الغاء</button>
//                             </div>
//                         </>
//                     )}
//                 </Modal.Body>
//             </Modal >

//             <AnotherBookingModal item={item} title={"احجز الان"} show={showBooking} onHide={handleCloseBooking}/>
//         </div>
//     )
// }

// export default SubscribeToTeacher


import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../styles/modal.css";
import { setSubscribe } from '../../utils/subscribe';
import { useSelector } from 'react-redux';
import { ErrorMsg, success } from '../shared/modules/Notifications';
import AnotherBookingModal from './AnotherBookingModal';
import { HomePage } from '../../utils/Translation/HomePage';

const SubscribeToTeacher = ({ targetTeacher, item }) => {
    const [show, setShow] = useState(false); // State for subscribe modal visibility
    const handleClose = () => setShow(false); // Function to close subscribe modal
    const handleShow = () => setShow(true); // Function to show subscribe modal
    const [showBooking, setShowBooking] = useState(false); // State for booking modal visibility
    const [loading, setLoading] = useState(false); // State for loading status

    const loggedUser = useSelector((state) => state.loggedUser); // Get the logged-in user from redux store

    const handleCloseBooking = () => setShowBooking(false); // Function to close booking modal
    const handleShowBooking = () => setShowBooking(true); // Function to show booking modal

    const handleSubscribe = async () => {
        try {
            if (loggedUser?.auth === false) { // Check if the user is logged in
                ErrorMsg("يرجي تسجيل الدخول اولا"); // Show error message if not logged in
            } else {
                const res = await setSubscribe({
                    subscriber: loggedUser?.user?._id, // Pass subscriber ID
                    target: item?.teacher?._id // Pass teacher ID
                });
                if (res?.data?.case) {
                    success(res?.data?.message); // Show success message
                    if (res?.data?.message === "Subscribed successfully.") {
                        handleClose(); // Close the subscribe modal
                        handleShowBooking(); // Open the booking modal
                    }
                } else {
                    ErrorMsg(res?.data?.message); // Show error message
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <button className="book-button buttonOpacity w-100" dir={`${dir}`} onClick={handleShow}>{HomePage.subscribeNow[lang]}</button>

            <Modal show={show} className='font modalBook' onHide={handleClose} centered dir="rtl">
                <div className='headerTitle py-3 px-2'>
                    <p className='fs-4' style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>{HomePage.subscribeNow[lang]} </p>
                </div>

                <Modal.Body>
                    {loading ? (
                        <div className="loaderIcon" style={{ height: "10vh", textAlign: "center" }}>
                            <span className="loader"></span>
                        </div>
                    ) : (
                        <>
                            <h5 style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}`, color: "black", marginBottom: "15px" }}>
                                {HomePage.info_subscribe[lang]}
                            </h5>
                            <div style={{ textAlign: "left" }}>
                                <button className='btn buttonsGroub buttonOpacity' onClick={handleSubscribe}>{HomePage.subscribeNow[lang]}</button>
                                <button className='btn special color' onClick={handleClose}>{HomePage.cancel[lang]}</button>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>

            <AnotherBookingModal
                item={item}
                title={"احجز الان"}
                showModal={showBooking}
                onHide={handleCloseBooking}
            />
        </div>
    );
};

export default SubscribeToTeacher;
