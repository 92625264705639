import axios from 'axios';
import { urlBase } from './API';

// create new class 
export const newClass = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/create-new-class`,
        data: data //{name , levelId, branchId}
    })
}

// add students 
export const addStudentsToClass = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/add-students-class`,
        data: data// {students,id,}
    })
}
// ADD TEACHER TO Class
// {teacher,id,materialCode} teacher mean teacher id , id mean class id
export const addTeacherToClass = (data) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/add-teacher-to-class`,
        data: data,
    })
}
// GET TEACHER ACTIVE CLASSES
// {id:id} teacher id
export const getTeacherClasses = (data) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/get-all/teacher/classes`,
        data: data
    })
}
// ADD SUPERVISOR TO CLASS 
//{id,supervisorID,materialCode}, value (id) for class id  
export const addSuperVisorToClass = (data) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/add/supervisor/to-class`,
        data: data
    })
}
//GET ALL CLASSES OF A SUPERVISOR
//{id} for supervisor
export const getAllSuperVisorOfClass = (id) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/get-all/supervisor/classes`,
        data: id
    })
}
// POST endpoint for distributing students to supervisors
//{superV, studentID, classID, materialCode}

export const distributeStudentsOnSuperVisor = (data) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/distribute-supervisor/students`,
        data
    })
}

// Remove Student in class from supervisor
// {studentID,classID,superV}
export const removeStudentOnSupervisor = (data) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/remove-student-from/supervisor`,
        data

    })
}
// change class active state
export const changeClassActiveState = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/deactivate-class`,
        data: data // {id,active}
    })
}
// get classes 
export const getClasses = () => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/get-classes`,

    })
}
//get student classes 

export const getClassesByStudent = (studentId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/get-classes-by-student`,
        data: studentId // {studentId}
    })
}
// get Class By Student By Program
//{studentId, packID}
export const getClassByStudentByProgram = (data) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/get-class-by-student-program`,
        data: data
    })
}
// Add Week Student 
// {materialCode,classID,studentID}
export const addWeekStudent = (data) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/add-week-student/class/material`,
        data: data
    })
}
// create meeting room
export const createMeetingRoom = (data) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/classes/${data.classId}/teachers`,
        data: data
    })
}

//get Meeting roomId
export const getMeetingRoomId = (data) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/classes/${data?.classId}/room`,
        data: data
    })
}
// Add Week Times 
export const addWeekTimes = (data)=>{
    return axios({
        method:'post',
        url:`${urlBase}/api/add/class/week-times`,
        data:data
    })
}

