import React, { useState, useRef } from 'react';
import "./newAccount.css";
import Modal from "../../modal/modal.js";
import Col from "../../styled-components/col";
import Contain from "../../styled-components/contain";
import { FcPlus } from "react-icons/fc";
import Input from "../../input/input";
import serializeForm from "form-serialize";
import { useDispatch, useSelector } from "react-redux";
import {
  Register_Admin,
  Register_Teacher,
} from "../../../actions/login&register";

function NewAccount({ type, setRender, render }) {
  const [open, setOpen] = useState(false);
  const form = useRef();
  const dispatch = useDispatch();
  const { toast } = useSelector(state => state.notify);

  //-----------Create New Account Function------
  const createNew = (e) => {
    e.preventDefault();
    const data = serializeForm(form.current, { hash: true });
    console.log(data);
    if (!data.name) {
      return toast().error(`"name" is required! `);
    }
    if (!data.email) {
      return toast().error(`"email" is required! `);
    }
    if (!data.password) {
      return toast().error(`"password" is required! `);
    }
    if (!data.phone) {
      toast().error(`"phone" is required! `);
    }
    data.role = type;
    const today = new Date();
    data.date = today.toISOString();
    dispatch(Register_Teacher(data));
    if (setRender) {
      setRender(!render);
    }
    setOpen(false);
  };

  const branches = useSelector((state) => state.branchs);

  return (
    <>
      <div className='fcplus-cont'>
        <FcPlus
          size="2em"
          onClick={() => setOpen(true)}
          style={{
            position: "absolute",
            right: "5px",
            cursor: "pointer",
            color: '#0F2A51'
          }}
          className='addPlus'
        />
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        width={"70vw"}
        height="60vh"
        className="font"
        centered
      >
        <form ref={form} onSubmit={createNew}>
          <Contain>
            <Col dir="column" fg="1" border="0px">
              <Col
                fg="1"
                justify="space-between"
                border="0px"
                perfix="form-row-n"
                width="80%"
              >
                <input
                  type="text"
                  name="name"
                  placeholder="Full name"
                  label="Full name"
                  className="form-control mx-2"
                />

                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  className="form-control mx-2"
                  label="Username"
                />
              </Col>
              <Col
                fg="1"
                justify="space-around"
                border="0px"
                perfix="form-row-n"
                width="80%"
              >
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  className='form-control mx-2'
                />

                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  className='form-control mx-2'
                />
              </Col>

              <Col
                fg="1"
                justify="space-around"
                border="0px"
                perfix="form-row-n"
                width="80%"
              >
                <input
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  className='form-control mx-2'
                />

                <select
                  name="branchId"
                  className="form-control"
                  style={{ marginLeft: "auto" }}
                >
                  {branches ? (
                    branches.map((branch) => {
                      return (
                        <option key={branch._id} value={branch._id}>
                          {branch.name}
                        </option>
                      );
                    })
                  ) : null}
                </select>
              </Col>
              <input
                type="submit"
                name="Save"
                placeholder="Save"
                className='subm form-control'
                style={{
                  width: "20%",
                  margin: "auto",
                  background: "#0F2A51",
                  textAlign: "center"
                }}
              />
            </Col>
          </Contain>
        </form>
      </Modal>
    </>
  );
}

export default NewAccount;
