import {Payment} from '../utils/productApi'
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';

const paymentRequest = (data)=>{
    return {
        type : PAYMENT_REQUEST,
        data
    }
}
export const Payment_Request=(data)=>{
    return (dispatch)=>{
        return Payment(data).then(res=>{
           dispatch(paymentRequest(res.data))
        })
    }
}