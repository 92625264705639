import axios from 'axios'
import React, { useState } from 'react'
import { urlBase } from '../../../utils/API'

function DownloadExcellFile({downloadRequestData,fileName}) {
    const [downloadLink,setDownloadLink] = useState(null)
    const [loading ,setLoading] = useState(false)
    
    const donwloadSheet = async (id) => {
        try {
            setLoading(true)
            const res = await axios.post(`${urlBase}/api/create/code/sheet/excell/file`, { id }, {
                responseType: 'blob', // Important to handle binary data
            });
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = window.URL.createObjectURL(blob);
            setDownloadLink(url)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }
  return (
    <>
    {!downloadLink?
                                            
        <button style={styles.prepareDownload} onClick={!loading?()=>{donwloadSheet(downloadRequestData)}:()=>{}}>{loading?'جارى تحضير الملف':'طلب الملف'}</button>
        :<a href={downloadLink} download={`code-sheet-${fileName}.xlsx`}>تحميل</a>
            }
            </>
  )
}
const styles = {
    prepareDownload:{
        border:'0px',
        
    }
}

export default DownloadExcellFile