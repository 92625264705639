import React from 'react'
import platformOne from "../../assets/platform1.png"
import platformTwo from "../../assets/platform2.png"
import platformThree from "../../assets/platform3.png"
import platformFour from "../../assets/platform4.png"
import { useSelector } from 'react-redux'
import { HomePage } from '../../utils/Translation/HomePage'
const OurPlatform = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div dir={`${dir}`}>
            <div style={{ textAlign: "center", marginTop: "120px" }}>
                <h4 style={{ marginBottom: "100px" }}>{HomePage.titlePlatform[lang]}</h4>
            </div>
            <div className='row  justify-content-between align-items-center' style={{ marginTop: "50px" }} dir="rtl">
                <div className='col-lg-3 col-md-6 col-sm-12 col-md-6 col-6'>
                    <div className='text-center'>
                        <img src={platformOne} alt="imagePlatform" className='w-25 mb-4' />
                        <p>{HomePage.performance[lang]}</p>
                        <p className='mt-3'>{HomePage.performance_content[lang]}</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-md-6 col-6'>
                    <div className='text-center'>
                        <img src={platformTwo} alt="imagePlatform" className='w-25 mb-4' />
                        <p>{HomePage.maintaince[lang]}</p>
                        <p className='mt-3'>
                            {HomePage.maintaince_content[lang]}
                        </p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-md-6 col-6'>
                    <div className='text-center'>
                        <img src={platformThree} alt="imagePlatform" className='w-25 mb-4' />
                        <p>{HomePage.interactive[lang]}</p>
                        <p className='mt-3'>{HomePage.interactive_content[lang]}</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-md-6 col-6'>
                    <div className='text-center'>
                        <img src={platformFour} alt="imagePlatform" className='w-25 mb-4' />
                        <p>{HomePage.teacher_prof[lang]}</p>
                        <p className='mt-3'>
                            {HomePage.teacher_prof_content[lang]}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurPlatform
