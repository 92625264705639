import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Get_All_Packages, Delete_Package } from '../../../actions/packages';
import { Dropdown, DropdownButton, Table, Form } from 'react-bootstrap';
import { FcDeleteDatabase } from "react-icons/fc";
import Edit from './edit';
import AddCode from './AddCode';
import GetCodes from './GetCodes';

function PackagesList({ handleToggle }) {
  const dispatch = useDispatch();

  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');

  const onDelete = async (id) => {
    try {
      await dispatch(Delete_Package({ id }));
    } catch (err) {
      console.warn(err.message);
    }
  };

  const getPacks = async () => {
    try {
      await dispatch(Get_All_Packages());
    } catch (err) {
      console.warn(err.message);
    }
  };

  useEffect(() => {
    getPacks();
  }, [dispatch]);

  const { packages, levels, branchs } = useSelector((state) => ({
    packages: state.packages,
    levels: state.levels,
    branchs: state.branchs,
  }));

  const filteredLevels = levels.filter(level => {
    return branchs.find(branch => branch._id === selectedBranch)?.levelsIDS.includes(level._id);
  });

  const filteredPackages = packages.filter((pack) => {
    return (
      (!selectedBranch || pack.branchId === selectedBranch) &&
      (!selectedLevel || pack.levelId === selectedLevel)
    );
  });
  console.log('packages', packages)
  return (
    <div className='font'>
      <h2> البرامج الحالية : {filteredPackages?.length}</h2>
      <div style={{ height: "100vh" }}>
        <Form>
          <Form.Group controlId="selectBranch">
            <Form.Label>اختر الفرع</Form.Label>
            <Form.Control
              as="select"
              value={selectedBranch}
              onChange={(e) => {
                setSelectedBranch(e.target.value);
                setSelectedLevel('');
              }}
            >
              <option value="">الكل</option>
              {branchs.map((branch) => (
                <option key={branch._id} value={branch._id}>
                  {branch.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="selectLevel">
            <Form.Label>اختر المستوى</Form.Label>
            <Form.Control
              as="select"
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(e.target.value)}
              disabled={!selectedBranch}
            >
              <option value="">الكل</option>
              {filteredLevels.map((level) => (
                <option key={level._id} value={level._id}>
                  {level.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
        <Table>
          <thead>
            <tr>
              <th>الاسم</th>
              <th>السعر</th>
              <th>السعر بعد الخصم</th>
              <th>تعديل/عرض</th>
              <th>اضافة الاكواد</th>
              <th>عرض الاكواد</th>
              <th>حذف</th>
            </tr>
          </thead>
          <tbody>
            {filteredPackages.map((pack) => (
              <tr key={pack._id}>
                <td>{pack?.name}</td>
                <td>{pack?.price}</td>
                <td>{pack?.discountPrice}</td>
                <td>
                  <Edit pack={pack} />
                </td>
                <td>
                  <DropdownButton id="dropdown-basic-button" title="المواد">
                    {pack?.materials?.map((material) => (
                      <Dropdown.Item key={material?.materialCode}>
                        <AddCode material={material} packages={pack} />
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </td>
                <td>
                  <DropdownButton id="dropdown-basic-button" title="المواد">
                    {pack.materials?.map((material) => (
                      <Dropdown.Item key={material?.materialCode}>
                        <GetCodes material={material} packages={pack} />
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </td>
                <td>
                  <FcDeleteDatabase size={35} onClick={() => onDelete(pack._id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default PackagesList;
