import { AUTH_LOGIN, PROGRAMS_PER_USER, SEND_MESSAGE, UPDATE_USER_IMG, GET_TEACHER_STUDENTS, STORE_CLASSES, TEACHER_SELECTED_TEST } from "../actions/login&register";
import { EDIT_APPOINTMENT } from '../actions/booking';


export const loggedUser = (state = {}, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return state = { ...action.user };
    // case SEND_MESSAGE:
    //   let user = state;
    //   console.log('action...........',action);

    //   user.user.sent=[...user.user.sent,{...action.contact.messages,to:action.contact.to}]
    //   return state={...state}
    case EDIT_APPOINTMENT:
      const appointments = state.user.appointments.map(a => {
        if (a.id === action.bookingId) {
          a.finished = parseInt(a.finished) + 1;
          return a
        }
        else return a
      })
      break;

    case UPDATE_USER_IMG:
      return state = { ...state, user: action.payload };

    case PROGRAMS_PER_USER:
      return state = { ...state, programs: action.payload };

    case GET_TEACHER_STUDENTS:
      return state = { ...state, teacherStudents: action.payload };

    case STORE_CLASSES:
      return state = { ...state, classes: action.payload };

    case TEACHER_SELECTED_TEST:
      return state = { ...state, teacher_test: action.payload }

    default:
      return state;
  }
};
