import { ROOM_ID, MEETING_DETAILS, ADD_SOCKET } from "../actions/etcMeeting";

export const meeting = (state = { socketRef: null }, action) => {
    switch (action.type) {
        case ADD_SOCKET:
            return state = { ...state, socketRef: action.socketRef }
        case ROOM_ID:
            return state = { ...state, roomId: action.id.roomId, userId: action.id.userId }
        case MEETING_DETAILS:
            return state = { ...state, ...action.details }
        default: return state
    }
}