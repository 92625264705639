// import React, { useState, useEffect, useCallback } from 'react';
// import { Container, Form, Row, Col, Button, Modal } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
// import { Update_Package } from '../../../actions/packages';
// import Loading from '../../loadingEffect';
// import { set } from 'date-fns';
// import { uploadFileLocaly, urlBase } from '../../../utils/API';
// import axios from 'axios';

// function Edit({ pack, currency }) {
//   const { user } = useSelector(({ loggedUser }) => {
//     return { user: loggedUser?.user }
//   })
//   const [showModal, setShowModal] = useState(false);
//   const [materials, setMaterials] = useState(pack.materials);
//   const [name, setName] = useState(pack.name);
//   const [price, setPrice] = useState(pack.price);
//   const [discountPrice, setDiscountPrice] = useState(pack?.discountPrice);
//   const [delayed, setDelayed] = useState(pack.delayed ? pack.delayed : false)
//   const [description, setDescription] = useState(pack.description);
//   const [coverImage, setCoverImage] = useState(pack.coverImage);
//   const [loading, setLoading] = useState(false);
//   const { toast } = useSelector((state) => state.notify);
//   const dispatch = useDispatch();

//   const handleCloseModal = () => setShowModal(false);
//   const handleShowModal = () => setShowModal(true);
//   const [progress, setProgress] = useState(0);

//   const handleMaterialChange = useCallback((index, updatedMaterial) => {
//     setMaterials((prevMaterials) =>
//       prevMaterials.map((material, i) => (i === index ? updatedMaterial : material))
//     );
//   }, []);

//   const [file, setFile] = useState(null)
//   const [cover, setCover] = useState(null)

//   console.log(pack)

//   const handleSave = async () => {
//     setLoading(true);
//     try {
//       const updatedPackage = {
//         ...pack,
//         active: false,
//         name,
//         price,
//         description,
//         materials: materials.map((m) => ({
//           materialCode: m.materialCode,
//           selectedLessons: m.selectedLessons,
//         })),
//         delayed,
//         discountPrice
//       };
//       let upladCoverRes
//       if (file) {

//         upladCoverRes = await uploadingFileLocally(file);
//         if (!upladCoverRes.data.case) throw new Error('خطا فى تحميل الملف!');
//         updatedPackage.coverImage = upladCoverRes?.data?.data.name;
//     }
//       const res = await dispatch(Update_Package(updatedPackage));
//       console.log(res)
//       if (res.data.case) {
//         toast().success(res.data.message);
//       } else {
//         toast().error(res.data.message);
//       }
//     } catch (err) {
//       toast().error(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (showModal) {
//       materials.forEach((material, index) => {
//         getLessonsOfSpecificMaterial({ code: material.materialCode }).then((res) => {
//           const updatedMaterial = { ...material, lessons: res.data.lessons };
//           handleMaterialChange(index, updatedMaterial);
//         });
//       });
//     }
//   }, [showModal]);


//   function uploadingFileLocally(data) {
//     const formData = new FormData();
//     formData.append("file", data)
//     return axios({
//         method: "POST",
//         url: `${urlBase}/api/upload-file-locally?userId=${user?._id}`,
//         data: formData,
//         onUploadProgress: (progressEvent) => {
//             const percentage = Math.round(
//                 (progressEvent.loaded * 100) / progressEvent.total
//             );
//             setProgress(percentage)

//         }
//     })
// }

//   return (
//     <>
//       <Button
//         style={{ width: '70px', backgroundColor: '#fff', color: 'gray' }}
//         onClick={handleShowModal}
//       >
//         تعديل
//       </Button>
//       <Modal show={showModal} onHide={handleCloseModal} dir='rtl' className='font'>
//         <Modal.Header>
//           <Modal.Title>تعديل البرنامج</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {loading ? (
//             <Loading />
//           ) : (
//             <Form>
//               <Form.Label> برنامج مؤجل  </Form.Label>

//               {/* <Form.Check // prettier-ignore
//                 type="switch"
//                 id="custom-switch"
//                 checked={delayed}
//                 onChange={() => { setDelayed(!delayed) }}
//               /> */}

//               <label className="switch mx-4">
//                 <input
//                   type="checkbox"
//                   checked={delayed}
//                   onChange={() => { setDelayed(!delayed) }}
//                 />
//                 <span className="slider round"></span>
//               </label>

//               <Form.Group controlId="packageName">
//                 <Form.Label>اسم البرنامج</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)
//                   }

//                 />
//               </Form.Group>
//               <Form.Group controlId="packageImage">
//                 <Form.Label>تعديل صورة البرنامج</Form.Label>
//                 <Form.Control
//                   type="file"
//                   onChange={(event) => {
//                     setFile(event.target.files[0]);
//                     const url = window.URL.createObjectURL(event.target.files[0]);
//                     setCover(url)
//                   }}
//                 />
//                 {cover ?
//                   <img width="300px" src={cover} alt="coverImage" /> : <></>}
//               </Form.Group>
//               <Form.Group controlId="packagePrice">
//                 <Form.Label>السعر ({currency})</Form.Label>
//                 <Form.Control
//                   type="number"
//                   value={price}
//                   onChange={(e) => setPrice(e.target.value)}
//                 />
//               </Form.Group>
//               <Form.Group controlId="packagePrice">
//                 <Form.Label>السعر بعد الخصم ({currency})</Form.Label>
//                 <Form.Control
//                   type="number"
//                   value={discountPrice}
//                   onChange={(e) => setDiscountPrice(e.target.value)}
//                 />
//               </Form.Group>

//               <Form.Group controlId="packageDescription">
//                 <Form.Label>الوصف</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={3}
//                   value={description}
//                   onChange={(e) => setDescription(e.target.value)}
//                   style={{ resize: "none" }}
//                 />
//               </Form.Group>
//               {materials.map((material, index) => (
//                 <Form.Group key={material.materialCode} controlId={`material-${material.materialCode}`}>
//                   <Form.Label>مادة {material.materialCode}</Form.Label>
//                   <Row>
//                     {material.lessons?.map((lesson) => (
//                       <Col key={lesson._id} xs={6} md={4}>
//                         <Form.Check
//                           type="checkbox"
//                           label={lesson.name}
//                           checked={material.selectedLessons.includes(lesson._id)}
//                           onChange={(e) => {
//                             const updatedSelectedLessons = e.target.checked
//                               ? [...material.selectedLessons, lesson._id]
//                               : material.selectedLessons.filter((id) => id !== lesson._id);
//                             handleMaterialChange(index, {
//                               ...material,
//                               selectedLessons: updatedSelectedLessons,
//                             });
//                           }}
//                         />
//                       </Col>
//                     ))}
//                   </Row>
//                 </Form.Group>
//               ))}
//             </Form>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <button className='btn special color' onClick={handleCloseModal}>
//             اغلاق
//           </button>
//           <Button className='btn buttonsGroub' onClick={handleSave}>
//             حفظ التغييرات
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default Edit;

// File path: src/components/EditPackage/Edit.js

import React, { useState, useEffect, useCallback } from 'react';
import { Container, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { Update_Package } from '../../../actions/packages';
import Loading from '../../loadingEffect';
import axios from 'axios';
// import { urlBase } from '../../../utils/API';
import { urlBase } from '../../../utils/API';

function Edit({ pack, currency }) {
  const [showModal, setShowModal] = useState(false);
  const [materials, setMaterials] = useState(pack.materials);
  const [name, setName] = useState(pack.name);
  const [price, setPrice] = useState(pack.price);
  const [discountPrice, setDiscountPrice] = useState(pack?.discountPrice);
  const [delayed, setDelayed] = useState(pack.delayed ? pack.delayed : false);
  const [startDate, setStartDate] = useState(pack?.startDate)
  const [description, setDescription] = useState(pack.description);
  const [coverImage, setCoverImage] = useState(pack?.coverImage);
  const [image, setImage] = useState(null);
  const [cover, setCover] = useState(`${urlBase}/${pack?.coverImage}`);
  const [level, setLevel] = useState(pack?.levelId);
  const [loading, setLoading] = useState(false);
  const { toast } = useSelector((state) => state.notify);
  const { levels, branchs } = useSelector(({ levels, branchs }) => {
    return { levels, branchs }
  });

  const dispatch = useDispatch();

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleMaterialChange = useCallback((index, updatedMaterial) => {
    setMaterials((prevMaterials) =>
      prevMaterials.map((material, i) => (i === index ? updatedMaterial : material))
    );
  }, []);

  const detectLevel = (id) => {
    const [lev] = levels.filter(l => l?._id === id);
    return lev;
  }



  const { user } = useSelector(state => state?.loggedUser);
  // 
  function uploadingFileLocally(data) {
    const formData = new FormData();
    formData.append("file", data)
    return axios({
      method: "POST",
      url: `${urlBase}/api/upload-file-locally?userId=${user?._id}`,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // setProgress(percentage)

      }
    })
  }
  const handleSave = async () => {
    setLoading(true);

    try {
      let cov = null;
      if (image) {
        const res = await uploadingFileLocally(image);
        if (res.data.case) {
          cov = res?.data?.data?.name
        }

      }
      const updatedPackage = {
        ...pack,
        active: false,
        name,
        price,
        description,
        materials: materials.map((m) => ({
          materialCode: m.materialCode,
          selectedLessons: m.selectedLessons,
        })),
        delayed,
        startDate,
        discountPrice,
        coverImage: cov ? cov : coverImage
      };

      const res = await dispatch(Update_Package(updatedPackage));
      console.log(res);
      if (res.data.case) {
        toast().success(res.data.message);
      } else {
        toast().error(res.data.message);
      }
    } catch (err) {
      toast().error(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (showModal) {
      materials.forEach((material, index) => {
        getLessonsOfSpecificMaterial({ code: material.materialCode }).then((res) => {
          const updatedMaterial = { ...material, lessons: res.data.lessons };
          handleMaterialChange(index, updatedMaterial);
        });
      });
    }
  }, [showModal]);
  // console.log('startD',startDate)
  return (
    <>
      <Button
        style={{ width: '70px', backgroundColor: '#fff', color: 'gray' }}
        onClick={handleShowModal}
        className='font'
      >
        تعديل
      </Button>
      <Modal show={showModal} className='font' onHide={handleCloseModal} dir='rtl'>
        <Modal.Header>
          <Modal.Title>تعديل البرنامج</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loading />
          ) : (
            <Form>

              <Form.Label> برنامج مؤجل  </Form.Label>

              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                checked={delayed}
                onChange={() => { setDelayed(!delayed) }}
              />
              {delayed ? <Col md={6} className='mt-3'>
                <Form.Group controlId="levelSelect">
                  <Form.Label> اختيار التاريخ المؤجل له</Form.Label>
                  <input type="date"
                    defaultValue={startDate}
                    name="typeDate" id="typeDate" className="form-control"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Form.Group>
                <p>{startDate ? startDate.split('T')[0] : <></>}</p>
              </Col> : <></>}
              <h2>{detectLevel(level)?.name}</h2>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>اختيار صورة</Form.Label>
                <Form.Control type="file"
                  onChange={(event) => {
                    setImage(event.target.files[0]);
                    const url = window.URL.createObjectURL(event.target.files[0]);
                    setCover(url)

                  }}
                />
              </Form.Group>
              {cover ?
                <img width="300px" src={cover} /> : <></>}
              <Form.Group controlId="packageName">
                <Form.Label>اسم البرنامج</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="packagePrice">
                <Form.Label>السعر {currency ? currency : 'ج.م'}</Form.Label>
                <Form.Control
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="packagePrice">
                <Form.Label>السعر بعد الخصم {currency ? currency : 'ج.م'}</Form.Label>
                <Form.Control
                  type="number"
                  value={discountPrice}
                  onChange={(e) => setDiscountPrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="packageDescription">
                <Form.Label>الوصف</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              {materials.map((material, index) => (
                <Form.Group key={material.materialCode} controlId={`material-${material.materialCode}`}>
                  <Form.Label>مادة {material.materialCode}</Form.Label>
                  <Row>
                    {material.lessons?.map((lesson) => (
                      <Col key={lesson._id} xs={6} md={4}>
                        <Form.Check
                          type="checkbox"
                          label={lesson.name}
                          checked={material.selectedLessons.includes(lesson._id)}
                          onChange={(e) => {
                            const updatedSelectedLessons = e.target.checked
                              ? [...material.selectedLessons, lesson._id]
                              : material.selectedLessons.filter((id) => id !== lesson._id);
                            handleMaterialChange(index, {
                              ...material,
                              selectedLessons: updatedSelectedLessons,
                            });
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              ))}
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            اغلاق
          </Button>
          <Button variant="primary" onClick={handleSave}>
            حفظ التغييرات
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Edit;
