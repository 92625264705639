import React from 'react';
import Table from 'react-bootstrap/Table';
import AddStudentsToSuperVisor from './addStudentsToSuperVisor';
import { useSelector } from 'react-redux';
import UsersList from './supervisorStudentsList';

function SupervisorsList({ Class }) {
  // console.log('Class', Class);
  const materials = useSelector(state=>state.materials)
// console.log('mat',materials)
  const getMaterial = (code)=>{ 
    const material = materials.find(m=>m.code === code);
    return material?material:{name:'المادة غير موجودة'}
  }
  return (
    <div  className="table-responsive ">
      {Class &&materials && Class.supervisorIDS && Class.supervisorIDS.length > 0 ? (
        <Table className=''  striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>الاسم</th>
              <th>المادة</th>
              <th>الطلاب</th>
              <th>عدد الطلاب</th>
              <th>اضافة طلاب</th>
              
              {/* Add more table headers for other supervisor details if needed */}
            </tr>
          </thead>
          <tbody>
            {Class.supervisorIDS.map((supervisor,index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{supervisor.superV.name||supervisor.superV.username}</td>
                <td>{getMaterial(supervisor?.materialCode)?.name}</td> 
                <td><UsersList usersIDS={supervisor?.students}/></td>
                <td>{supervisor?.students?.length}</td>
                <td><AddStudentsToSuperVisor cls = {Class} supervisor={supervisor}/>  </td>
                {/* Render additional details here if needed */}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No supervisors found.</p>
      )}
    </div>
  );
}

export default SupervisorsList;
