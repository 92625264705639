import { getTestsByIds } from "../../utils/tests";

export const GET_TEST = 'GET_TEST';

const getTest = (test) => {
    return {
        type: GET_TEST,
        test
    }
}

export const Get_Test = (testIDS) => {
    return dispatch => getTestsByIds({ testIDS }).then((res) => {
        if (res.data.case) {
            dispatch(getTest(res.data.tests[0]))
        }
        return res
    })
}

export const SET_RESULT = "SET_RESULT";
const setResult = (result) => {
    return {
        type: SET_RESULT,
        result
    }
}
export const Set_Result = (result) => {
    return dispatch => dispatch(setResult(result))
}