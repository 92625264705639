import React, { useContext, useEffect, useState } from "react";

import "./styles/loginPag.css";
import { MdEmail } from "react-icons/md";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { logIn } from "../../../actions/login&register";
import { FaFacebookF } from "react-icons/fa";
import { FaKey } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import google from "../../../assets/google.png";
import login_Rectangle from "../../../assets/login_image.png";
import { Get_All_My_Messages } from "../../../actions/massenger";
import { loginTrans } from "../../../utils/Translation/loginAndRegister";
import PageContainer from "../../layout/PagecContainer";
import { ThemeContext } from "../../../ThemeProvider";
import { HomePage } from "../../../utils/Translation/HomePage";



function LoginPage(props) {


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  // handling the form data to submit
  const dispatch = useDispatch();
  // let handleSubmit = async (e) => {
  //   try {

  //     e.preventDefault();
  //     setLoading(true);
  //     let formData = {
  //       email,
  //       password,
  //     };
  //     const res = await dispatch(logIn(formData));
  //     // console.log('login res');
  //     console.log(res);

  //     // return;
  //     setMessage(res);
  //     // let role="student";
  //     setLoading(false);

  //     if (!res.auth) return alert(' الرقم السرى او البريد الالكترونى غير صحيح!!')
  //     let id;
  //     id = res.user?._id;
  //     const name = res.user?.name;


  //     // Save user data to localStorage
  //     localStorage.setItem('user', JSON.stringify(res.user));


  //     if (res.user.role === 'Admin') {
  //       return navigate(`/user/${id}`)
  //     }

  //     //get all messages
  //     // let messages = await getAllMyMessages({ ownerID: id });

  //     // console.log('messages before login',messages);

  //     // return;
  //     //   if (messages?.data?.case === true) {
  //     //     let contacts = messages?.data?.contacts;
  //     //     let contactsArr = [];
  //     //     for (let contact of contacts) {
  //     //       let contactObj = await getUserByID({ userID: contact.contactID });
  //     //       //contactObj?.data?.user
  //     //       const dateString = contact?.date;
  //     //       const date = new Date(dateString);

  //     //       const day = date.getDate();
  //     //       const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  //     //       const year = date.getFullYear();
  //     //       const formattedDate = `${day}-${month < 10 ? '0' + month : month}-${year}`;
  //     //       // console.log(formattedDate); // Output: 18-03-2024

  //     //       contact = { ...contact, contactID: contactObj?.data?.user, date: formattedDate };
  //     //       console.log(contact);
  //     //       contactsArr.push(contact);

  //     //     }
  //     //     console.log('contactsArr',contactsArr);
  //     //    // return;

  //     //     // await dispatch(storeUserMessages([1,2,3]));
  //     //    //old await dispatch(storeUserMessages(contactsArr));

  //     //  // await dispatch(Get_All_My_Messages({ownerID:}));

  //     //   }
  //     await dispatch(Get_All_My_Messages({ ownerID: id }));

  //     // console.log(res)

  //     if (res?.auth) {
  //       navigate(`/dashboard/${name.split(' ').join('')}/${id}/profile`);
  //     }
  //     navigate(`/dashboard/${name.split(' ').join('')}/${id}/profile`);
  //     navigate(-1)
  //   } catch (err) {
  //     console.log(err.message);
  //     setLoading(false);
  //     setMessage(err?.response?.data);
  //     console.log("formData---------", err?.response?.data);
  //   }
  // };
  const location = useLocation();
  useEffect(() => {
    // حفظ المسار الحالي في localStorage
    localStorage.setItem("lastRoute", location.pathname);
  }, [location]);


  let handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let formData = {
        email,
        password,
      };
      const res = await dispatch(logIn(formData));

      setLoading(false);

      if (!res.auth) return alert('الرقم السري أو البريد الإلكتروني غير صحيح!!');

      let id = res.user?._id;
      const name = res.user?.name;

      // حفظ بيانات المستخدم في localStorage
      localStorage.setItem("user", JSON.stringify(res.user));

      // استرجاع المسار الأخير من localStorage
      const lastRoute = localStorage.getItem("lastRoute") || `/dashboard/${name.split(" ").join("")}/${id}/profile`;

      // توجيه المستخدم إلى المسار الأخير أو المسار الافتراضي
      if (lastRoute === "/" || lastRoute === "/login" || lastRoute === "/register") {
        if (res?.user?.role === "Admin") {
          navigate(`/user/${id}`)
        } else {
          navigate(`/dashboard/${name.split(" ").join("")}/${id}/profile`);
        }
      } else {
        navigate(lastRoute);
      }

    } catch (err) {
      console.log(err.message);
      setLoading(false);
      setMessage(err?.response?.data);
    }
  };


  const { theme } = useContext(ThemeContext);



  return (
    <PageContainer>
      <div className={`loginpage font`}>
        <div className={`container spacePage`}>
          {!loading ? (
            <div className="loginForm py-4">
              {
                message && <div className={`mt-3 text-center alert ${message?.auth ? 'alert-success' : 'alert-danger'}`} role="alert">
                  {message && message?.message}
                </div>
              }


              <div className={`row `}>
                <div className="col-md-6 ">
                  <div className="formPart">
                    <form className="w-100" action="" onSubmit={handleSubmit}>
                      <div className="font text-center">
                        <p className="color">{HomePage.welcomeSentence[lang]}</p>
                        <p style={{ color: `${theme === "light" ? "black" : "black"}` }}>{HomePage.pleaseLogin[lang]}</p>
                      </div>
                      {/* Links */}
                      <div>
                        <div className="links font shadow">
                          <img src={google} alt="google" className="me-2" />
                          <span style={{ color: `${theme === "light" ? "black" : "black"}` }}>{HomePage.google[lang]}</span>
                        </div>
                        <div className="links font shadow">
                          <FaFacebookF color="#3B5999" className="me-2" />
                          <span style={{ color: `${theme === "light" ? "black" : "black"}` }}>{HomePage.facebook[lang]}</span>
                        </div>
                      </div>
                      {/* Links */}

                      {/* Or */}
                      <div className="hr mt-5">
                        <div className="line"></div>
                        <div className="font">او</div>
                        <div className="line"></div>
                      </div>


                      <div className="mb-3 mt-4 positionInput">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder={loginTrans.email[lang]}
                          id="loginId1"
                          // aria-describedby="emailHelp"
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}

                        />
                        <div className="email" style={{ color: `${theme === "light" ? "black" : "black"}` }}> <MdEmail /></div>

                      </div>

                      <div className="mb-3 mt-4 positionInput">
                        <input
                          type="password"
                          name="password"
                          placeholder={loginTrans.pass[lang]}
                          className="form-control"
                          id="loginId2"
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                        />
                        <div className="email">  <FaKey /></div>
                      </div>
                      {/* Addition Help for passowrd and remeber */}
                      <div className="d-flex justify-content-between">
                        <div>
                          <Link className="link color font" to="/forget-pass">{loginTrans.password[lang]}</Link>
                        </div>
                        <div>
                          <label className="me-4 font" style={{ color: `${theme === "light" ? "black" : "black"}` }}>{loginTrans.checkbox[lang]}</label>
                          <input type="checkbox" name="remeber me" />
                        </div>
                      </div>

                      {/* تسجيل الدخول */}
                      <div className="text-center" style={{ margin: "35px auto" }}>
                        <input
                          type="submit"
                          className=" w-100 font btn buttonsGroub"

                          style={{ background: "" }}

                          id="loginId3"
                          // onClick={}
                          value={loginTrans.completed[lang]}
                        />

                      </div>

                      {/* Not Account Register Now */}
                      <div className="mt-4 text-center">
                        <div className="font">
                          <span>{loginTrans.accountHasNot[lang]}</span> &nbsp;
                          <br />
                          <Link to="/register">{loginTrans.subscripe[lang]}</Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-6 d-none d-md-block">
                  <div className="imagePart">
                    <img src={login_Rectangle} className="login-image w-100 h-100" alt="error" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="loaderIcon">
              <span className="loader"></span>
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
}

export default LoginPage;
