import { urlBase } from "../API";
import axios from 'axios';

// new order 
// {productID ,userID, categoryID ,units , color , address , response ,userID}
export const newOrder = (order) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/new-order`,
        data: order
    })
}

// get all orders
export const getAllOrders = () => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/get-orders-in-store`,
        data: {}
    })
}

//get orders per user
export const getOrdersPerUser = (data) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/get-orders-by-user`,
        data: data //{userID}
    })
}
// Action On Request

export const actionOnOrder = (data) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/action-on-request`,
        data: data,// {response,id}
    })
}