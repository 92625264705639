// import React, { useState } from 'react';
// import { Form, FormControl, FormGroup, FormLabel, Button, FormCheck, Container, Alert } from 'react-bootstrap';
// import './tests.css';
// import { useSelector } from 'react-redux';
// import { createTest } from '../../../utils/tests';
// import AddQuestion from './addQuestion';
// import Question from './question';
// import "./addTest.css"
// function AddTest() {
//   const [message, setMessage] = useState('');
//   const [testName, setTestName] = useState('');
//   const [totalDegree, setTotalDegree] = useState(0); // Added totalDegree state
//   // const [tType, setTType] = useState('multi');
//   const [questions, setQuestions] = useState([]);
//   const [alertVariant, setAlertVariant] = useState(null);

//   const handleTestNameChange = (e) => {
//     setTestName(e.target.value);
//   };

//   const handleTotalDegreeChange = (e) => {
//     setTotalDegree(e.target.value);
//   };

//   // question text
//   const handleQuestionChange = (e, questionIndex) => {
//     setQuestions((prevQuestions) => {
//       const updatedQuestions = [...prevQuestions];
//       updatedQuestions[questionIndex].question = e.target.value;
//       return updatedQuestions;
//     });
//   };

//   // question degree
//   const handleDegreeChange = (e, questionIndex) => {
//     setQuestions((prevQuestions) => {
//       const updatedQuestions = [...prevQuestions];
//       updatedQuestions[questionIndex].degree = e.target.value;
//       return updatedQuestions;
//     });
//   };

//   // choices text with CHOICE type
//   const handleChoiceChange = (e, questionIndex, choiceIndex) => {
//     setQuestions((prevQuestions) => {
//       const updatedQuestions = [...prevQuestions];
//       updatedQuestions[questionIndex].choices[choiceIndex].value = e.target.value;
//       return updatedQuestions;
//     });
//   };

//   // correct choice with multible choices
//   const handleRadioChange = (questionIndex, choiceIndex) => {
//     setQuestions((prevQuestions) => {
//       const updatedQuestions = [...prevQuestions];
//       updatedQuestions[questionIndex].choices = updatedQuestions[questionIndex].choices.map(
//         (choice, i) => {
//           return { ...choice, correct: i === choiceIndex };
//         }
//       );
//       return updatedQuestions;
//     });
//   };

//   // handel add file
//   const handelAddFile = (questionIndex, file) => {
//     const updatedQuestions = questions.map((p, i) => {
//       if (i === questionIndex) {
//         p.file = file;
//         return p
//       }
//       return p
//     })
//     setQuestions(updatedQuestions);
//   }
//   const updateReason = (questionIndex, reason) => {
//     const updatedQuestions = questions.map((p, i) => {
//       if (i === questionIndex) {
//         p.reason = reason;
//         return p
//       }
//       return p
//     })
//     setQuestions(updatedQuestions);
//   }

//   // handel true or false answer
//   const handleTrue_FalseAnswer = (questionIndex, ans) => {
//     console.log('event', ans)
//     const updatedQuestions = questions.map((p, i) => {
//       if (i === questionIndex) {
//         p.TrueFalseAnswer = ans;
//         return p
//       }
//       return p
//     })
//     setQuestions(updatedQuestions);
//   }
//   //-------
//   console.log('questions', questions)
//   function QuestionByType(t, index) {
//     // console.log('type',type)
//     // debugger
//     return {
//       order: index,
//       questionType: { q: t.q, answer: t.answer },
//       question: null,
//       degree: null,
//       file: null,
//       reason: null,
//       choices: t.answer === 'CHOICE' ? [
//         { value: null, correct: false },
//         { value: null, correct: false },
//         { value: null, correct: false },
//         { value: null, correct: false },
//       ] : null,
//       TrueFalseAnswer: null,
//     }


//   }
//   const addQuestion = (t) => {

//     console.log('first',
//       QuestionByType(t, questions.length + 1)
//     )
//     setQuestions((prevQuestions) => [
//       ...prevQuestions,
//       QuestionByType(t, questions.length + 1)
//     ]);
//   };

//   const deleteLastQuestion = () => {
//     if (questions.length > 1) {
//       setQuestions((prevQuestions) => prevQuestions.slice(0, -1));
//     }
//   };

//   // get test target id
//   const { testFor } = useSelector(({ view }) => {
//     return { testFor: view?.testFor };
//   });

//   // submit test
//   const submitTest = async () => {
//     try {
//       const testData = {
//         test: {
//           name: testName,
//           tot_degree: totalDegree, // In5clude totalDegree in the request
//           questions: questions,
//         },
//         testFor,
//       };
//       const res = await createTest(testData);
//       setMessage(res.data.message);
//       setAlertVariant(res.data.case ? 'success' : 'danger');
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   return (
//     <Container style={{
//       paddingBottom: '100px'
//     }}>
//       <h2>اضافة اختبار </h2>
//       <hr />

//       <FormGroup>
//         <FormLabel> اسم الإختبار:</FormLabel>
//         <FormControl type="text" value={testName} onChange={handleTestNameChange} />
//       </FormGroup>

//       <FormGroup>
//         <FormLabel> الدرجة الكلية:</FormLabel>
//         <FormControl type="number" min={0} value={totalDegree} onChange={handleTotalDegreeChange} />
//       </FormGroup>


//       {questions.map((question, questionIndex) => (

//         <Question
//           question={question}
//           questionIndex={questionIndex}
//           handleQuestionChange={handleQuestionChange}
//           handleDegreeChange={handleDegreeChange}
//           handleChoiceChange={handleChoiceChange}
//           handleRadioChange={handleRadioChange}
//           handelAddFile={handelAddFile}
//           updateReason={updateReason}
//           handleTrue_FalseAnswer={handleTrue_FalseAnswer}
//         />
//       ))}

//       <br />
//       <AddQuestion
//         addQuestion={addQuestion}
//         order={questions.length}
//         deleteLastQuestion={deleteLastQuestion} />


//       {message && (
//         <Alert variant={alertVariant} onClose={() => setMessage('')} dismissible>
//           {message}
//         </Alert>
//       )}
//       {/* Add your submit button */}
//       <Button onClick={submitTest} variant="success" style={{ marginTop: '20px' }}>
//         حفظ
//       </Button>

//     </Container>
//   );
// }

// export default AddTest;



import React, { useState } from 'react';
import { Form, FormControl, FormGroup, FormLabel, Button, Spinner, Container, Alert } from 'react-bootstrap';
import './tests.css';
import { useSelector } from 'react-redux';
import { createTest } from '../../../utils/tests';
import AddQuestion from './addQuestion';
import Question from './question';
import "./addTest.css"

function AddTest() {
  const [message, setMessage] = useState('');
  const [testName, setTestName] = useState('');
  const [totalDegree, setTotalDegree] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [alertVariant, setAlertVariant] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTestNameChange = (e) => {
    setTestName(e.target.value);
  };

  const handleTotalDegreeChange = (e) => {
    setTotalDegree(e.target.value);
  };

  const handleQuestionChange = (e, questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].question = e.target.value;
      return updatedQuestions;
    });
  };

  const handleDegreeChange = (e, questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].degree = e.target.value;
      return updatedQuestions;
    });
  };

  const handleChoiceChange = (e, questionIndex, choiceIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].choices[choiceIndex].value = e.target.value;
      return updatedQuestions;
    });
  };

  const handleRadioChange = (questionIndex, choiceIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].choices = updatedQuestions[questionIndex].choices.map(
        (choice, i) => ({ ...choice, correct: i === choiceIndex })
      );
      return updatedQuestions;
    });
  };

  const handelAddFile = (questionIndex, file) => {
    const updatedQuestions = questions.map((p, i) => {
      if (i === questionIndex) {
        p.file = file;
        return p;
      }
      return p;
    });
    setQuestions(updatedQuestions);
  };

  const updateReason = (questionIndex, reason) => {
    const updatedQuestions = questions.map((p, i) => {
      if (i === questionIndex) {
        p.reason = reason;
        return p;
      }
      return p;
    });
    setQuestions(updatedQuestions);
  };

  const handleTrue_FalseAnswer = (questionIndex, ans) => {
    const updatedQuestions = questions.map((p, i) => {
      if (i === questionIndex) {
        p.TrueFalseAnswer = ans;
        return p;
      }
      return p;
    });
    setQuestions(updatedQuestions);
  };

  function QuestionByType(t, index) {
    return {
      order: index,
      questionType: { q: t.q, answer: t.answer },
      question: null,
      degree: null,
      file: null,
      reason: null,
      choices: t.answer === 'CHOICE' ? [
        { value: null, correct: false },
        { value: null, correct: false },
        { value: null, correct: false },
        { value: null, correct: false },
      ] : null,
      TrueFalseAnswer: null,
    };
  }

  const addQuestion = (t) => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      QuestionByType(t, questions.length + 1)
    ]);
  };

  const deleteLastQuestion = () => {
    if (questions.length > 1) {
      setQuestions((prevQuestions) => prevQuestions.slice(0, -1));
    }
  };

  const { testFor } = useSelector(({ view }) => {
    return { testFor: view?.testFor };
  });

  const submitTest = async () => {
    setIsLoading(true);
    try {
      const testData = {
        test: {
          name: testName,
          tot_degree: totalDegree,
          questions: questions,
        },
        testFor,
      };
      const res = await createTest(testData);
      setMessage(res.data.message);
      setAlertVariant(res.data.case ? 'success' : 'danger');
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container style={{ paddingBottom: '100px' }}>
      <h2>اضافة اختبار </h2>
      <hr />

      <FormGroup>
        <FormLabel> اسم الإختبار:</FormLabel>
        <FormControl type="text" value={testName} onChange={handleTestNameChange} />
      </FormGroup>

      <FormGroup>
        <FormLabel> الدرجة الكلية:</FormLabel>
        <FormControl type="number" min={0} value={totalDegree} onChange={handleTotalDegreeChange} />
      </FormGroup>

      {questions.map((question, questionIndex) => (
        <Question
          key={questionIndex}
          question={question}
          questionIndex={questionIndex}
          handleQuestionChange={handleQuestionChange}
          handleDegreeChange={handleDegreeChange}
          handleChoiceChange={handleChoiceChange}
          handleRadioChange={handleRadioChange}
          handelAddFile={handelAddFile}
          updateReason={updateReason}
          handleTrue_FalseAnswer={handleTrue_FalseAnswer}
        />
      ))}

      <br />
      <AddQuestion
        addQuestion={addQuestion}
        order={questions.length}
        deleteLastQuestion={deleteLastQuestion}
      />

      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spinner animation="border" />
        </div>
      ) : (
        message && (
          <Alert variant={alertVariant} onClose={() => setMessage('')} dismissible>
            {message}
          </Alert>
        )
      )}

      <Button onClick={submitTest} variant="success" style={{ marginTop: '20px' }}>
        حفظ
      </Button>
    </Container>
  );
}

export default AddTest;
