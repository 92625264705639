import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Spinner, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { createMeetingRoom, getMeetingRoomId } from '../../../utils/class';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { BiSolidToggleLeft } from "react-icons/bi";
import { BiSolidToggleRight } from "react-icons/bi";
import MeetingRoomDetailsModal from './meetingRoomDetailsModal';

function MeetingRoomModal({ show, setShow, classObj }) {
  const handleClose = () => setShow(false);
  const [teacherClassData, setTeacherClassData] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [outerLink, setOuterLink] = useState(false);
  const [link, setLink] = useState(null);
  const [meetingPlatform, setMeetingPlatform] = useState(null)
  const { user } = useSelector(({ loggedUser }) => {
    return {
      user: loggedUser.user
    }
  })

  const getRoomId = async (data) => {
    try {
      const res = await getMeetingRoomId(data);
      if (res.data.case) {
        setRoomId(res.data?.roomId)
      }
    }
    catch (err) {
      console.warn(err.message)
    }
  }
  useEffect(() => {
    try {
      let teacherData = {}
      teacherData.classId = classObj._id;
      const [teacher] = classObj?.teachersIDS.filter(t => t.teacher === user._id);
      teacherData.teacherId = user?._id;
      teacherData.materialCode = teacher?.materialCode;

      setTeacherClassData(teacherData);
      getRoomId(teacherData)
    }
    catch (err) {
      console.warn(err.message)
    }
  }, [])
  const handleConfirm = async () => {
    try {
      setLoading(true);
      // Call createMeetingRoom with provided classId, teacherId, and materialCode
      if (outerLink && !link) return alert("برجاء وضع رابط الاجتماع")
      if (link && !meetingPlatform) return alert("برجاء اختيار نوع منصة الاجتماعات الخارجية")
      const data = teacherClassData;
      data.source = meetingPlatform;
      data.link = link;
      const res = await createMeetingRoom(data);
      setRoomId(res?.data?.roomId);
      setLoading(false);
      setShow(false); // Close modal after room creation
    } catch (error) {
      console.error('Error creating meeting room:', error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <>
      {roomId?.type === "IN" ?
        <NavLink
          onClick={() => {
            if (!roomId) {
              alert('لا يوجد روابط اجتماعات بعد')
            }
          }}
          className={"addclass_btn"}
          style={{
            textDecoration:"none"
          }}
          to={`/meeting-room/${user?.username.split(' ').join('-')}/${user?._id}/${roomId?.link}`}>الدخول لغرفة الاجتماعات</NavLink>
        :
        <MeetingRoomDetailsModal roomObj={roomId} />
      }
      <Modal show={show} onHide={handleClose} centered className='font' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>انشاء غرفة اجتماعات جديدة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>غرفة خارجية</span>

            {outerLink ? <BiSolidToggleRight
              onClick={() => {
                setOuterLink(false);
                setLink(null);
              }}
              size={'40px'}
              color='green'
              className='pointer'
            />
              :
              <BiSolidToggleLeft
                size={'40px'}
                color='gray'
                onClick={() => setOuterLink(true)}
                className='pointer'
              />}
            <span> غرفة داخلية </span>

          </div>
          {outerLink ?
            <>
              <FormGroup>
                <FormLabel> رابط الاجتماع</FormLabel>
                <FormControl
                  type='text'
                  onChange={(e) => setLink(e.target.value)}
                  placeholder='ادخل رابط الاجتماع الخارجى'

                >


                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>اختر نوع منصة الاجتماع</FormLabel>
                <FormControl as="select" onChange={(e) => setMeetingPlatform(e.target.value)}>
                  <option value=''>اختر منصة الاجتماع</option>
                  <option value='Google'>Google Meeting</option>
                  <option value="Zoom"> Zoom </option>
                  <option value="Microsoft"> Microsoft Teams</option>
                </FormControl>
              </FormGroup>
            </>
            : <></>
          }
        </Modal.Body>
        <Modal.Footer>

          <button className='btn special color' onClick={handleClose}>
            الغاء
          </button>
          <Button style={{ background: "#2B6CCD" }} onClick={handleConfirm}>
            {loading && <Spinner />}  انشاء رابط اجتماع جديد
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MeetingRoomModal;
