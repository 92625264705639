import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IconContext } from 'react-icons/lib';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import { useSelector } from 'react-redux';
import Level from '../Dashboard/Subscriptions/Level';
import { ThemeContext } from '../../ThemeProvider';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { FaFilter } from "react-icons/fa";

import { IoMenu } from "react-icons/io5";
import { HomePage } from '../../utils/Translation/HomePage';
const SidebarNav = styled.nav`
  background-color: radial-gradient(25.31% 24.98% at 0% 15.35%, #CCDFFF 0%, #ECF3FF 90%) !important;
  width: 100%;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 16px;
  color: black;
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: white;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000;
  font-size: 18px;

  &:hover {
    background: #0F2A51;
    cursor: pointer;
    color: white;
  }
`;

const Sidebar = ({ onLevelSelect }) => {
    const [sidebar, setSidebar] = useState(false);
    const [subnav, setSubnav] = useState({});

    const branchs = useSelector((state) => state.branchs);

    const toggleSubnav = (index) => {
        setSubnav((prevSubnav) => ({
            ...prevSubnav,
            [index]: !prevSubnav[index],
        }));
    };

    const handleLevelClick = (levelId) => {
        onLevelSelect(levelId);
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const SidebarData = [
        {
            iconClosed: <RiIcons.RiArrowDownSFill color='black' />,
            iconOpened: <RiIcons.RiArrowUpSFill color='black' />,
            title: `${HomePage.choose_classes[lang]}`,
            subNav: branchs
                ? branchs.map((branch, branchIndex) => ({
                    title: branch.name,
                    icon: <IoIcons.IoIosPaper />,
                    levelsIDS: branch.levelsIDS,
                    key: branchIndex, // Add key to each branch
                }))
                : [],
        },
    ];

    const { theme } = useContext(ThemeContext)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <div style={{ borderLeft: '0.5px solid #001C45', borderRight: '0.5px solid #001C45' }}>
            <Button variant="primary" onClick={handleShow} className='d-sm-none mx-4'>
                <FaFilter />
            </Button>
            <span className='d-sm-none'>اختر من الصفوف</span>

            <div className="access" >
                <IconContext.Provider value={{ color: '#fff' }} >
                    <SidebarNav sidebar={sidebar}>
                        <SidebarWrap>
                            {SidebarData.map((item, index) => (
                                <div key={index}>
                                    <SidebarLink to={item.path} onClick={() => toggleSubnav(index)}>
                                        <div>
                                            {item.icon}
                                            <SidebarLabel style={{ color: `${theme === "light" ? "black" : "white"}` }}>{item.title}</SidebarLabel>
                                        </div>
                                        <div>
                                            {item.subNav && subnav[index]
                                                ? item.iconOpened
                                                : item.subNav
                                                    ? item.iconClosed
                                                    : null}
                                        </div>
                                    </SidebarLink>
                                    {subnav[index] &&
                                        item.subNav.map((subItem, subIndex) => (
                                            <div key={subIndex}>
                                                <DropdownLink to={subItem.path} className='d-flex justify-content-between' onClick={() => toggleSubnav(`${index}-${subIndex}`)}>
                                                    <SidebarLabel>{subItem.title}</SidebarLabel>
                                                    <div>
                                                        {subItem.levelsIDS && subnav[`${index}-${subIndex}`]
                                                            ? <RiIcons.RiArrowUpSFill color='black' />
                                                            : subItem.levelsIDS
                                                                ? <RiIcons.RiArrowDownSFill color='black' />
                                                                : null}
                                                    </div>
                                                </DropdownLink>
                                                {subItem.levelsIDS && subnav[`${index}-${subIndex}`] &&
                                                    subItem.levelsIDS.map((levelID, levelIndex) => (
                                                        <DropdownLink
                                                            to={levelID.path}
                                                            key={levelIndex}
                                                            style={{ paddingLeft: '4rem' }}
                                                            onClick={() => handleLevelClick(levelID)}
                                                        >
                                                            <SidebarLabel style={{ fontSize: "16px" }}>
                                                                <Level levelId={levelID} />
                                                            </SidebarLabel>
                                                        </DropdownLink>
                                                    ))}
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </SidebarWrap>
                    </SidebarNav>
                </IconContext.Provider>
            </div>


            <Offcanvas show={show} onHide={handleClose} className="font">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>البرامج الدراسيه</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SidebarNav sidebar={sidebar}>
                        <SidebarWrap>
                            {SidebarData.map((item, index) => (
                                <div key={index}>
                                    <SidebarLink to={item.path} onClick={() => toggleSubnav(index)}>
                                        <div>
                                            {item.icon}
                                            <SidebarLabel style={{ color: `${theme === "light" ? "black" : "white"}` }}>{item.title}</SidebarLabel>
                                        </div>
                                        <div>
                                            {item.subNav && subnav[index]
                                                ? item.iconOpened
                                                : item.subNav
                                                    ? item.iconClosed
                                                    : null}
                                        </div>
                                    </SidebarLink>
                                    {subnav[index] &&
                                        item.subNav.map((subItem, subIndex) => (
                                            <div key={subIndex}>
                                                <DropdownLink to={subItem.path} className='d-flex justify-content-between' onClick={() => toggleSubnav(`${index}-${subIndex}`)}>
                                                    <SidebarLabel>{subItem.title}</SidebarLabel>
                                                    <div>
                                                        {subItem.levelsIDS && subnav[`${index}-${subIndex}`]
                                                            ? <RiIcons.RiArrowUpSFill color='black' />
                                                            : subItem.levelsIDS
                                                                ? <RiIcons.RiArrowDownSFill color='black' />
                                                                : null}
                                                    </div>
                                                </DropdownLink>
                                                {subItem.levelsIDS && subnav[`${index}-${subIndex}`] &&
                                                    subItem.levelsIDS.map((levelID, levelIndex) => (
                                                        <DropdownLink
                                                            to={levelID.path}
                                                            key={levelIndex}
                                                            style={{ paddingLeft: '4rem' }}
                                                            onClick={() => handleLevelClick(levelID)}
                                                        >
                                                            <SidebarLabel style={{ fontSize: "16px" }}>
                                                                <Level levelId={levelID} />
                                                            </SidebarLabel>
                                                        </DropdownLink>
                                                    ))}
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </SidebarWrap>
                    </SidebarNav>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default Sidebar;
