import React from "react";
import "./sidNav.css"
import { Log_Out } from '../../actions/login&register.js'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import { CiLogout } from 'react-icons/ci'
import GetText from '../translation/getText'
// const onChoose =(e,p,c)=>{ //
//     let arr = document.querySelectorAll(`.${c}`);
//     arr.forEach(a=>{
//       a.classList.remove(p);    
//     });
//     if(e.target.className.includes(p)){
//       e.target.classList.remove(p);     
//     }
//     if(!e.target.className.includes(p)){    
//       e.target.classList.add(p);   
//     } 
// }
const SidNav = ({ taps }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClick = () => {
    dispatch(Log_Out());
    navigate("/");
  };
  return (
    <>
      <div className="sidnav ">
        <ul className="side-menu">

          {taps ? taps.map((t, index) => (<div key={index}>
            <li key={index} className="menu-item">
              <NavLink id={t.name}
                className={({ isActive }) =>
                  isActive ? "side-link side-link-active" : "side-link"
                }
                to={t.path}>
                {t.icon}
                <span id={t.name.split(' ').join('')}>
                  {t.name}
                </span>
              </NavLink>
            </li>
            <hr />  </div>
          )) : null}
          <li className="menu-item">
            <button onClick={onClick} className='side-link-logout'><CiLogout /><span id='logoutbtn'> Log out</span></button>
          </li>
          <hr />

        </ul>
      </div>
    </>
  )
}
export default SidNav;