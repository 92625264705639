import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../../../utils/Translation/HomePage';
import { useSelector } from 'react-redux';
// import GetMaterial from '../../../publicPges/pages/Classroom/GetMaterial';

const SchedualModal = ({ schedual }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { lang, dir } = useSelector(({ language }) => {
        return {
            lang: language.lang,
            dir: language.dir
        }
    });

    const getDayName = (id) => {
        const days = ["الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت", "الأحد"];
        return days[id - 1]; // Assuming id starts from 1 and goes up to 7
    };

    return (
        <div>
            <p onClick={handleShow} className="font">
                {/* {HomePage.Schedual[lang]} */}
                الجدول
            </p>
            <Modal show={show} onHide={handleClose} centered className='font text-center'>
                    <Modal.Title className='text-center'>عرض الجدول</Modal.Title>
            
                <Modal.Body>
                    <table className='w-100'> 
                        <thead>
                            <tr>
                                <th>اسم المادة</th>
                                <th>اليوم</th>
                                <th>الوقت</th>
                                <th>مدة الحصه</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedual && schedual.length > 0 ? (
                                schedual.map((item) => {
                                    const selectedSelections = Object.keys(item.selections)
                                        .filter(key => item.selections[key].selected)
                                        .map(key => item.selections[key]);

                                    return (
                                        selectedSelections.map((selection, index) => (
                                            <tr key={selection.id} className='mb-5 p-5'>
                                                {index === 0 && (
                                                    <td rowSpan={selectedSelections.length}>
                                                        عربي
                                                        {/* <GetMaterial materialCode={item.materialCode} /> */}
                                                    </td>
                                                )}
                                                <td>{getDayName(selection.id)}</td>
                                                <td>{selection.time}</td>
                                                <td>{item.sessionDuration}</td>
                                            </tr>
                                        ))
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="4">لا توجد بيانات للعرض</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{background:"#0056b3"}} onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SchedualModal;
