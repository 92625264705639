import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// bootstrap Liks

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
// import { persistReducer,persistStore  } from 'redux-persist';
// import { PersistGate } from 'redux-persist/integration/react';

import './index.css'
import { Provider } from 'react-redux';
import reducer from './reducer';
import middleware from "./middleware";
import { createStore } from "redux";
import { ThemeProvider } from "./ThemeProvider";
// import storage from 'redux-persist/lib/storage';
const store = createStore(reducer, middleware);

// const persistor = persistStore(store);

const rootElement = document.getElementById("root");

createRoot(rootElement).render(

  <React.StrictMode>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <Provider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>
    {/* </PersistGate> */}
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();